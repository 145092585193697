import React, { useCallback } from 'react'
import { Image, Button } from 'semantic-ui-react'

import './upgrade-time.css'

import Typography from 'component/Typography'

const imgSrc = require('images/take-off.png')

export default function UpgradeTime() {
  const launchLatestVersion = useCallback(() => {
    window.location.reload()
  }, [])
  return (
    <div className="upgrade-time">
      <div style={{ background: 'black', display: 'flex', justifyContent: 'center' }}>
        <Image src={imgSrc} className="upgrade-time-image" />
      </div>
      <div className="upgrade-time-action-box">
        <div className="upgrade-time-action-text">
          <Typography.h3
            style={{
              textAlign: 'center',
              lineHeight: '1.4',
            }}
          >
            A newer version of Quiz House Live is now available
          </Typography.h3>
        </div>
        <div className="upgrade-time-action-button">
          <Button primary onClick={launchLatestVersion}>
            LAUNCH LATEST VERSION
          </Button>
        </div>
      </div>
    </div>
  )
}
