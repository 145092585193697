import React, { useCallback } from 'react'
import { Button, Form, Icon, Divider } from 'semantic-ui-react'

import Input from 'component/Input'

import useForm from 'hooks/useForm'
import useAuth from 'hooks/useAuth'

import { getEnvironSupportsSocialLogin } from 'module/environ'

const ENVIRON_SUPPORTS_SOCIAL_LOGIN = getEnvironSupportsSocialLogin()

export default function SignIn({ onSuccess }) {
  const {
    signInUpStatus = {},
    signIn,
    signInWithGoogle,
    resetPassword,
    passwordResetMessage,
  } = useAuth()
  const { userSigningIn, userSignInErrorDisplay } = signInUpStatus

  const onForgotPassword = useCallback(
    (evt) => {
      evt.preventDefault()
      resetPassword()
    },
    [resetPassword],
  )

  const onSignIn = useCallback(
    ({ email, password }) => {
      signIn({ email, password, onSuccess })
    },
    [signIn, onSuccess],
  )

  const onSignInWithGoogle = useCallback(() => {
    signInWithGoogle({ onSuccess })
  }, [signInWithGoogle, onSuccess])

  const {
    inputs: signInInputs,
    handleInputChange: handleSignInInputChange,
    handleSubmit: handleSignIn,
  } = useForm({ onSubmit: onSignIn })

  return (
    <div>
      <Form onSubmit={handleSignIn}>
        <Input
          icon="mail"
          iconPosition="left"
          label="Email"
          type="email"
          placeholder="Email"
          onChange={handleSignInInputChange}
          name="email"
          value={signInInputs.email || ''}
          required
        />
        <Input
          icon="lock"
          iconPosition="left"
          label="Password"
          type="password"
          onChange={handleSignInInputChange}
          name="password"
          value={signInInputs.password || ''}
          required
        />
        {!!userSignInErrorDisplay && (
          <p style={{ textAlign: 'center', color: 'red' }}>{userSignInErrorDisplay}</p>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button content="Login" primary loading={userSigningIn} />
          <div style={{ marginLeft: '1em' }}>
            <a href="/forgot-password" onClick={onForgotPassword} style={{ fontSize: '12px' }}>
              Forgot Password?
            </a>
            {!!passwordResetMessage && <p style={{ marginTop: '0.5em' }}>{passwordResetMessage}</p>}
          </div>
        </div>
      </Form>
      {!!ENVIRON_SUPPORTS_SOCIAL_LOGIN && (
        <>
          <Divider horizontal style={{ marginTop: '1.5em' }}>
            Or
          </Divider>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button color="google plus" style={{ marginTop: '0.5em' }} onClick={onSignInWithGoogle}>
              <Icon name="google plus" /> Google
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
