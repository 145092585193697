import React, { useRef } from 'react'

import useVideoPlayerSlaveTwilio from 'hooks/useVideoPlayerSlaveTwilio'

import './video-player.css'

export default function VideoPlayerSlaveJitsi({
  isMe,
  audioTrack,
  videoTrack,
  audioEnabled,
  videoEnabled,
  videoDimensionStyles,
}) {
  const videoRef = useRef()
  const audioRef = useRef()
  useVideoPlayerSlaveTwilio({
    isMe,
    videoRef,
    audioRef,
    audioTrack,
    videoTrack,
    audioEnabled,
    videoEnabled,
  })
  return (
    <div className="video-container">
      <video ref={videoRef} autoPlay={true} style={videoDimensionStyles} />
      <audio ref={audioRef} autoPlay={true} muted={isMe} />
    </div>
  )
}
