import React, { useCallback } from 'react'
import { Button, Form, Checkbox } from 'semantic-ui-react'

import Input from 'component/Input'

import useForm from 'hooks/useForm'
import useAuth from 'hooks/useAuth'

export default function SignUp({ onSuccess }) {
  const { signInUpStatus = {}, signUp } = useAuth()
  const { userSigningUp, userSignUpErrorDisplay } = signInUpStatus

  const onSignUp = useCallback(
    ({ name, email, password }) => {
      signUp({ name, email, password, onSuccess })
    },
    [signUp, onSuccess],
  )

  const {
    inputs: signUpInputs,
    handleInputChange: handleSignUpInputChange,
    handleSubmit: handleSignUp,
  } = useForm({ onSubmit: onSignUp })

  return (
    <Form onSubmit={handleSignUp}>
      <Form.Field>
        <Input
          icon="user"
          iconPosition="left"
          label="Name"
          type="text"
          onChange={handleSignUpInputChange}
          name="name"
          value={signUpInputs.name || ''}
          required
        />
      </Form.Field>
      <Form.Field>
        <Input
          icon="mail"
          iconPosition="left"
          label="Email"
          type="email"
          onChange={handleSignUpInputChange}
          name="email"
          value={signUpInputs.email || ''}
          required
        />
      </Form.Field>
      <Form.Field>
        <Input
          icon="lock"
          iconPosition="left"
          label="Password"
          type="password"
          onChange={handleSignUpInputChange}
          name="password"
          value={signUpInputs.password || ''}
          required
        />
      </Form.Field>
      {/* <Form.Field>
        <Checkbox
          label="I agree to the Terms and Conditions"
          onChange={handleSignUpInputChange}
          name="agree"
          checked={!!signUpInputs.agree}
          required
        />
      </Form.Field> */}
      <Form.Group>
        <Form.Field>
          <Button primary type="submit" loading={userSigningUp}>
            Submit
          </Button>
        </Form.Field>
        <div style={{ marginLeft: '1em', display: 'flex', alignItems: 'center' }}>
          {!!userSignUpErrorDisplay && <p style={{ color: 'red' }}>{userSignUpErrorDisplay}</p>}
        </div>
      </Form.Group>
    </Form>
  )
}
