import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Image } from 'semantic-ui-react'

import { useAppState } from 'store/App'
import { getAppVersion, getUserId } from 'store/App/selectors'

import { getNonLiveEnvironName } from 'module/environ'

import useAuth from 'hooks/useAuth'
import useNav from 'hooks/useNav'

const imgSrc = require('images/logo.svg')
const ENVIRON_NAME = getNonLiveEnvironName()
const environLabel = !!ENVIRON_NAME ? ` (${ENVIRON_NAME})` : ''

export const HEADER_NAV_HEIGHT = 65

export default function HeaderNav() {
  const state = useAppState()
  const { isUserStatusDetermined, userIsAuthorised, signOut, user = {} } = useAuth()
  const { navHome } = useNav()

  const signOutAndNavigateHome = (evt) => {
    evt.preventDefault()
    navHome()
    signOut()
  }

  const appVersion = getAppVersion(state)
  const userId = getUserId(state)

  return (
    <div
      style={{
        background: 'white',
        height: HEADER_NAV_HEIGHT,
        display: 'flex',
        alignItems: 'center',
      }}
      data-app-version={appVersion}
      data-user-id={userId}
    >
      <Container>
        <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Link to="/">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image src={imgSrc} rounded size="mini" />
              <h4 style={{ margin: 0, paddingLeft: '0.5em', color: '#EC0A68' }}>
                QUIZ HOUSE LIVE{environLabel}
              </h4>
            </div>
          </Link>
          {isUserStatusDetermined && userIsAuthorised && (
            <div style={{ display: 'flex' }}>
              <div style={{ paddingLeft: '1em' }}>
                <Link to="/dashboard">My Quizzes</Link>
              </div>

              <div style={{ paddingLeft: '1em' }}>
                <a href="/sign-out" onClick={signOutAndNavigateHome}>
                  Sign Out
                </a>
              </div>
              <div style={{ paddingLeft: '1.5em' }}>{user.name}</div>
            </div>
          )}
        </nav>
      </Container>
    </div>
  )
}
