import React from 'react'
import { Table, Header, Image } from 'semantic-ui-react'
import _orderBy from 'lodash/orderBy'

import './player-list.css'

import useLiveGame from 'feature/Game/useLiveGame'

import { orderedAvatars } from 'feature/QuizManager/selectors'

export default function PlayerList({ gameLinkId }) {
  const { gameData = {} } = useLiveGame({
    gameLinkId,
  })
  const { preGameData = {} } = gameData
  const { playerData = {} } = preGameData
  let indexHack = -1

  const players = _orderBy(
    Object.entries(playerData).map(([playerId, { name, playerOrder }]) => {
      return {
        playerId,
        name,
        playerOrder,
      }
    }),
    ['playerOrder'],
    ['asc'],
  ).map(({ playerId, name, playerOrder }) => {
    indexHack += 1
    return {
      playerId,
      name,
      image: orderedAvatars[indexHack],
    }
  })

  return (
    <div className={`confirmed-player-list`}>
      <Table basic="very" celled unstackable>
        <Table.Body>
          {players.map(({ playerId, name, image }) => (
            <Table.Row key={playerId}>
              <Table.Cell>
                <Header as="h4" className="confirmed-player-list-row-heading">
                  <Image src={image} className="confirmed-player-list-avatar" />
                  <Header.Content className="confirmed-player-list-name">{name}</Header.Content>
                </Header>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
