import { useCallback } from 'react'

import { useAppState, useAppDispatch } from 'store/App'
import {
  getCreateEventStatus,
  getUpdateEventStatus,
  getDeleteEventStatus,
} from 'store/App/selectors'
import {
  createEvent as creatEventAction,
  updateEvent as updateEventAction,
  deleteEvent as deleteEventAction,
  setEventRounds as setEventRoundsAction,
} from 'store/App/actions'

const useHostEvents = ({ eventId = '' } = {}) => {
  const state = useAppState()
  const dispatch = useAppDispatch()

  const createEvent = useCallback(
    ({
      name,
      date,
      time,
      useVideo,
      videoMode,
      connectionType,
      supportingVideoConference,
      supportingVideoConferenceInfo,
      maxDurationMinutes,
      maxPlayers,
      onSuccess,
      onError,
    }) => {
      creatEventAction({
        dispatch,
        name,
        date,
        time,
        useVideo,
        videoMode,
        connectionType,
        supportingVideoConference,
        supportingVideoConferenceInfo,
        maxDurationMinutes,
        maxPlayers,
        onSuccess,
        onError,
      })
    },
    [dispatch],
  )

  const updateEvent = useCallback(
    ({
      id,
      name,
      date,
      time,
      useVideo,
      videoMode,
      connectionType,
      supportingVideoConference,
      supportingVideoConferenceInfo,
      maxDurationMinutes,
      maxPlayers,
      gameLinkId,
      onSuccess,
      onError,
    }) => {
      updateEventAction({
        dispatch,
        id,
        name,
        date,
        time,
        useVideo,
        videoMode,
        connectionType,
        supportingVideoConference,
        supportingVideoConferenceInfo,
        maxDurationMinutes,
        maxPlayers,
        gameLinkId,
        onSuccess,
        onError,
      })
    },
    [dispatch],
  )

  const setEventRounds = useCallback(
    ({ id, rounds, onSuccess, onError }) => {
      setEventRoundsAction({
        dispatch,
        id,
        rounds,
        onSuccess,
        onError,
      })
    },
    [dispatch],
  )

  const deleteEvent = useCallback(
    ({ id, onSuccess, onError }) => {
      deleteEventAction({
        dispatch,
        id,
        onSuccess,
        onError,
      })
    },
    [dispatch],
  )

  return {
    createEvent,
    createEventStatus: getCreateEventStatus(state),
    updateEvent,
    setEventRounds,
    updateEventStatus: getUpdateEventStatus({ state, eventId }),
    deleteEvent,
    deleteEventStatus: getDeleteEventStatus({ state, eventId }),
  }
}

export default useHostEvents
