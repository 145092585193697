import React, { useCallback } from 'react'
import { Form, Checkbox, Button } from 'semantic-ui-react'

import Input from 'component/Input'
import ConfirmButton from 'component/ConfirmButton'

import useForm, { HiddenSubmitButton } from 'hooks/useForm'
import { hasValue } from 'module/utils'

const defaultAnswerOptionValues = {
  answerText: '',
  isCorrect: false,
}

export default function QuestionAnswerOption({
  readOnly,
  answerOptionId,
  initialAnswerOptionValues,
  onSaveAnswerOption,
  onDeleteAnswerOption,
}) {
  const mode = initialAnswerOptionValues ? 'edit' : 'add'
  const onHandleSubmit = useCallback(
    (inputs) => {
      const { answerText, isCorrect } = inputs
      onSaveAnswerOption({
        answerOptionId,
        answerOption: {
          answerText,
          isCorrect,
        },
      })
    },
    [answerOptionId, onSaveAnswerOption],
  )

  const onRemove = useCallback(() => {
    onDeleteAnswerOption({
      answerOptionId,
    })
  }, [onDeleteAnswerOption, answerOptionId])

  let initialValues = initialAnswerOptionValues
    ? {
        ...defaultAnswerOptionValues,
        ...initialAnswerOptionValues,
      }
    : {
        ...defaultAnswerOptionValues,
      }

  const { inputs, handleInputChange, handleSubmit, hasChanges } = useForm({
    initialValues,
    onSubmit: onHandleSubmit,
  })

  return (
    <div>
      <Form>
        <HiddenSubmitButton handleSubmit={handleSubmit} />
        <Form.Field>
          <Input
            autoFocus={mode === 'add'}
            placeholder="Answer Text"
            required
            onChange={handleInputChange}
            value={inputs.answerText || ''}
            name="answerText"
            type="text"
            disabled={readOnly}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="is correct answer"
            onChange={handleInputChange}
            name="isCorrect"
            checked={!!inputs.isCorrect}
            required
            disabled={readOnly}
          />
        </Form.Field>
        <Form.Group inline>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex' }}>
              {hasChanges && hasValue(inputs.answerText) && (
                <Form.Field>
                  <Button primary onClick={handleSubmit}>
                    {mode === 'edit' ? 'Update' : 'Add'}
                  </Button>
                </Form.Field>
              )}
            </div>
            {mode === 'edit' && !readOnly && (
              <Form.Field>
                <ConfirmButton
                  renderButton={() => <Button color="red">Delete Quiz</Button>}
                  renderConfirmButton={() => (
                    <Button size="tiny" color="red" content="Delete" onClick={onRemove} />
                  )}
                  renderCancelButton={({ onCancel }) => (
                    <Button size="tiny" color="black" content="Cancel" onClick={onCancel} />
                  )}
                />
              </Form.Field>
            )}
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}
