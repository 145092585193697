import { useCallback, useState, useEffect } from 'react'

import { VIDEO_MODE } from 'mode'

import storeApi from 'store/storeApi'

import {
  GAME_START_RESULT_SUCCESS,
  GAME_START_RESULT_ERROR,
  GAME_DATA_STATUS_EXISTS,
} from 'api/apiConstants'

import useNav from 'hooks/useNav'
import useIsMounted from 'hooks/useIsMounted'

const useGameActivation = ({ gameLinkId }) => {
  const isMounted = useIsMounted()
  const [fetchingGameData, setFetchingGameData] = useState(true)
  const [fetchingGameErrorDisplay, setFetchingGameErrorDisplay] = useState('')
  const [startingGame, setStartingGame] = useState(false)
  const [startingGameErrorDisplay, setStartingGameErrorDisplay] = useState('')
  const [gameStatus, setGameStatus] = useState('')
  const { navToLiveGame } = useNav()

  const startGame = useCallback(() => {
    try {
      setStartingGame(true)
      storeApi({
        method: 'startGame',
        params: {
          gameLinkId,
          videoMode: VIDEO_MODE,
          onSuccess: ({ data } = {}) => {
            if (isMounted.current) {
              const { result, reason } = data
              setStartingGameErrorDisplay('')
              setStartingGame(false)
              if (result === GAME_START_RESULT_SUCCESS) {
                navToLiveGame({ gameLinkId })
              }
              if (result === GAME_START_RESULT_ERROR) {
                setStartingGameErrorDisplay(reason || 'Error starting game')
              }
            }
          },
          onError: ({ errorMsg = 'Error starting game' } = {}) => {
            if (isMounted.current) {
              setStartingGameErrorDisplay(errorMsg)
              setStartingGame(false)
            }
          },
        },
      })
    } catch (error) {
      if (isMounted.current) {
        setStartingGame(false)
        setStartingGameErrorDisplay('Error starting game')
      }
    }
  }, [isMounted, gameLinkId, setStartingGame, setStartingGameErrorDisplay, navToLiveGame])

  useEffect(() => {
    try {
      storeApi({
        method: 'getPublicGameData',
        params: {
          gameLinkId,
          onSuccess: ({ data = {} }) => {
            if (isMounted.current) {
              const { status, details = {} } = data
              if (status !== GAME_DATA_STATUS_EXISTS) {
                throw new Error('Game does not exist')
              }
              setFetchingGameErrorDisplay('')
              setFetchingGameData(false)
              setGameStatus(details.status)
            }
          },
          onError: ({ errorMsg = 'Error fetching game data' } = {}) => {
            if (isMounted.current) {
              setFetchingGameErrorDisplay(errorMsg)
              setFetchingGameData(false)
            }
          },
        },
      })
    } catch (error) {
      if (isMounted.current) {
        setFetchingGameData(false)
        setFetchingGameErrorDisplay('Error fetching game data')
      }
    }
  }, [isMounted, navToLiveGame, gameLinkId, setFetchingGameData, setFetchingGameErrorDisplay])

  return {
    startGame,
    startingGame,
    startingGameErrorDisplay,
    fetchingGameData,
    fetchingGameErrorDisplay,
    gameStatus,
  }
}

export default useGameActivation
