import React from 'react'
import { Form, Select, Button, Radio } from 'semantic-ui-react'

import Input from 'component/Input'

import { HiddenSubmitButton } from 'hooks/useForm'

import useAddEditRound from './useAddEditRound'

import AddEditQuestionModal from 'feature/QuizManager/components/AddEditQuestionModal'
import QuestionList from 'feature/QuizManager/components/QuestionList'
import Typography from 'component/Typography'

const LABEL_WIDTH = 100

export default function AddEditRound({
  mode: initialMode,
  roundId,
  onSaveRoundSuccess,
  isSaving = false,
}) {
  const {
    mode,
    inputs,
    handleInputChange,
    roundTypeOptions,
    responseTimingOptions,
    responseTimingOptionDescriptions,
    playerResponseOptions,
    fieldsToDisplay,
    addEditQuestionDisplayProps,
    roundQuestions,
    onAddQuestion,
    onEditQuestion,
    onSaveRound,
    readOnly,
  } = useAddEditRound({ initialMode, roundId, onSaveRoundSuccess })

  const saveButtonText = mode === 'edit' ? 'Save Changes' : 'Save Round'

  return (
    <div>
      <Form>
        <HiddenSubmitButton />
        {fieldsToDisplay.indexOf('roundName') > -1 && (
          <Form.Group inline>
            <Form.Field label="Name" style={{ width: LABEL_WIDTH }}></Form.Field>
            <Input
              fluid
              autoFocus={mode === 'add'}
              placeholder="Round Name"
              required
              onChange={handleInputChange}
              value={inputs.roundName || ''}
              name="roundName"
              type="text"
              disabled={readOnly}
              width={12}
            />
          </Form.Group>
        )}
        {fieldsToDisplay.indexOf('roundType') > -1 && (
          <Form.Group inline>
            <Form.Field label="Type" style={{ width: LABEL_WIDTH }}></Form.Field>
            <Select
              disabled={mode === 'edit' || !!readOnly}
              placeholder="Round type"
              options={roundTypeOptions}
              onChange={handleInputChange}
              value={inputs.roundType || ''}
              name="roundType"
            />
          </Form.Group>
        )}
        {fieldsToDisplay.indexOf('responseCapture') > -1 && (
          <Form.Group inline>
            <label>Player response:</label>
            {playerResponseOptions.map(({ key, value, text }) => (
              <Form.Field
                key={key}
                control={Radio}
                label={text}
                value={value}
                checked={inputs.responseCapture === value}
                onChange={handleInputChange}
                name="responseCapture"
                disabled={readOnly}
              />
            ))}
          </Form.Group>
        )}
        {fieldsToDisplay.indexOf('pointsPerQuestion') > -1 && (
          <Form.Group inline>
            <label>Points For Correct Answer:</label>
            <Input
              style={{ width: 70 }}
              required
              onChange={handleInputChange}
              value={inputs.pointsPerQuestion}
              name="pointsPerQuestion"
              type="number"
              disabled={readOnly}
            />
          </Form.Group>
        )}
        {fieldsToDisplay.indexOf('responseTiming') > -1 && (
          <Form.Group inline>
            <label>Points Allocation:</label>
            <Select
              placeholder="Response type"
              options={responseTimingOptions}
              onChange={handleInputChange}
              value={inputs.responseTiming || ''}
              name="responseTiming"
              disabled={readOnly}
            />
            <Typography.smallText alt style={{ marginLeft: '1em' }}>
              {responseTimingOptionDescriptions[inputs.responseTiming]}
            </Typography.smallText>
          </Form.Group>
        )}
        {fieldsToDisplay.indexOf('questions') > -1 && (
          <Form.Field>
            {!readOnly && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button primary onClick={onAddQuestion}>
                  {Object.entries(roundQuestions || {}).length
                    ? 'Add Another Question'
                    : 'Add Question'}
                </Button>
                {fieldsToDisplay.indexOf('saveButton') > -1 && (
                  <Button color="green" onClick={onSaveRound} loading={isSaving}>
                    {saveButtonText}
                  </Button>
                )}
              </div>
            )}

            {!!addEditQuestionDisplayProps && (
              <AddEditQuestionModal {...addEditQuestionDisplayProps} readOnly={readOnly} />
            )}
            <QuestionList
              questions={roundQuestions}
              onEditQuestion={onEditQuestion}
              readOnly={readOnly}
            />
          </Form.Field>
        )}
        {fieldsToDisplay.indexOf('questions') === -1 &&
          fieldsToDisplay.indexOf('saveButton') > -1 &&
          !readOnly && (
            <Form.Field>
              <Button color="green" onClick={onSaveRound} loading={isSaving}>
                {saveButtonText}
              </Button>
            </Form.Field>
          )}
      </Form>
    </div>
  )
}
