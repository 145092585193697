import moment from 'moment'

// TODO: bring in moment timezone to ensure time zone conversions are accurate

function getTimeParts(timeString = '') {
  if (!isValidTimeString({ timeString })) {
    return ''
  }
  const timeParts = timeString.split(':')
  return {
    hours: timeParts[0],
    minutes: timeParts[1],
  }
}

function getLocaleTimeFormat() {
  // 9:15am
  return 'h:mma'
}

export function getServerTimeStampToNormalisedFormat(unixTimeStamp) {
  return moment(unixTimeStamp).toISOString(true)
}

export function dateToNormalisedFormat(date) {
  return moment(date).toISOString(true)
}

export function getDatePart(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function getTimePart(date) {
  return moment(date).format('HH:mm')
}

export const getLocaleDateFormat = () => {
  // slightly hacky... we want to show either DD/MM/YYYY or MM/DD/YYYY
  let dateDisplayFormat = 'MM/DD/YYYY'
  try {
    const localDateString = new Date('1980-03-26').toLocaleDateString()
    const dateParts = localDateString.split('/')
    if (dateParts[0] === '26') {
      dateDisplayFormat = 'DD/MM/YYYY'
    }
  } catch (e) {}
  return dateDisplayFormat
}

export function dateDisplay(date) {
  return moment(date).format(getLocaleDateFormat())
}

export function dateTimeDisplay({ dateTime, mode = 'factual' }) {
  if (mode === 'friendly') {
    return `${moment(dateTime).format('dddd Do MMMM')} ${moment(dateTime).format(
      getLocaleTimeFormat(),
    )}`
  }
  return `${moment(dateTime).format(getLocaleDateFormat())} ${moment(dateTime).format(
    getLocaleTimeFormat(),
  )}`
}

export function dateAsDayName(date) {
  return moment(date).format('ddd').toLowerCase()
}

// valid date string format 'YYYY-MM-DD'
export const isValidDateString = ({ dateString = '' } = {}) => {
  // FALSE: if value is not a string
  if (typeof dateString !== 'string') return false
  // FALSE: if there are not three date parts split by "-"
  const dateParts = dateString.split('-')
  if (dateParts.length !== 3) return false
  // FALSE: if any of the date parts are blank
  if (!dateParts[0] || !dateParts[1] || !dateParts[2]) {
    return false
  }
  // FALSE: if any of the date parts are not numbers
  if (isNaN(dateParts[0]) || isNaN(dateParts[1]) || isNaN(dateParts[2])) {
    return false
  }
  // FALSE: if part lengths are not as expected
  if (dateParts[0].length !== 4 || dateParts[1].length !== 2 || dateParts[2].length !== 2) {
    return false
  }
  // FINALLY: we do a moment JS validation check
  return moment(dateString).isValid()
}

// valid time string format 'HH:MM'
export const isValidTimeString = ({ timeString = '' } = {}) => {
  if (timeString.length !== 5) {
    return false
  }
  const timeParts = timeString.split(':')
  if (timeParts.length !== 2) {
    return false
  }
  if (Number(timeParts[0]) < 0 || Number(timeParts[0]) > 23) {
    return false
  }
  if (Number(timeParts[1]) < 0 || Number(timeParts[1]) > 59) {
    return false
  }
  return true
}

export function getNormalisedDateFromDateTimeParts({ datePart, timePart }) {
  if (!isValidDateString({ dateString: datePart })) {
    console.warn(`invalid datePart`, { datePart })
    return ''
  }
  if (!isValidTimeString({ timeString: timePart })) {
    console.warn(`invalid timePart`, { timePart })
    return ''
  }
  const { hours, minutes } = getTimeParts(timePart) || {}
  var date = new Date(datePart)
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return dateToNormalisedFormat(date)
}
