import React from 'react'
import { Table, Header, Image } from 'semantic-ui-react'

import './live-leader-board.css'

import { useLiveGameData } from 'store/LiveGame'
import { getLeaderBoardData } from 'feature/QuizManager/selectors'

import LeaderBoardBonusPointsAdjustment from 'feature/Game/GameLive/GamePlay/LiveLeaderBoard/LeaderBoardBonusPointsAdjustment'

export default function LiveLeaderBoard({
  mini = true,
  showBonusPoints = false,
  canSetBonusPoints = false,
}) {
  const { gameData } = useLiveGameData()
  const leaderBoardData = getLeaderBoardData({
    gameData,
  })
  const liveLeaderBoardClassName = mini ? 'live-leader-board-mini' : ''
  const pointsColumnDescr = showBonusPoints ? 'Total Points' : 'Points'
  return (
    <div className={`live-leader-board ${liveLeaderBoardClassName}`}>
      <Table basic="very" celled collapsing={mini} unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Player</Table.HeaderCell>
            {!!showBonusPoints && (
              <Table.HeaderCell textAlign="center">Bonus Points</Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="center">{pointsColumnDescr}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {leaderBoardData.map(({ playerId, name, image, points, bonusPoints }) => (
            <Table.Row key={playerId}>
              <Table.Cell>
                <Header as="h4" className="live-leader-board-row-heading">
                  <Image src={image} className="live-leader-board-avatar" />
                  <Header.Content className="live-leader-board-player-name">{name}</Header.Content>
                  {!!canSetBonusPoints && <LeaderBoardBonusPointsAdjustment playerId={playerId} />}
                </Header>
              </Table.Cell>
              {!!showBonusPoints && <Table.Cell textAlign="center">{bonusPoints}</Table.Cell>}
              <Table.Cell textAlign="center">{points}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
