import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

// eslint-disable-next-line no-unused-vars
import css from './css'

import initialiseSentry from 'sentryInit'
import initialiseFirebase from 'firebaseInit'

import App from 'App'

initialiseSentry()
initialiseFirebase()
ReactDOM.render(<App />, document.getElementById('root'))
