import React from 'react'
import { Modal } from 'semantic-ui-react'

import useAuth from 'hooks/useAuth'

import useAuthNav from 'feature/AuthDialog/useAuthNav'
import TabbedAuth from './TabbedAuth'
import FullScreenLoader from 'component/FullScreenLoader'

export default function AuthDialog() {
  const { isUserStatusDetermined, user } = useAuth()
  const { isSecureRoute, isSignUpRoute } = useAuthNav()

  if (!isSecureRoute) {
    return null
  }

  const defaultActiveIndex = isSignUpRoute ? 1 : 0

  // TODO: add a retry state if result of fetching auth is an error?
  if (!isUserStatusDetermined) {
    return <FullScreenLoader />
  } else if (!user) {
    return (
      <Modal open dimmer closeOnDimmerClick={false} closeOnEscape={false} size="tiny">
        <Modal.Content>
          <TabbedAuth defaultActiveIndex={defaultActiveIndex} />
        </Modal.Content>
      </Modal>
    )
  }
  return null
}
