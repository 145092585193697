import { useEffect, useCallback, useRef } from 'react'

const useTimeout = (callback, delay = 1000) => {
  const timerRef = useRef()
  const cancel = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = undefined
    }
  }, [timerRef])
  useEffect(() => {
    timerRef.current = setTimeout(callback, delay)
    return cancel
  }, [timerRef, callback, delay, cancel])
  return cancel
}

export default useTimeout
