import { useCallback, useState, useEffect, useMemo } from 'react'

import storeApi from 'store/storeApi'
import { GAME_JOIN_RESULT_GAME_FULL } from 'api/apiConstants'

import useUserData from 'hooks/useUserData'
import useIsMounted from 'hooks/useIsMounted'
import { getHasGameAccess } from 'store/App/selectors'

const useGameParticipation = ({ gameLinkId }) => {
  const isMounted = useIsMounted()
  const state = useUserData()
  const [joiningGame, setJoiningGame] = useState(false)
  const [joiningGameErrorDisplay, setJoiningGameErrorDisplay] = useState('')

  const joinGame = useCallback(() => {
    function onSuccess({ data } = {}) {
      if (isMounted.current) {
        const { result, reason } = data
        if (result === GAME_JOIN_RESULT_GAME_FULL) {
          setJoiningGameErrorDisplay(reason || 'Game is full')
        }
        setJoiningGame(false)
      }
    }
    function onError({ errorMsg = 'Error joining game' } = {}) {
      if (isMounted.current) {
        setJoiningGameErrorDisplay(errorMsg)
        setJoiningGame(false)
      }
    }
    try {
      setJoiningGameErrorDisplay('')
      setJoiningGame(true)
      storeApi({
        method: 'joinGame',
        params: {
          gameLinkId,
          onSuccess,
          onError,
        },
      })
    } catch (error) {
      if (isMounted.current) {
        setJoiningGameErrorDisplay('Error joining game')
        setJoiningGame(false)
      }
    }
  }, [isMounted, gameLinkId, setJoiningGame, setJoiningGameErrorDisplay])

  const hasGameAccess = useMemo(() => {
    return getHasGameAccess({
      state,
      gameLinkId,
    })
  }, [gameLinkId, state])

  useEffect(() => {
    if (isMounted.current.true && hasGameAccess && joiningGame) {
      setJoiningGame(false)
    }
  }, [isMounted, hasGameAccess, joiningGame, setJoiningGame])

  return {
    hasGameAccess,
    joinGame,
    joiningGame,
    joiningGameErrorDisplay,
  }
}

export default useGameParticipation
