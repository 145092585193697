/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useCallback, useRef } from 'react'

import './image-input.css'

import Input from 'component/Input'

export default function ImageInput({ setFieldValidity, ...otherInputProps }) {
  const [imageLoadError, setImageLoadError] = useState(false)
  const imageRef = useRef()
  const onImageLoad = useCallback(() => {
    setImageLoadError(false)
    if (setFieldValidity) {
      setFieldValidity({ isValid: true })
    }
  }, [setImageLoadError, setFieldValidity])

  const onImageError = useCallback(() => {
    setImageLoadError(true)
    if (setFieldValidity) {
      setFieldValidity({ isValid: false })
    }
  }, [setImageLoadError, setFieldValidity])

  return (
    <div>
      <Input {...otherInputProps} />
      {!!otherInputProps.value && (
        <div>
          <div>
            {!!imageLoadError && (
              <p style={{ color: 'red', paddingBottom: '0.5em' }}>Error loading image</p>
            )}
          </div>
          <div className="image-input-preview-container">
            <img
              ref={imageRef}
              onError={onImageError}
              onLoad={onImageLoad}
              alt="Question Image"
              src={otherInputProps.value}
              className="image-input-preview-img"
            />
          </div>
        </div>
      )}
    </div>
  )
}
