import React from 'react'
import { Modal } from 'semantic-ui-react'

import AddEditQuestion from './AddEditQuestion'

export default function AddEditQuestionModal({ open, readOnly, mode, ...otherProps }) {
  const modalTitle = mode === 'add' ? 'Add Question' : readOnly ? 'Question' : 'Edit Question'
  return (
    <Modal open={open} closeOnDimmerClick={false} closeOnEscape={false}>
      <Modal.Header>{modalTitle}</Modal.Header>
      <Modal.Content>
        <AddEditQuestion mode={mode} readOnly={readOnly} {...otherProps} />
      </Modal.Content>
    </Modal>
  )
}
