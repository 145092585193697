import { useState, useCallback, useEffect, useRef } from 'react'
import _isEqual from 'lodash/isEqual'

import { getUuid, sanitizeData } from 'module/utils'

import {
  ROUND_TYPE_ADHOC,
  ROUND_TYPE_OPEN_QUESTION,
  ROUND_TYPE_Q_AND_A,
  ROUND_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_OPEN_QUESTION,
  QUESTION_TYPE_QUESTION_AND_ANSWER,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  RESPONSE_TIMING_FF_FIRST,
  RESPONSE_TIMING_EVERYONE,
  RESPONSE_CAPTURE_TEXT,
  RESPONSE_CAPTURE_AV,
} from 'module/constants'

import {
  roundTypeLookup,
  responseTimingLookup,
  playerResponseLookup,
  responseTimingDescriptionLookup,
} from 'module/lookups'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import { getRound } from 'feature/QuizManager/selectors'

import useForm from 'hooks/useForm'

const roundToQuestionTypeMap = {
  ROUND_TYPE_OPEN_QUESTION: QUESTION_TYPE_OPEN_QUESTION,
  ROUND_TYPE_Q_AND_A: QUESTION_TYPE_QUESTION_AND_ANSWER,
  ROUND_TYPE_MULTIPLE_CHOICE: QUESTION_TYPE_MULTIPLE_CHOICE,
}

const roundTypeOptions = [
  // {
  //   key: ROUND_TYPE_OPEN_QUESTION,
  //   value: ROUND_TYPE_OPEN_QUESTION,
  //   text: roundTypeLookup[ROUND_TYPE_OPEN_QUESTION],
  // },
  {
    key: ROUND_TYPE_Q_AND_A,
    value: ROUND_TYPE_Q_AND_A,
    text: roundTypeLookup[ROUND_TYPE_Q_AND_A],
  },
  {
    key: ROUND_TYPE_MULTIPLE_CHOICE,
    value: ROUND_TYPE_MULTIPLE_CHOICE,
    text: roundTypeLookup[ROUND_TYPE_MULTIPLE_CHOICE],
  },
]

const responseTimingOptions = [
  {
    key: RESPONSE_TIMING_EVERYONE,
    value: RESPONSE_TIMING_EVERYONE,
    text: responseTimingLookup[RESPONSE_TIMING_EVERYONE],
  },
  {
    key: RESPONSE_TIMING_FF_FIRST,
    value: RESPONSE_TIMING_FF_FIRST,
    text: responseTimingLookup[RESPONSE_TIMING_FF_FIRST],
  },
]

const responseTimingOptionDescriptions = responseTimingDescriptionLookup

const playerResponseOptions = [
  {
    key: RESPONSE_CAPTURE_TEXT,
    value: RESPONSE_CAPTURE_TEXT,
    text: playerResponseLookup[RESPONSE_CAPTURE_TEXT],
  },
  {
    key: RESPONSE_CAPTURE_AV,
    value: RESPONSE_CAPTURE_AV,
    text: playerResponseLookup[RESPONSE_CAPTURE_AV],
  },
]

export default function useAddEditRound({
  initialMode = 'add',
  roundId: roundIdProp,
  onSaveRoundSuccess,
} = {}) {
  const [mode, setMode] = useState(initialMode)
  const [roundId, setRoundId] = useState(roundIdProp)
  const isHandlingChange = useRef(false)
  const { gameData, onSaveRound: onSaveRoundQuizManager, readOnly } = useQuizManagerContext()
  const { questions, ...otherRoundProps } = getRound({ gameData, roundId })
  const { inputs, handleInputChange, hasChanges: hasFormChanges, setFormOriginValues } = useForm({
    initialValues: otherRoundProps,
  })
  const [roundQuestions, setRoundQuestions] = useState(questions)

  const [addEditQuestionDisplayProps, setAddEditQuestionDisplayProps] = useState({ open: false })
  const { roundName, roundType, responseTiming, responseCapture, pointsPerQuestion } = inputs
  const needsQuestions = roundType !== ROUND_TYPE_ADHOC
  const questionType = roundToQuestionTypeMap[roundType]
  const hasRoundQuestionChanges = !_isEqual(sanitizeData(questions), sanitizeData(roundQuestions))
  const hasAddEditChanges = hasFormChanges || hasRoundQuestionChanges

  let fieldsToDisplay = ['roundName']

  const cancelAddEditQuestion = useCallback(() => {
    setAddEditQuestionDisplayProps({ open: false })
  }, [setAddEditQuestionDisplayProps])

  const onSaveRound = useCallback(() => {
    // TODO: only send the fields we need - as input entries are effectively cached at the moment
    // which is probably a nice UX, as data doesn't get wiped if you tweak the fields
    // but could lead to irrelevant data being sent for a given round type
    onSaveRoundQuizManager({
      mode,
      roundId,
      round: {
        // TODO: include only necessary fields for round type
        ...inputs,
        questions: {
          ...roundQuestions,
        },
      },
      onSuccess: ({ roundId }) => {
        setFormOriginValues({ values: inputs })
        setMode('edit')
        setRoundId(roundId)
        if (onSaveRoundSuccess) {
          onSaveRoundSuccess()
        }
      },
    })
  }, [
    inputs,
    mode,
    roundId,
    roundQuestions,
    onSaveRoundQuizManager,
    onSaveRoundSuccess,
    setFormOriginValues,
    setMode,
    setRoundId,
  ])

  const onAddQuestion = useCallback(() => {
    setAddEditQuestionDisplayProps({
      open: true,
      mode: 'add',
      questionType,
      onSubmit: (question) => {
        setRoundQuestions((roundQuestions) => ({
          ...roundQuestions,
          [getUuid({ prefix: 'question-' })]: {
            ...question,
            order: Object.keys(roundQuestions).length,
          },
        }))
        setAddEditQuestionDisplayProps({ open: false })
      },
      onCancel: cancelAddEditQuestion,
    })
  }, [questionType, setAddEditQuestionDisplayProps, setRoundQuestions, cancelAddEditQuestion])

  const onEditQuestion = useCallback(
    ({ questionId, question }) => {
      setAddEditQuestionDisplayProps({
        open: true,
        mode: 'edit',
        questionType,
        question,
        onSubmit: (question) => {
          setRoundQuestions((roundQuestions) => ({
            ...roundQuestions,
            [questionId]: {
              ...question,
            },
          }))
          setAddEditQuestionDisplayProps({ open: false })
        },
        onCancel: cancelAddEditQuestion,
        onDelete: () => {
          setRoundQuestions((roundQuestions) => {
            const { [questionId]: questionToRemove, ...remainingQuestions } = roundQuestions
            return remainingQuestions
          })
          setAddEditQuestionDisplayProps({ open: false })
        },
      })
    },
    [questionType, setAddEditQuestionDisplayProps, cancelAddEditQuestion],
  )

  // auto save if rounds have been updated
  useEffect(() => {
    const hasRoundQuestionChanges = !_isEqual(sanitizeData(questions), sanitizeData(roundQuestions))
    if (hasRoundQuestionChanges) {
      if (!isHandlingChange.current) {
        isHandlingChange.current = true
        onSaveRound()
      }
    } else {
      isHandlingChange.current = false
    }
  }, [questions, roundQuestions, onSaveRound])

  if (roundName) {
    fieldsToDisplay.push('roundType')

    if (
      [ROUND_TYPE_OPEN_QUESTION, ROUND_TYPE_Q_AND_A, ROUND_TYPE_MULTIPLE_CHOICE].indexOf(
        roundType,
      ) > -1
    ) {
      fieldsToDisplay.push('responseTiming')

      // if (responseTiming) {
      //   fieldsToDisplay.push('responseCapture')
      // }

      if (responseTiming && responseCapture) {
        fieldsToDisplay.push('pointsPerQuestion')
      }

      if (mode === 'edit') {
        if (needsQuestions && responseTiming && responseCapture && pointsPerQuestion) {
          fieldsToDisplay.push('questions')
        }

        if (responseTiming && responseCapture && pointsPerQuestion) {
          fieldsToDisplay.push('saveButton')
        }
      }

      if (mode === 'add') {
        if (responseTiming && responseCapture && pointsPerQuestion) {
          fieldsToDisplay.push('saveButton')
        }
      }
    }

    if (roundType === ROUND_TYPE_ADHOC) {
      if (roundName) {
        fieldsToDisplay.push('saveButton')
      }
    }

    if (mode === 'edit' && !hasAddEditChanges) {
      // remove save button if no changes and we are in edit mode
      fieldsToDisplay = fieldsToDisplay.filter((field) => field !== 'saveButton')
    }
  }

  return {
    inputs,
    handleInputChange,
    addEditQuestionDisplayProps,
    roundTypeOptions,
    responseTimingOptions,
    responseTimingOptionDescriptions,
    playerResponseOptions,
    fieldsToDisplay,
    roundQuestions,
    onAddQuestion,
    onEditQuestion,
    onSaveRound,
    readOnly,
    mode,
  }
}
