import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { Icon, Progress, Loader, Popup } from 'semantic-ui-react'

import { getUuid } from 'module/utils'

import './sound-player.css'

import useInterval from 'hooks/useInterval'

// https://developers.soundcloud.com/docs/api/html5-widget

function SoundPlayer({
  trackUrl,
  lengthToPlay = 10 * 1000,
  autoPlay = false,
  canPlay = true,
  cantPlayMessage = '',
  onTrackLoaded,
  onTrackLoadError,
  onTrackStarted,
  onTrackStopped,
}) {
  const initialised = useRef(false)
  const autoPlayValueRef = useRef(autoPlay)
  const soundPlayerUuid = useRef(getUuid({ prefix: 'sound-player' }))
  const [trackLoaded, setTrackLoaded] = useState(false)
  const [trackLoadError, setTrackLoadError] = useState(false)
  const [trackPlaying, setTrackPlaying] = useState(false)
  const trackPlayingValueCache = useRef(trackPlaying)
  const checkForScriptsRef = useRef()
  const iFrameRef = useRef()
  const soundCloudWidgetRef = useRef()
  const playTimer = useRef()
  const shouldBePlayingRef = useRef()
  const isAutoPlayRequest = useRef()

  const stopTrackSnippet = useCallback(() => {
    if (playTimer.current) {
      clearTimeout(playTimer.current)
    }
    soundCloudWidgetRef.current.pause()
    setTrackPlaying(false)
    shouldBePlayingRef.current = false
  }, [setTrackPlaying])

  const playTrackSnippet = useCallback(
    ({ isAutoPlay } = {}) => {
      if (!shouldBePlayingRef.current) {
        if (soundCloudWidgetRef.current) {
          shouldBePlayingRef.current = true
          isAutoPlayRequest.current = isAutoPlay
          soundCloudWidgetRef.current.seekTo(0)
          soundCloudWidgetRef.current.play()
          playTimer.current = setTimeout(stopTrackSnippet, lengthToPlay)
        }
      }
    },
    [stopTrackSnippet, lengthToPlay, soundCloudWidgetRef],
  )

  const trackReadyListener = useCallback(() => {
    setTrackLoaded(true)
    if (autoPlay) {
      playTrackSnippet({ isAutoPlay: true })
    }
  }, [autoPlay, setTrackLoaded, playTrackSnippet])

  const trackErrorListener = useCallback(() => {
    setTrackPlaying(false)
    setTrackLoadError(true)
  }, [setTrackPlaying, setTrackLoadError])

  const trackPlayListener = useCallback(() => {
    if (!isAutoPlayRequest.current) {
      setTrackPlaying(true)
    }
  }, [isAutoPlayRequest, setTrackPlaying])

  // we use track progress listener for 'autoplay' requests as safari doesn't
  // allow auto play of audio tracks (but the PLAY event still fires when attempting it)
  const trackProgressListener = useCallback(
    ({ currentPosition }) => {
      if (shouldBePlayingRef.current && !trackPlaying && currentPosition > 0) {
        if (currentPosition > 0) {
          setTrackPlaying(true)
        }
      }
    },
    [shouldBePlayingRef, trackPlaying, setTrackPlaying],
  )

  const trackStoppedListener = useCallback(() => {
    shouldBePlayingRef.current = false
    setTrackPlaying(false)
  }, [setTrackPlaying, shouldBePlayingRef])

  const initialiseAudio = useCallback(() => {
    if (!initialised.current) {
      initialised.current = true
      const iFrame = iFrameRef.current
      soundCloudWidgetRef.current = window.SC.Widget(iFrame)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.READY, trackReadyListener)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.ERROR, trackErrorListener)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.PLAY, trackPlayListener)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.PLAY_PROGRESS, trackProgressListener)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.PAUSE, trackStoppedListener)
      soundCloudWidgetRef.current.bind(window.SC.Widget.Events.FINISHED, trackStoppedListener)
    }
  }, [
    initialised,
    iFrameRef,
    soundCloudWidgetRef,
    trackReadyListener,
    trackErrorListener,
    trackPlayListener,
    trackProgressListener,
    trackStoppedListener,
  ])

  const checkForScripts = useCallback(() => {
    if (checkForScriptsRef.current) {
      checkForScriptsRef.current()
    }
  }, [checkForScriptsRef])

  const { start: startCheckForScripts, stop: stopCheckForScripts } = useInterval({
    callback: checkForScripts,
    delay: 100,
    autoStart: false,
  })

  useEffect(() => {
    if (!window.SC || !window.SC.Widget) {
      const soundCloudScript = document.createElement('script')
      soundCloudScript.src = 'https://w.soundcloud.com/player/api.js'
      soundCloudScript.async = true
      document.body.appendChild(soundCloudScript)
    }
    if (!checkForScriptsRef.current) {
      checkForScriptsRef.current = () => {
        if (window.SC && window.SC.Widget) {
          stopCheckForScripts()
          initialiseAudio()
        }
      }
    }
    startCheckForScripts()
  }, [checkForScriptsRef, startCheckForScripts, stopCheckForScripts, initialiseAudio])

  useEffect(() => {
    return () => {
      if (playTimer.current) {
        clearTimeout(playTimer.current)
      }
      if (soundCloudWidgetRef.current) {
        soundCloudWidgetRef.current.pause()
        soundCloudWidgetRef.current.unbind(window.SC.Widget.Events.READY)
        soundCloudWidgetRef.current.unbind(window.SC.Widget.Events.ERROR)
        soundCloudWidgetRef.current.unbind(window.SC.Widget.Events.PAUSE)
        soundCloudWidgetRef.current.unbind(window.SC.Widget.Events.FINISHED)
      }
    }
  }, [])

  useEffect(() => {
    if (soundPlayerUuid.current) {
      const animationDuration = `${lengthToPlay / 1000}s`
      const customSoundPlayerStyle = document.createElement('style')
      document.head.appendChild(customSoundPlayerStyle)
      customSoundPlayerStyle.sheet.insertRule(
        `
        .sound-player.${soundPlayerUuid.current}.playing .sound-player-progress .bar {
          transition: width ${animationDuration} linear !important;
        }
      `,
        0,
      )
    }
  }, [soundPlayerUuid, lengthToPlay])

  useEffect(() => {
    if (trackLoaded) {
      if (trackLoadError) {
        if (onTrackLoadError) {
          onTrackLoadError()
        }
      } else {
        if (onTrackLoaded) {
          onTrackLoaded()
        }
      }
    }
  }, [trackLoaded, trackLoadError, onTrackLoaded, onTrackLoadError])

  useEffect(() => {
    if (autoPlayValueRef.current !== autoPlay) {
      if (autoPlay) {
        playTrackSnippet({ isAutoPlay: true })
      }
      autoPlayValueRef.current = autoPlay
    }
  }, [autoPlayValueRef, autoPlay, playTrackSnippet])

  useEffect(() => {
    if (onTrackStarted || onTrackStopped) {
      if (trackPlaying !== trackPlayingValueCache.current) {
        trackPlayingValueCache.current = trackPlaying
        if (trackPlaying) {
          onTrackStarted({ trackUrl })
        } else {
          onTrackStopped({ trackUrl })
        }
      }
    }
  }, [trackUrl, trackPlaying, trackPlayingValueCache, onTrackStarted, onTrackStopped])

  const trackPlayingClassName = useMemo(() => {
    return trackPlaying ? 'playing' : 'not-playing'
  }, [trackPlaying])

  const progressPercent = useMemo(() => {
    return trackPlaying ? 100 : 0
  }, [trackPlaying])

  const onClickPlay = useCallback(() => {
    if (canPlay) {
      playTrackSnippet()
    }
  }, [playTrackSnippet, canPlay])

  return (
    <div className={`sound-player ${trackPlayingClassName} ${soundPlayerUuid.current}`}>
      {!!trackLoadError && <div className="sound-player-controls error">Error loading track</div>}
      {!trackLoadError && !trackLoaded && (
        <div className="sound-player-controls-loader">
          <Loader active inline inverted size="small" />
        </div>
      )}
      {!trackLoadError && trackLoaded && (
        <div className="sound-player-controls">
          <div>
            <Popup
              disabled={canPlay}
              on="click"
              trigger={
                <Icon
                  name="play circle"
                  size="big"
                  link
                  onClick={onClickPlay}
                  style={{ marginRight: 0 }}
                />
              }
              content={<p>{cantPlayMessage}</p>}
            />
            <Icon name="stop circle" size="big" link onClick={stopTrackSnippet} />
          </div>
          <div className="sound-player-progress-container">
            <Progress className="sound-player-progress" percent={progressPercent} />
          </div>
        </div>
      )}
      <div
        style={{
          display: 'none',
        }}
      >
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <div>
                                      <div>
                                        <div>
                                          <div>
                                            <div>
                                              <div>
                                                <iframe
                                                  ref={iFrameRef}
                                                  height="0"
                                                  width="0"
                                                  scrolling="no"
                                                  frameBorder="no"
                                                  allow="autoplay"
                                                  data-app-version="IF YOU ARE READING THIS THEN YOU ARE A SNEAKY MUSIC QUESTION CHEAT"
                                                  src={`https://w.soundcloud.com/player/?url=${trackUrl}`}
                                                ></iframe>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// if the `trackUrl` changes then we un-mount the component in order to kick
// off a whole new iframe load (bit hacky but does the job)

export default ({ trackUrl, ...otherProps }) => {
  const trackUrlRef = useRef()
  const reMountTimer = useRef()
  const [mounted, setMounted] = useState(true)
  const reMount = useCallback(() => {
    setMounted(true)
  }, [setMounted])
  useEffect(() => {
    if (!trackUrlRef.current) {
      trackUrlRef.current = trackUrl
    } else if (trackUrlRef.current !== trackUrl) {
      trackUrlRef.current = trackUrl
      clearTimeout(reMountTimer.current)
      setMounted(false)
      reMountTimer.current = setTimeout(reMount, 400)
    }
    return () => {
      clearTimeout(reMountTimer.current)
    }
  }, [trackUrlRef, trackUrl, reMount])
  if (!mounted) {
    return (
      <div className="sound-player-controls-loader">
        <Loader active inline inverted size="small" />
      </div>
    )
  }
  return <SoundPlayer trackUrl={trackUrl} {...otherProps} />
}
