import React, { useState } from 'react'
import { Tab, Button } from 'semantic-ui-react'

import { RESPONSE_ASSESSMENT_CORRECT } from 'module/constants'
import { AssessmentButton } from 'component/AssessmentButtons'
import Typography from 'component/Typography'
import SoundPlayer from 'component/SoundPlayer'

import ResponseList from 'feature/QuizManager/components/QuestionDisplay/ResponseList'
import QuestionImage from 'feature/QuizManager/components/QuestionDisplay/QuestionImage'

function OptionsDisplay({
  isHost,
  playerResponse,
  answerRevealed,
  playerHasResponded,
  selectedOptionId,
  setSelectedOptionId,
  answerOptions,
  correctAnswerOptionId,
  onSubmit,
}) {
  const { assessment, answerOptionId } = playerResponse
  // const showSubmitButton =
  //   !!onSubmit &&
  //   !answerRevealed &&
  //   !playerHasResponded &&
  //   selectedOptionId &&
  //   answerOptions[selectedOptionId]
  const showAwaitingOtherPlayerResponses = !isHost && !answerRevealed && playerHasResponded
  let showAssessmentButton = true
  if (!isHost && !answerRevealed) {
    showAssessmentButton = false
  }
  return (
    <div>
      <div>
        {Object.entries(answerOptions).map(([id, { answerText }]) => {
          let isSelectedOption = id === answerOptionId
          if (!isSelectedOption && !answerRevealed) {
            // use the local selected option state
            isSelectedOption = id === selectedOptionId
          }
          const isCorrectOption = id === correctAnswerOptionId
          let opacity = 0
          let neutral = false
          let assessmentDisplay
          if (isHost) {
            if (isCorrectOption) {
              opacity = 1
              assessmentDisplay = RESPONSE_ASSESSMENT_CORRECT
            }
          } else {
            if (answerRevealed && isSelectedOption) {
              opacity = 1
              assessmentDisplay = assessment
            } else if (answerRevealed && isCorrectOption) {
              opacity = 1
              neutral = true
              assessmentDisplay = RESPONSE_ASSESSMENT_CORRECT
            }
          }
          const onClick = isHost
            ? undefined
            : () => {
                if (!playerHasResponded && !selectedOptionId) {
                  setSelectedOptionId(id)
                  onSubmit({ answerOptionId: id })
                  // setSelectedOptionId(id)
                }
              }
          return (
            <div key={id} className="multiple-choice-display-option-container">
              <Button
                fluid
                primary={isSelectedOption}
                size="large"
                style={{ textAlign: 'left' }}
                onClick={onClick}
              >
                {answerText}
              </Button>
              {showAssessmentButton && (
                <AssessmentButton
                  assessment={assessmentDisplay}
                  neutral={neutral}
                  style={{ opacity: opacity }}
                />
              )}
            </div>
          )
        })}
      </div>
      {/* {!!showSubmitButton && (
        <div>
          <Button
            fluid
            primary
            size="large"
            style={{ marginTop: '2em', marginBottom: '1em' }}
            onClick={() => {
              onSubmit({ answerOptionId: selectedOptionId })
            }}
          >
            Submit
          </Button>
        </div>
      )} */}
      {!!showAwaitingOtherPlayerResponses && (
        <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'center' }}>
          <Typography.text alt>Awaiting other player responses</Typography.text>
        </div>
      )}
    </div>
  )
}

export default function MultipleChoiceDisplay({
  isHost,
  questionId,
  question = {},
  onSubmit,
  answerRevealed,
  questionRevealed,
  correctAnswerOptionId,
  playerResponse = {},
  responses,
  playerHasResponded,
  onLocalSoundtrackStarted,
  onLocalSoundtrackStopped,
}) {
  const { questionType, questionText, questionImage, questionAudio, answerOptions = {} } = question
  const [selectedOptionId, setSelectedOptionId] = useState('')
  const panes = []
  const optionsDisplayProps = {
    isHost,
    playerResponse,
    answerRevealed,
    correctAnswerOptionId,
    playerHasResponded,
    selectedOptionId,
    setSelectedOptionId,
    answerOptions,
    onSubmit,
  }
  if ((!isHost && answerRevealed) || (isHost && questionRevealed)) {
    panes.push({
      menuItem: 'Options',
      render: () => <OptionsDisplay {...optionsDisplayProps} />,
    })
    panes.push({
      menuItem: 'Player Responses',
      render: () => (
        <ResponseList
          isHost={isHost}
          questionType={questionType}
          responses={responses}
          answerRevealed={answerRevealed}
          answerOptions={answerOptions}
        />
      ),
    })
  }
  const defaultActiveIndex = 1
  let answerText = ''
  Object.entries(answerOptions).forEach(([id, { answerText: optionAnswerText }]) => {
    if (id === correctAnswerOptionId) {
      answerText = optionAnswerText
    }
  })

  return (
    <div className="player-question-display-container">
      <div className="player-question-display-question-and-answer">
        <h3>{questionText}</h3>
        {!!questionImage && (
          <QuestionImage
            questionId={questionId}
            questionImage={questionImage}
            minimised={playerHasResponded || isHost}
            containerStyle={{ marginTop: 0 }}
          />
        )}
        {!!isHost && !!questionAudio && (
          <div style={{ marginBottom: '1em' }}>
            <SoundPlayer
              trackUrl={questionAudio}
              canPlay={!!questionRevealed}
              cantPlayMessage="Track can only be played once the question is shown to players"
              onTrackStarted={onLocalSoundtrackStarted}
              onTrackStopped={onLocalSoundtrackStopped}
            />
          </div>
        )}
        {!isHost && !!questionAudio && (
          <div style={{ marginBottom: '1em' }}>
            <SoundPlayer
              autoPlay
              trackUrl={questionAudio}
              onTrackStarted={onLocalSoundtrackStarted}
              onTrackStopped={onLocalSoundtrackStopped}
            />
          </div>
        )}
        {answerRevealed && answerText && (
          <div style={{ marginBottom: '1em' }}>
            <Typography.h3 alt>{answerText}</Typography.h3>
          </div>
        )}
        {!panes.length && <OptionsDisplay {...optionsDisplayProps} />}
        {isHost && !!panes.length && (
          <Tab
            defaultActiveIndex={defaultActiveIndex}
            panes={panes}
            menu={{ secondary: true, pointing: true }}
          />
        )}
        {!isHost && !!panes.length && (
          <ResponseList
            isHost={isHost}
            questionType={questionType}
            responses={responses}
            answerRevealed={answerRevealed}
            answerOptions={answerOptions}
          />
        )}
      </div>
    </div>
  )
}
