import React from 'react'
import { Image, Loader } from 'semantic-ui-react'

import './response-list.css'

import { hasValue } from 'module/utils'
import { getDurationDisplay } from 'module/displayFormat'
import { getServerTimeStampToNormalisedFormat } from 'module/dates'
import {
  RESPONSE_TIMING_FF_FIRST,
  RESPONSE_TIMING_EVERYONE,
  RESPONSE_ASSESSMENT_CORRECT,
  RESPONSE_CAPTURE_TEXT,
  QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'module/constants'

import { AssessmentButton, HostAssessmentButtons } from 'component/AssessmentButtons'
import TimeCounter from 'component/TimeCounter'
import Typography from 'component/Typography'

function ResponseItem({
  response,
  isHost,
  showDuration,
  canAssess,
  questionType,
  answerOptions,
  responseCapture,
  onAssessCorrect,
  onAssessWrong,
}) {
  const {
    playerId,
    playerName,
    pass,
    playerImage,
    responseDuration,
    assessment,
    answerOptionId,
    answerText,
  } = response
  let responseText = ''
  let requiresHostAssessment = true
  if (responseCapture === RESPONSE_CAPTURE_TEXT) {
    responseText = answerText
  }
  if (
    questionType === QUESTION_TYPE_MULTIPLE_CHOICE &&
    answerOptionId &&
    answerOptions[answerOptionId]
  ) {
    responseText = answerOptions[answerOptionId].answerText
  }
  if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
    requiresHostAssessment = false
  }
  let assessmentDisplay = null
  const durationDisplay = showDuration
    ? `(${getDurationDisplay({ duration: responseDuration })})`
    : ''
  if (!pass) {
    if (isHost && requiresHostAssessment) {
      if (assessment || canAssess) {
        assessmentDisplay = (
          <HostAssessmentButtons
            assessment={assessment}
            onAssessCorrect={() => {
              onAssessCorrect({
                playerId,
              })
            }}
            onAssessWrong={() => {
              onAssessWrong({
                playerId,
              })
            }}
          />
        )
      }
    } else if (assessment) {
      assessmentDisplay = <AssessmentButton assessment={assessment} />
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image src={playerImage} rounded size="mini" />
        <div style={{ marginLeft: '1em' }}>
          <div>
            <span style={{ marginRight: '0.5em' }}>{playerName}</span>
            {durationDisplay}
          </div>
          {hasValue(responseText) && (
            <div style={{ color: 'grey' }}>
              <span>{responseText}</span>
            </div>
          )}
        </div>
      </div>
      <div style={{ whiteSpace: 'nowrap', paddingLeft: 2 }}>{assessmentDisplay}</div>
    </div>
  )
}

export default function ResponseList({
  isHost,
  responses = [],
  questionType,
  answerRevealed,
  questionRevealedAt,
  answerOptions = {},
  responseTiming,
  responseCapture,
  onAssessCorrect,
  onAssessWrong,
}) {
  const responseList = responses.filter(({ pass }) => !pass)
  const passList = responses.filter(({ pass }) => !!pass)
  let hasOneCorrectAssessment
  let hasOneAssessment
  if (isHost && !responses.length && !answerRevealed) {
    const from = getServerTimeStampToNormalisedFormat(questionRevealedAt)
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.text alt>Awaiting responses</Typography.text>
        <TimeCounter from={from} />
      </div>
    )
  } else if (isHost && !responses.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.text alt>No responses</Typography.text>
      </div>
    )
  } else if (!isHost && !responses.length && !answerRevealed) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Loader active inline="centered" />
      </div>
    )
  }
  return (
    <div>
      {!!responseList.length && <h4>Responses</h4>}
      {responseList.map((response) => {
        let canAssess
        if (responseTiming === RESPONSE_TIMING_EVERYONE) {
          canAssess = true
        }
        if (responseTiming === RESPONSE_TIMING_FF_FIRST) {
          canAssess = false
          if (response.assessment === RESPONSE_ASSESSMENT_CORRECT) {
            hasOneCorrectAssessment = true
          }
          if (
            !hasOneCorrectAssessment &&
            !hasOneAssessment &&
            !response.assessment &&
            !response.pass
          ) {
            hasOneAssessment = true
            canAssess = true
          }
        }
        if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
          canAssess = false
        }
        return (
          <ResponseItem
            key={response.playerId}
            questionType={questionType}
            answerOptions={answerOptions}
            response={response}
            responseCapture={responseCapture}
            isHost={isHost}
            showDuration={true}
            canAssess={canAssess}
            onAssessCorrect={onAssessCorrect}
            onAssessWrong={onAssessWrong}
          />
        )
      })}
      {!!passList.length && <h4 style={{ marginTop: '1em' }}>Passes</h4>}
      {passList.map((response) => {
        return <ResponseItem key={response.playerId} response={response} showDuration={false} />
      })}
    </div>
  )
}
