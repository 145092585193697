import { useRouteMatch } from 'react-router-dom'

const useAuthNav = () => {
  // for now, all but the landing page is considered to be a secure route
  const match = useRouteMatch('/') || {}
  const { path, isExact } = match
  const isHomePage = path === '/' && isExact
  // bit hackish, but "/" seems to win here for some reason
  const isSignUpRoute = window.location.pathname === '/sign-up'
  return {
    isSecureRoute: !isHomePage,
    isSignUpRoute,
  }
}

export default useAuthNav
