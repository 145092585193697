import React, { useRef } from 'react'

import useVideoPlayerSlaveOpenTok from 'hooks/useVideoPlayerSlaveOpenTok'

import './video-player.css'

export default function VideoPlayerSlaveOpenTok({
  isMe,
  videoTrack,
  onEnableVideoConnection,
  onDisableVideoConnection,
  onEnableAudioConnection,
  onDisableAudioConnection,
  audioEnabled,
  videoEnabled,
}) {
  const videoRef = useRef()
  const audioRef = useRef()
  useVideoPlayerSlaveOpenTok({
    isMe,
    videoRef,
    audioRef,
    videoTrack,
    audioEnabled,
    videoEnabled,
    onEnableVideoConnection,
    onDisableVideoConnection,
    onEnableAudioConnection,
    onDisableAudioConnection,
  })
  return (
    <div className="video-container">
      <div ref={videoRef} />
    </div>
  )
}
