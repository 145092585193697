import React from 'react'

import { colors } from 'module/theme'
import './typography.css'

const textSizeClassName = {
  megaText: 'qha-typography-size-mega-text',
  small: 'qha-typography-size-small-text',
}

const ElementMap = {
  h1: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
  h2: ({ children, ...props }) => <h2 {...props}>{children}</h2>,
  h3: ({ children, ...props }) => <h3 {...props}>{children}</h3>,
  h4: ({ children, ...props }) => <h4 {...props}>{children}</h4>,
  h5: ({ children, ...props }) => <h5 {...props}>{children}</h5>,
  h6: ({ children, ...props }) => <h6 {...props}>{children}</h6>,
  text: ({ children, ...props }) => <span {...props}>{children}</span>,
}

function Text({
  children,
  type,
  primary = false,
  secondary = false,
  tertiary = false,
  alt = false,
  size,
  style = {},
  className = '',
}) {
  const props = {
    style: {},
    className: '',
  }
  const Element = ElementMap[type]
  // colors
  if (primary) {
    props.style = {
      ...props.style,
      color: colors.primary,
    }
  } else if (secondary) {
    props.style = {
      ...props.style,
      color: colors.secondary,
    }
  } else if (tertiary) {
    props.style = {
      ...props.style,
      color: colors.tertiary,
    }
  } else if (alt) {
    props.style = {
      ...props.style,
      color: colors.alt,
    }
  }
  // size
  if (size && textSizeClassName[size]) {
    props.className = `${props.className} ${textSizeClassName[size]}`
  }
  props.style = {
    ...props.style,
    ...style,
  }
  props.className = `${props.className} ${className}`
  return <Element {...props}>{children}</Element>
}

const Typography = {
  h1: (props) => <Text type="h1" {...props} />,
  h2: (props) => <Text type="h2" {...props} />,
  h3: (props) => <Text type="h3" {...props} />,
  h4: (props) => <Text type="h4" {...props} />,
  h5: (props) => <Text type="h5" {...props} />,
  h6: (props) => <Text type="h6" {...props} />,
  text: (props) => <Text type="text" {...props} />,
  smallText: (props) => <Text type="text" size="small" {...props} />,
}

export default Typography
