import { useEffect } from 'react'

import { useAppState, useAppDispatch } from 'store/App'
import { getIsUserDataListenerInitialised, getIsUserAuthenticated } from 'store/App/selectors'
import { startUserDataListener } from 'store/App/actions'

const useUserData = () => {
  const state = useAppState()
  const dispatch = useAppDispatch()
  const isUserAuthenticated = getIsUserAuthenticated(state)
  const isUserDataListenerInitialised = getIsUserDataListenerInitialised(state)
  useEffect(() => {
    if (isUserAuthenticated && !isUserDataListenerInitialised) {
      startUserDataListener(dispatch)
    }
  }, [dispatch, isUserAuthenticated, isUserDataListenerInitialised])
  return state
}

export default useUserData
