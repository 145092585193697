import React from 'react'

import { AppStateContext } from 'store/App/context'

class StateDebug extends React.Component {
  componentDidMount() {
    window.QHLDEBUG = window.QHLDEBUG || {}
    window.QHLDEBUG.logAppState = () => {
      console.log('*** AppStateContext', {
        context: this.context,
      })
    }
  }
  render() {
    return null
  }
}
StateDebug.contextType = AppStateContext

export default StateDebug
