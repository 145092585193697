import { useState, useCallback } from 'react'

import useInterval from 'hooks/useInterval'

export default function RenderOnInterval({ interval = 1000, children, test = () => true }) {
  const [displayed, setDisplayed] = useState(false)
  const callback = useCallback(() => {
    if (test()) {
      setDisplayed(true)
    } else {
      setDisplayed(false)
    }
  }, [setDisplayed, test])
  useInterval({
    callback,
    interval,
    autoStart: true,
  })
  if (!displayed) {
    return null
  }
  return children
}
