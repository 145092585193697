import { useEffect } from 'react'

const useVideoPlayerSlaveOpenTok = ({
  isMe,
  videoRef,
  audioTrack,
  videoTrack,
  audioEnabled,
  videoEnabled,
  onEnableAudioConnection,
  onDisableAudioConnection,
  onEnableVideoConnection,
  onDisableVideoConnection,
}) => {
  useEffect(() => {
    if (videoTrack) {
      videoRef.current.appendChild(videoTrack)
    }
  }, [videoRef, videoTrack])

  useEffect(() => {
    if (isMe) {
      if (audioEnabled) {
        onEnableAudioConnection()
      } else {
        onDisableAudioConnection()
      }
    }
  }, [audioEnabled, isMe, onEnableAudioConnection, onDisableAudioConnection])

  useEffect(() => {
    if (isMe) {
      if (videoEnabled) {
        onEnableVideoConnection()
      } else {
        onDisableVideoConnection()
      }
    }
  }, [videoEnabled, isMe, onEnableVideoConnection, onDisableVideoConnection])
}

export default useVideoPlayerSlaveOpenTok
