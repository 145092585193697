import React from 'react'
import { Button } from 'semantic-ui-react'

import './host-question-display.css'

import Typography from 'component/Typography'
import ConfirmButton from 'component/ConfirmButton'
import SoundPlayer from 'component/SoundPlayer'

import ResponseList from 'feature/QuizManager/components/QuestionDisplay/ResponseList'
import MultipleChoiceDisplay from 'feature/QuizManager/components/QuestionDisplay/MultipleChoiceDisplay'
import QuestionImage from 'feature/QuizManager/components/QuestionDisplay/QuestionImage'

import {
  QUESTION_TYPE_QUESTION_AND_ANSWER,
  RESPONSE_TIMING_FF_FIRST,
  QUESTION_TYPE_OPEN_QUESTION,
  QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'module/constants'

export default function HostQuestionDisplay({
  question,
  currentQuestionId,
  questionRevealed,
  questionRevealedAt,
  answerRevealed,
  correctAnswerOptionId,
  responseTiming,
  responseCapture,
  onRevealQuestion,
  onRevealAnswer,
  onGoToNextQuestion,
  onEndRound,
  onAssessCorrect,
  onAssessWrong,
  onLocalSoundtrackStarted,
  onLocalSoundtrackStopped,
  responses = [],
  allPlayersResponded,
  allResponsesAssessed,
  hasOneCorrectResponse,
  isFinalQuestionInRound,
}) {
  const { questionType, questionText, questionImage, questionAudio, answerText } = question
  let confirmMessage
  if (
    responseTiming !== RESPONSE_TIMING_FF_FIRST ||
    (responseTiming === RESPONSE_TIMING_FF_FIRST && !hasOneCorrectResponse)
  ) {
    if (!allPlayersResponded) {
      confirmMessage = 'Not all player responses have been received yet, are you sure?'
    }
    if (
      allPlayersResponded &&
      !allResponsesAssessed &&
      questionType !== QUESTION_TYPE_MULTIPLE_CHOICE
    ) {
      confirmMessage = 'Not all player responses have been assessed yet, are you sure?'
    }
  }
  let nextAction = {
    title: 'Go to next question',
    action: onGoToNextQuestion,
  }
  if (isFinalQuestionInRound) {
    nextAction = {
      title: 'End Round',
      action: onEndRound,
    }
  }
  if (
    !answerRevealed &&
    (questionType === QUESTION_TYPE_QUESTION_AND_ANSWER ||
      questionType === QUESTION_TYPE_MULTIPLE_CHOICE)
  ) {
    nextAction = {
      title: 'Reveal Answer',
      action: onRevealAnswer,
    }
  }

  let showInlineQuestionAndAnswer = false
  let showInlineResponses = false
  if (
    questionType === QUESTION_TYPE_QUESTION_AND_ANSWER ||
    questionType === QUESTION_TYPE_OPEN_QUESTION
  ) {
    showInlineResponses = true
    showInlineQuestionAndAnswer = true
  }

  return (
    <div className="host-question-display-container">
      {showInlineQuestionAndAnswer && (
        <div className="host-question-display-question-and-answer">
          <div>
            <Typography.h3>{questionText}</Typography.h3>
          </div>
          {!!questionImage && (
            <QuestionImage
              questionId={currentQuestionId}
              questionImage={questionImage}
              minimised={questionRevealed}
            />
          )}
          {!!questionAudio && (
            <SoundPlayer
              trackUrl={questionAudio}
              canPlay={!!questionRevealed}
              cantPlayMessage="Track can only be played once the question is shown to players"
              onTrackStarted={onLocalSoundtrackStarted}
              onTrackStopped={onLocalSoundtrackStopped}
            />
          )}
          <div style={{ marginTop: '1em' }}>
            <Typography.h3 alt>{answerText}</Typography.h3>
          </div>
        </div>
      )}
      {questionType === QUESTION_TYPE_MULTIPLE_CHOICE && (
        <MultipleChoiceDisplay
          isHost
          questionId={currentQuestionId}
          question={question}
          answerRevealed={answerRevealed}
          questionRevealed={questionRevealed}
          correctAnswerOptionId={correctAnswerOptionId}
          responseCapture={responseCapture}
          responses={responses}
        />
      )}
      {!questionRevealed && (
        <div style={{ marginTop: '1em' }}>
          <Button primary fluid onClick={onRevealQuestion}>
            Show Question To Players
          </Button>
        </div>
      )}
      {questionRevealed && showInlineResponses && (
        <div>
          <ResponseList
            isHost
            questionType={questionType}
            responses={responses}
            questionRevealed={questionRevealed}
            answerRevealed={answerRevealed}
            questionRevealedAt={questionRevealedAt}
            responseTiming={responseTiming}
            responseCapture={responseCapture}
            onAssessCorrect={onAssessCorrect}
            onAssessWrong={onAssessWrong}
          />
        </div>
      )}
      {questionRevealed && (
        <div>
          <div style={{ marginTop: '1em' }}>
            {!confirmMessage && (
              <Button primary content={nextAction.title} fluid onClick={nextAction.action} />
            )}
            {!!confirmMessage && (
              <ConfirmButton
                message={confirmMessage}
                renderButton={() => <Button primary content={nextAction.title} fluid />}
                renderConfirmButton={({ closeConfirm }) => (
                  <Button
                    size="tiny"
                    primary
                    content={nextAction.title}
                    onClick={() => {
                      closeConfirm()
                      nextAction.action()
                    }}
                  />
                )}
                renderCancelButton={({ onCancel }) => (
                  <Button size="tiny" color="black" content="Cancel" onClick={onCancel} />
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
