import React from 'react'
import { Icon } from 'semantic-ui-react'

import {
  RESPONSE_ASSESSMENT_CORRECT,
  RESPONSE_ASSESSMENT_CORRECT_NO_POINTS,
  RESPONSE_ASSESSMENT_WRONG,
} from 'module/constants'

export function AssessmentButton({
  assessment,
  neutral: neutralProp = false,
  onClick,
  style = {},
}) {
  let linkProps = {}
  let iconProps = {}
  let styles = {}
  let neutral = neutralProp
  if (onClick) {
    linkProps = {
      link: true,
      onClick,
    }
  }
  if (assessment === RESPONSE_ASSESSMENT_CORRECT) {
    iconProps = {
      name: 'check circle',
      color: 'green',
    }
  } else if (assessment === RESPONSE_ASSESSMENT_CORRECT_NO_POINTS) {
    iconProps = {
      name: 'check circle',
    }
    neutral = true
  } else if (assessment === RESPONSE_ASSESSMENT_WRONG) {
    iconProps = {
      name: 'times circle',
      color: 'red',
    }
  }
  if (neutral) {
    iconProps.color = undefined
    styles.color = 'lightgray'
  }
  styles = {
    ...styles,
    margin: 0,
    ...style,
  }
  return <Icon size="big" {...iconProps} {...linkProps} style={styles} />
}

export function HostAssessmentButtons({
  assessment,
  onAssessCorrect = () => {},
  onAssessWrong = () => {},
}) {
  return (
    <div>
      <AssessmentButton
        assessment={RESPONSE_ASSESSMENT_CORRECT}
        neutral={assessment !== RESPONSE_ASSESSMENT_CORRECT}
        onClick={assessment !== RESPONSE_ASSESSMENT_CORRECT ? onAssessCorrect : undefined}
      />
      <AssessmentButton
        assessment={RESPONSE_ASSESSMENT_WRONG}
        neutral={assessment !== RESPONSE_ASSESSMENT_WRONG}
        onClick={assessment !== RESPONSE_ASSESSMENT_WRONG ? onAssessWrong : undefined}
      />
    </div>
  )
}
