import React from 'react'
import { Image } from 'semantic-ui-react'

import './under-maintenance.css'

import Typography from 'component/Typography'

const imgSrc = require('images/maintenance-monkey.png')

export default function UnderMaintenance() {
  return (
    <div className="under-maintenance">
      <div className="under-maintenance-content">
        <Image src={imgSrc} className="under-maintenance-image" />
        <div className="under-maintenance-message">
          <Typography.h4
            style={{
              textAlign: 'center',
              lineHeight: '1.4',
            }}
          >
            Quiz House Live is not so live at the moment while we undergo some essential maintenance
          </Typography.h4>
        </div>
        <div className="under-maintenance-message">
          <Typography.h3
            style={{
              textAlign: 'center',
              lineHeight: '1.4',
            }}
          >
            We'll be back online soon!
          </Typography.h3>
        </div>
      </div>
    </div>
  )
}
