import firebase from 'firebase'

// a bit hacktastic but we use the below to stop any "undefined" values being passed to firebase
// as it blows up if it finds any!
//
export async function firebaseUpdate({ path, data: dataProp }) {
  const data = JSON.parse(JSON.stringify(dataProp))
  if (path) {
    return await firebase.database().ref(path).update(data)
  } else {
    return await firebase.database().ref().update(data)
  }
}

export async function firebaseSet({ path, data: dataProp }) {
  const data = JSON.parse(JSON.stringify(dataProp))
  return await firebase.database().ref(path).set(data)
}
