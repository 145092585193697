import React from 'react'
import { Icon } from 'semantic-ui-react'

export default function PointsAdjustButtons({ onAdd = () => {}, onSubtract = () => {} } = {}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Icon name="add circle" size="big" link onClick={onAdd} style={{ marginBottom: 4 }} />
      <Icon name="minus circle" size="big" link onClick={onSubtract} />
    </div>
  )
}
