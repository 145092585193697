import React from 'react'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import { getQuestionDisplayProps } from 'feature/QuizManager/selectors'
import HostQuestionDisplay from 'feature/QuizManager/components/QuestionDisplay/HostQuestionDisplay'
import PlayerQuestionDisplay from 'feature/QuizManager/components/QuestionDisplay/PlayerQuestionDisplay'

export function QuestionDisplay({ isHost, ...otherProps }) {
  if (isHost) {
    return <HostQuestionDisplay {...otherProps} />
  }
  return <PlayerQuestionDisplay {...otherProps} />
}

export default ({ onLocalSoundtrackStarted, onLocalSoundtrackStopped }) => {
  const {
    gameData,
    isHost,
    playerId,
    onRevealQuestion,
    onSubmitResponse,
    onPass,
    onGoToNextQuestion,
    onRevealAnswer,
    onEndRound,
    onAssessCorrect,
    onAssessWrong,
  } = useQuizManagerContext()
  const {
    question,
    questionRevealed,
    questionRevealedAt,
    answerRevealed,
    correctAnswerOptionId,
    responseTiming,
    responseCapture,
    allPlayersResponded,
    allResponsesAssessed,
    hasOneCorrectResponse,
    isFinalQuestionInRound,
    currentQuestionId,
    responses,
  } = getQuestionDisplayProps({
    isHost,
    gameData,
  })

  return (
    <QuestionDisplay
      isHost={isHost}
      playerId={playerId}
      question={question}
      currentQuestionId={currentQuestionId}
      correctAnswerOptionId={correctAnswerOptionId}
      questionRevealed={questionRevealed}
      questionRevealedAt={questionRevealedAt}
      answerRevealed={answerRevealed}
      responseTiming={responseTiming}
      responseCapture={responseCapture}
      allPlayersResponded={allPlayersResponded}
      allResponsesAssessed={allResponsesAssessed}
      hasOneCorrectResponse={hasOneCorrectResponse}
      isFinalQuestionInRound={isFinalQuestionInRound}
      responses={responses}
      onRevealQuestion={onRevealQuestion}
      onSubmitResponse={onSubmitResponse}
      onPass={onPass}
      onGoToNextQuestion={onGoToNextQuestion}
      onRevealAnswer={onRevealAnswer}
      onEndRound={onEndRound}
      onAssessCorrect={onAssessCorrect}
      onAssessWrong={onAssessWrong}
      onLocalSoundtrackStarted={onLocalSoundtrackStarted}
      onLocalSoundtrackStopped={onLocalSoundtrackStopped}
    />
  )
}
