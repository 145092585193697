import React from 'react'
import { Form } from 'semantic-ui-react'

import './input.css'

export default function Input(props) {
  const { textArea = false, additionalInfo, autoComplete = 'off', ...otherProps } = props
  const InputComponent = textArea ? Form.TextArea : Form.Input
  let classNames = 'input-container'
  if (additionalInfo) {
    classNames += ' has-additional-info'
  }
  return (
    <>
      <InputComponent autoComplete={autoComplete} {...otherProps} className={classNames} />
      {additionalInfo}
    </>
  )
}
