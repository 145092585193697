import { useAppState } from 'store/App'
import { getSetupData, getAppVersion } from 'store/App/selectors'

import useUserData from 'hooks/useUserData'

const useSetupData = () => {
  const state = useAppState()
  // bit hacktastic, but at the moment setup data listener is combined with the user
  // data stuff
  useUserData()
  return {
    appVersion: getAppVersion(state),
    setupData: getSetupData(state),
  }
}

export default useSetupData
