import { dateToNormalisedFormat } from 'module/dates'
import {
  ROUND_TYPE_ADHOC,
  ROUND_TYPE_Q_AND_A,
  ROUND_TYPE_MULTIPLE_CHOICE,
  ROUND_STATUS_PENDING,
  ROUND_STATUS_IN_PROGRESS,
  ROUND_STATUS_COMPLETED,
  RESPONSE_TIMING_FF_FIRST,
  RESPONSE_CAPTURE_TEXT,
  QUESTION_TYPE_QUESTION_AND_ANSWER,
  RESPONSE_ASSESSMENT_CORRECT,
  RESPONSE_TIMING_EVERYONE,
  QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'module/constants'

import {
  LIVE_GAME_STATUS_PENDING,
  LIVE_GAME_STATUS_IN_PROGRESS,
  LIVE_GAME_STATUS_ENDED,
} from 'api/apiConstants'
import { now } from 'moment'

// export const MOCK_API_DELAY = 1000
export const MOCK_API_DELAY = 1

export const today = new Date()
today.setHours(19)
today.setMinutes(30)
today.setSeconds(0)
today.setMilliseconds(0)

export const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

export const mockUser = {
  id: 'user-1',
  name: 'Bill Oddy',
}

export const events = {
  'event-1': {
    name: 'Some Event A',
    dateTime: dateToNormalisedFormat(tomorrow),
    gameLinkId: `link-8u2dh8238h32h8h22dh2`,
    addedToGamePool: true,
  },
  'event-2': {
    name: 'Some Event C',
    dateTime: dateToNormalisedFormat(today),
    gameLinkId: `link-8u2dh8238h32h8h22dh2`,
    rounds: {
      'round-1': {
        roundType: ROUND_TYPE_Q_AND_A,
        order: 1,
        roundName: 'Round 1',
        questions: {
          'question-1': {
            questionText: 'Who is the best?',
            answerText: 'Russell Winborn',
            questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
            order: 1,
          },
        },
        responseTiming: RESPONSE_TIMING_FF_FIRST,
        responseCapture: RESPONSE_CAPTURE_TEXT,
        pointsPerQuestion: 10,
      },
      'round-2': {
        roundType: ROUND_TYPE_Q_AND_A,
        order: 2,
        roundName: 'Round 2',
        questions: {
          'question-1': {
            questionText: 'Who is NOT the best?',
            answerText: 'Carl Gibson',
            questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
            order: 1,
          },
          'question-2': {
            questionText: 'Who is NOT the best at dancing?',
            answerText: 'David Honeyball',
            questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
            order: 2,
          },
        },
        responseTiming: RESPONSE_TIMING_FF_FIRST,
        responseCapture: RESPONSE_CAPTURE_TEXT,
        pointsPerQuestion: 10,
      },
    },
  },
  'event-3': {
    name: 'Some Event B',
    dateTime: dateToNormalisedFormat(today),
    gameLinkId: `link-8u2dh8238h32h8h22dh2`,
  },
}

export const games = {
  'link-9ow288wedbweyywywd': {
    hostId: 'some-host-id',
    name: 'Some Other Event',
    dateTime: dateToNormalisedFormat(tomorrow),
  },
}

export const gameData = {
  'link-8u2dh8238h32h8h22dh2': {
    public: {
      hostId: mockUser.id,
      name: events['event-1'].name,
      dateTime: events['event-1'].dateTime,
      status: LIVE_GAME_STATUS_IN_PROGRESS,
      // status: LIVE_GAME_STATUS_ENDED,
      participantConnections: {
        'user-1': {
          video: true,
          audio: true,
        },
        'player-1': {
          video: true,
          audio: true,
        },
        'player-2': {
          video: false,
          audio: false,
        },
        'player-3': {
          video: true,
          audio: true,
        },
        'player-4': {
          video: true,
          audio: true,
        },
        'player-5': {
          video: true,
          audio: true,
        },
        'player-6': {
          video: true,
          audio: true,
        },
        'player-7': {
          video: true,
          audio: true,
        },
        'player-8': {
          video: true,
          audio: true,
        },
        'player-9': {
          video: true,
          audio: true,
        },
      },
    },
    host: {
      ...mockUser,
    },
    players: {
      'player-1': {
        name: 'Big Ron',
        responses: {
          'round-2': {
            'question-1': {
              timeStamp: new Date(Date.now() + 6000), // 6 seconds after now
              pass: false,
              answerText: 'An answer',
            },
          },
        },
      },
      'player-2': {
        name: 'Little Dave',
      },
      'player-3': {
        name: 'Massive Margaret',
      },
      'player-4': {
        name: 'Sad Sally',
      },
      'player-5': {
        name: 'Fred',
      },
      'player-6': {
        name: 'Ian',
      },
      'player-7': {
        name: 'Wander',
      },
      'player-8': {
        name: 'Eddy',
      },
      'player-9': {
        name: 'Gavin Mackintosh',
      },
    },
    game: {
      control: {
        started: false,
        activeRoundId: 'round-3',
        ended: false,
      },
      rounds: {
        'round-1': {
          roundType: ROUND_TYPE_Q_AND_A,
          order: 1,
          // status: ROUND_STATUS_PENDING,
          status: ROUND_STATUS_COMPLETED,
          roundName: 'Round 1',
          activeQuestionId: 'question-1',
          questionRevealed: true,
          questions: {
            'question-1': {
              questionText: 'Who is the best?',
              answerText: 'Russell Winborn',
              questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
              order: 1,
            },
          },
          responseTiming: RESPONSE_TIMING_FF_FIRST,
          responseCapture: RESPONSE_CAPTURE_TEXT,
          pointsPerQuestion: 10,
          responseAssessments: {
            // keyed on questonId, then playerId
          },
        },
        'round-2': {
          roundType: ROUND_TYPE_Q_AND_A,
          order: 2,
          // status: ROUND_STATUS_PENDING,
          // status: ROUND_STATUS_IN_PROGRESS,
          status: ROUND_STATUS_COMPLETED,
          roundName: 'Round 2',
          // activeQuestionId: '',
          activeQuestionId: 'question-1',
          // questionRevealed: false,
          questionRevealed: true,
          answerRevealed: false,
          // questionRevealedAt: ,
          questionRevealedAt: new Date(),
          questions: {
            'question-1': {
              questionText: 'Who is NOT the best?',
              answerText: 'Carl Gibson',
              questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
              order: 1,
            },
            'question-2': {
              questionText: 'Who is NOT the best at dancing?',
              answerText: 'David Honeyball',
              questionType: QUESTION_TYPE_QUESTION_AND_ANSWER,
              order: 2,
            },
          },
          responseTiming: RESPONSE_TIMING_FF_FIRST,
          responseCapture: RESPONSE_CAPTURE_TEXT,
          pointsPerQuestion: 10,
          responseAssessments: {
            // keyed on questionId, then playerId
            'question-1': {
              'player-1': RESPONSE_ASSESSMENT_CORRECT,
            },
          },
        },
        'round-3': {
          roundType: ROUND_TYPE_MULTIPLE_CHOICE,
          order: 3,
          // status: ROUND_STATUS_PENDING,
          status: ROUND_STATUS_IN_PROGRESS,
          roundName: 'Round 3',
          activeQuestionId: 'question-1',
          questionRevealed: false,
          answerRevealed: false,
          questionRevealedAt: '',
          questions: {
            'question-1': {
              questionText: 'Who is the best?',
              questionType: QUESTION_TYPE_MULTIPLE_CHOICE,
              answerOptions: {
                'option-abc': {
                  answerText: 'Bill Oddy',
                },
                'option-def': {
                  answerText: 'Rusta McDude',
                  isCorrect: true,
                },
                'option-ghi': {
                  answerText: 'Danny Mills',
                },
                'option-jkl': {
                  answerText: 'Lara Croft',
                },
              },
              order: 1,
            },
          },
          responseTiming: RESPONSE_TIMING_EVERYONE,
          responseCapture: RESPONSE_CAPTURE_TEXT,
          pointsPerQuestion: 10,
          responseAssessments: {},
        },
      },
      playerData: {
        'player-1': {
          playerOrder: 1,
          points: 0,
        },
        'player-2': {
          playerOrder: 0,
          points: 0,
        },
        'player-3': {
          playerOrder: 2,
          points: 0,
        },
        'player-4': {
          playerOrder: 3,
          points: 0,
        },
        'player-5': {
          playerOrder: 4,
          points: 0,
        },
        'player-6': {
          playerOrder: 5,
          points: 0,
        },
        'player-7': {
          playerOrder: 6,
          points: 0,
        },
        'player-8': {
          playerOrder: 7,
          points: 0,
        },
        'player-9': {
          playerOrder: 8,
          points: 12,
        },
      },
    },
  },
  'link-9ow288wedbweyywywd': {
    public: {
      ...games['link-9ow288wedbweyywywd'].public,
      status: LIVE_GAME_STATUS_PENDING,
      participantConnections: {
        'user-1': {
          video: true,
          audio: false,
        },
        'user-2': {
          video: true,
          audio: true,
        },
        'player-1': {
          video: true,
          audio: true,
        },
      },
      players: {
        'player-1': {
          name: 'Big Ron',
        },
        [mockUser.id]: {
          ...mockUser,
        },
      },
    },
    host: {
      id: 'user-2',
      name: 'Gavin',
    },
    game: {
      playerData: {
        'player-1': {
          playerOrder: 1,
          points: 12,
          video: false,
          audio: false,
        },
        [mockUser.id]: {
          playerOrder: 0,
          points: 16,
          video: true,
          audio: true,
        },
      },
    },
  },
}

gameData['link-uweydh8we636363'] = JSON.parse(JSON.stringify(gameData['link-8u2dh8238h32h8h22dh2']))
gameData['link-uweydh8we636363'].game.rounds['round-2'].status = ROUND_STATUS_IN_PROGRESS

export const userData = {
  meta: {
    isHost: true,
  },
  events: {
    ...events,
  },
  games,
}
