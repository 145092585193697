import React, { useState, useCallback, useEffect } from 'react'

import Input from 'component/Input'

// TODO: check if format of provided time string is valid
function getTimePart({ part, timeString = '' }) {
  const parts = timeString.split(':')
  return part === 'hour' ? parts[0] : parts[1]
}

function zeroMeUp(numString = '') {
  if (numString.length === 1) {
    return `0${numString}`
  }
  return numString
}

export default function TimePicker({
  initialTimeString = '',
  onTimeChanged = () => {},
  disabled = false,
}) {
  const [timeString, setTimeString] = useState(initialTimeString)
  const [hour, setHour] = useState(getTimePart({ part: 'hour', timeString: initialTimeString }))
  const [minute, setMinute] = useState(
    getTimePart({ part: 'minute', timeString: initialTimeString }),
  )

  const changeHour = useCallback(
    (event, data) => {
      if (Number(data.value) < 0 || Number(data.value) > 23) {
        // do nothing as not valid
      } else {
        setHour(data.value)
        setTimeString(`${zeroMeUp(data.value)}:${zeroMeUp(minute)}`)
      }
    },
    [minute, setHour, setTimeString],
  )
  const changeMinute = useCallback(
    (event, data) => {
      if (Number(data.value) < 0 || Number(data.value) > 59) {
        // do nothing as not valid
      } else {
        setMinute(data.value)
        setTimeString(`${zeroMeUp(hour)}:${zeroMeUp(data.value)}`)
      }
    },
    [hour, setMinute, setTimeString],
  )
  useEffect(() => {
    onTimeChanged(timeString)
  }, [timeString, onTimeChanged])

  const blurHour = useCallback(() => {
    setHour(zeroMeUp(hour))
  }, [hour, setHour])

  const blurMinute = useCallback(() => {
    setMinute(zeroMeUp(minute))
  }, [minute, setMinute])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Input
        placeholder="HH"
        type="number"
        onChange={changeHour}
        onBlur={blurHour}
        name="hour"
        value={hour || ''}
        style={{ width: '90px' }}
        disabled={disabled}
      />
      <Input
        placeholder="MM"
        type="number"
        onChange={changeMinute}
        onBlur={blurMinute}
        name="minute"
        value={minute || ''}
        style={{ width: '90px' }}
        disabled={disabled}
      />
    </div>
  )
}
