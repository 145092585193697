import React, { useCallback } from 'react'
import { Icon } from 'semantic-ui-react'

import { useLiveGameData } from 'store/LiveGame'
import useAuth from 'hooks/useAuth'
import useGameHostActions from 'feature/Game/GameLive/GamePlay/useGameHostActions'

export default function LeaderBoardBonusPointsAdjustment({ playerId }) {
  const { user = {} } = useAuth()
  const { gameData } = useLiveGameData()
  const { setPlayerPoints } = useGameHostActions({ gameData })
  const { game = {}, host = {} } = gameData
  const { playerData = {} } = game
  const isHost = user.id === host.id

  const adjustPlayerPoints = useCallback(
    ({ playerId, direction }) => {
      if (playerData[playerId]) {
        const currentPoints = playerData[playerId].points || 0
        const points = direction === 'add' ? currentPoints + 1 : currentPoints - 1
        setPlayerPoints({
          playerId,
          points,
        })
      }
    },
    [playerData, setPlayerPoints],
  )

  const onAddBonusPoints = useCallback(() => {
    adjustPlayerPoints({ playerId, direction: 'add' })
  }, [playerId, adjustPlayerPoints])
  const onSubtractBonusPoints = useCallback(() => {
    adjustPlayerPoints({ playerId, direction: 'subtract' })
  }, [playerId, adjustPlayerPoints])

  if (!isHost) {
    return null
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 52,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 4,
      }}
    >
      <div>
        <Icon
          name="add circle"
          link
          onClick={onAddBonusPoints}
          style={{ margin: 0, fontSize: 18 }}
        />
        <Icon
          name="minus circle"
          link
          onClick={onSubtractBonusPoints}
          style={{ margin: 0, fontSize: 18 }}
        />
      </div>
    </div>
  )
}
