import firebase from 'firebase'

import { firebaseUpdate, firebaseSet } from 'api/firebaseUtils'

import { getUuid } from 'module/utils'
import { getGameLinkId } from 'module/environ'

export async function addUpdateEvent({
  id = getUuid({ prefix: 'event' }),
  gameLinkId = getGameLinkId(),
  name,
  dateTime,
  useVideo,
  videoMode,
  connectionType,
  supportingVideoConference,
  supportingVideoConferenceInfo,
  maxDurationMinutes,
  maxPlayers,
  onSuccess,
  onError,
}) {
  try {
    const userId = firebase.auth().currentUser.uid
    const path = `/users/${userId}/events/${id}/details`
    await firebaseUpdate({
      path,
      data: {
        id,
        name,
        useVideo,
        videoMode,
        connectionType,
        supportingVideoConference,
        supportingVideoConferenceInfo,
        maxDurationMinutes,
        maxPlayers,
        dateTime,
        gameLinkId,
      },
    })

    if (onSuccess) {
      onSuccess({
        eventId: id,
        gameLinkId,
      })
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export async function setEventRounds({ id, rounds, onSuccess, onError }) {
  try {
    const userId = firebase.auth().currentUser.uid
    const path = `/users/${userId}/events/${id}/rounds`
    await firebaseSet({
      path,
      data: rounds,
    })
    if (onSuccess) {
      onSuccess({
        eventId: id,
      })
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export async function deleteEvent({ id, onSuccess, onError }) {
  try {
    const userId = firebase.auth().currentUser.uid
    const path = `/users/${userId}/events/${id}`
    await firebase.database().ref(path).remove()
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}
