export const ROUND_TYPE_ADHOC = 'ROUND_TYPE_ADHOC'
export const ROUND_TYPE_Q_AND_A = 'ROUND_TYPE_Q_AND_A'
export const ROUND_TYPE_OPEN_QUESTION = 'ROUND_TYPE_OPEN_QUESTION'
export const ROUND_TYPE_MULTIPLE_CHOICE = 'ROUND_TYPE_MULTIPLE_CHOICE'
export const ROUND_TYPE_FILL_THE_BLANK = 'ROUND_TYPE_FILL_THE_BLANK'

export const ROUND_STATUS_PENDING = 'ROUND_STATUS_PENDING'
export const ROUND_STATUS_IN_PROGRESS = 'ROUND_STATUS_IN_PROGRESS'
export const ROUND_STATUS_PAUSED = 'ROUND_STATUS_PAUSED'
export const ROUND_STATUS_COMPLETED = 'ROUND_STATUS_COMPLETED'

export const QUESTION_TYPE_OPEN_QUESTION = 'QUESTION_TYPE_OPEN_QUESTION'
export const QUESTION_TYPE_QUESTION_AND_ANSWER = 'QUESTION_TYPE_QUESTION_AND_ANSWER'
export const QUESTION_TYPE_MULTIPLE_CHOICE = 'QUESTION_TYPE_MULTIPLE_CHOICE'
export const QUESTION_TYPE_FILL_THE_BLANK = 'QUESTION_TYPE_FILL_THE_BLANK'

export const RESPONSE_TIMING_FF_FIRST = 'RESPONSE_TIMING_FF_FIRST'
export const RESPONSE_TIMING_EVERYONE = 'RESPONSE_TIMING_EVERYONE'

export const RESPONSE_CAPTURE_TEXT = 'RESPONSE_CAPTURE_TEXT'
export const RESPONSE_CAPTURE_AV = 'RESPONSE_CAPTURE_AV'

export const RESPONSE_ASSESSMENT_CORRECT = 'RESPONSE_ASSESSMENT_CORRECT'
export const RESPONSE_ASSESSMENT_CORRECT_NO_POINTS = 'RESPONSE_ASSESSMENT_CORRECT_NO_POINTS'
export const RESPONSE_ASSESSMENT_WRONG = 'RESPONSE_ASSESSMENT_WRONG'

export const ROUND_DEFAULT_DATA = {
  pointsPerQuestion: 5,
  responseCapture: RESPONSE_CAPTURE_TEXT,
  status: ROUND_STATUS_PENDING,
  responseTiming: RESPONSE_TIMING_EVERYONE,
  questions: {},
}

export const GAME_PERIOD_WITHIN = 'GAME_PERIOD_WITHIN'
export const GAME_PERIOD_PRIOR = 'GAME_PERIOD_PRIOR'
export const GAME_PERIOD_POST = 'GAME_PERIOD_POST'

export const EVENT_LIMITS_MAX_PLAYERS = 9
export const EVENT_LIMITS_MAX_DURATION_MINUTES = 90
export const EVENT_DEFAULTS_MAX_PLAYERS = 9
export const EVENT_DEFAULTS_MAX_DURATION_MINUTES = 90

export const EVENT_CONNECTION_TYPE_OPTION_PEER_TO_PEER = 'peer-to-peer'
export const EVENT_CONNECTION_TYPE_OPTION_GROUP_SMALL = 'group-small'
export const EVENT_CONNECTION_TYPE_OPTION_GROUP = 'group'

export const EVENT_USE_VIDEO_OPTION_USE_VIDEO = 'use-video'
export const EVENT_USE_VIDEO_OPTION_NO_VIDEO = 'no-video'

export const EVENT_VIDEO_MODE_OPTION_TWILIO = 'TWILIO'
export const EVENT_VIDEO_MODE_OPTION_JITSI = 'JITSI'

export const EVENT_CONNECTION_TYPE_OPTIONS = [
  {
    key: EVENT_CONNECTION_TYPE_OPTION_PEER_TO_PEER,
    value: EVENT_CONNECTION_TYPE_OPTION_PEER_TO_PEER,
    text: 'Peer to Peer',
  },
  {
    key: EVENT_CONNECTION_TYPE_OPTION_GROUP_SMALL,
    value: EVENT_CONNECTION_TYPE_OPTION_GROUP_SMALL,
    text: 'Small Group',
  },
  {
    key: EVENT_CONNECTION_TYPE_OPTION_GROUP,
    value: EVENT_CONNECTION_TYPE_OPTION_GROUP,
    text: 'Group',
  },
]

export const EVENT_USE_VIDEO_OPTIONS = [
  {
    key: EVENT_USE_VIDEO_OPTION_USE_VIDEO,
    value: EVENT_USE_VIDEO_OPTION_USE_VIDEO,
    text: 'Yes',
  },
  {
    key: EVENT_USE_VIDEO_OPTION_NO_VIDEO,
    value: EVENT_USE_VIDEO_OPTION_NO_VIDEO,
    text: 'No',
  },
]

export const EVENT_VIDEO_MODE_OPTIONS = [
  {
    key: EVENT_VIDEO_MODE_OPTION_TWILIO,
    value: EVENT_VIDEO_MODE_OPTION_TWILIO,
    text: 'Twilio',
  },
  // { key: EVENT_VIDEO_MODE_OPTION_JITSI, value: EVENT_VIDEO_MODE_OPTION_JITSI, text: 'Jitsi' },
]

export const QUIZ_FEATURE_AUDIO_PLAY = 'QUIZ_FEATURE_AUDIO_PLAY'
export const QUIZ_FEATURE_IN_APP_VIDEO = 'QUIZ_FEATURE_IN_APP_VIDEO'
export const QUIZ_FEATURE_THIRD_PARTY_VIDEO = 'QUIZ_FEATURE_THIRD_PARTY_VIDEO'
export const QUIZ_FEATURES_ACTIVE = [
  QUIZ_FEATURE_AUDIO_PLAY,
  QUIZ_FEATURE_IN_APP_VIDEO,
  // QUIZ_FEATURE_THIRD_PARTY_VIDEO,
]
