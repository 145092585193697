import { useHistory } from 'react-router-dom'

import { getEnvironOriginPath } from 'module/environ'
import copyToClipboard from 'module/copyToClipboard'

export const routePathMap = {
  gamePlay: '/play/:gameLinkId/live',
}

const useNav = () => {
  const history = useHistory()
  const navHome = () => {
    history.push('/')
  }
  const navToAddEvent = () => {
    history.push('/event/add')
  }
  const navToEditEvent = ({ eventId } = {}) => {
    if (eventId) {
      history.push(`/event/edit/${eventId}`)
    } else {
      console.warn('missing "eventId" param for navToEditEvent')
    }
  }
  const navToDashboard = () => {
    history.push(`/dashboard`)
  }
  const navToGame = ({ gameLinkId }) => {
    if (gameLinkId) {
      history.push(`/play/${gameLinkId}`)
    } else {
      console.warn('missing "gameLinkId" param for navToGame')
    }
  }
  const navToLiveGame = ({ gameLinkId }) => {
    if (gameLinkId) {
      history.push(`/play/${gameLinkId}/live`)
    } else {
      console.warn('missing "gameLinkId" param for navToLiveGame')
    }
  }

  const copyGameLink = ({ gameLinkId }) => {
    if (gameLinkId) {
      copyToClipboard(`${getEnvironOriginPath()}/play/${gameLinkId}`)
    } else {
      console.warn('missing "gameLinkId" param for navToLiveGame')
    }
  }

  return {
    navHome,
    navToAddEvent,
    navToEditEvent,
    navToDashboard,
    navToLiveGame,
    navToGame,
    copyGameLink,
  }
}

export default useNav
