import { useState, useCallback } from 'react'

import { getBumpRoundOrderUpdates, getNextRoundOrderNum } from 'feature/QuizManager/selectors'
import { ROUND_STATUS_PENDING } from 'module/constants'
import { getUuid } from 'module/utils'

import useHostEvents from 'hooks/useHostEvents'
import useIsMounted from 'hooks/useIsMounted'

export default function useEventQuizManager({ eventId, initialData = {}, readOnly } = {}) {
  const isMounted = useIsMounted()
  const { setEventRounds, updateEventStatus = {} } = useHostEvents({ eventId })
  const [stage, setStage] = useState({ id: 'rounds', title: 'Rounds' })
  const [rounds, setRounds] = useState(initialData)

  const onFinishEditingRounds = useCallback(() => {
    setStage({
      id: 'rounds',
      title: 'Rounds',
    })
  }, [])

  const onGoToAddRound = useCallback(() => {
    setStage({
      id: 'add-edit-round',
      title: 'Add Round',
      params: {
        mode: 'add',
      },
    })
  }, [])

  const onGoToEditRound = useCallback(({ roundId }) => {
    setStage({
      id: 'add-edit-round',
      title: 'Round',
      params: {
        mode: 'edit',
        roundId,
      },
    })
  }, [])

  const onUpdateSuccess = useCallback(
    ({ rounds }) => {
      if (isMounted.current) {
        setRounds(rounds)
      }
    },
    [isMounted, setRounds],
  )

  const onSaveRound = useCallback(
    async ({ round: roundProp, roundId: roundIdProp, onSuccess }) => {
      const roundId = roundIdProp || getUuid({ prefix: 'round' })
      const existingRound = rounds[roundId] || {}
      const nextRoundOrderNum = getNextRoundOrderNum({ gameData: { game: { rounds } } })
      const round = {
        ...existingRound,
        ...roundProp,
      }
      round.status = ROUND_STATUS_PENDING
      round.order = typeof round.order !== 'undefined' ? round.order : nextRoundOrderNum
      const updatedRounds = {
        ...rounds,
        [roundId]: round,
      }
      setEventRounds({
        id: eventId,
        rounds: updatedRounds,
        onSuccess: () => {
          if (onSuccess) {
            onSuccess({ roundId })
          }
          onUpdateSuccess({
            rounds: updatedRounds,
          })
        },
      })
    },
    [eventId, rounds, setEventRounds, onUpdateSuccess],
  )

  const onRemoveRound = useCallback(
    async ({ roundId, onSuccess }) => {
      const { [roundId]: roundToDelete, ...remainingRounds } = rounds
      setEventRounds({
        id: eventId,
        rounds: remainingRounds,
        onSuccess: () =>
          onUpdateSuccess({
            rounds: remainingRounds,
          }),
      })
    },
    [eventId, rounds, setEventRounds, onUpdateSuccess],
  )

  const onBumpRoundOrder = useCallback(
    ({ roundId, direction }) => {
      const gameData = {
        game: { rounds },
      }
      const updatedRounds = getBumpRoundOrderUpdates({
        gameData,
        roundId,
        direction,
      })
      const nextRoundsState = {
        ...rounds,
        ...updatedRounds,
      }
      if (Object.keys(updatedRounds).length) {
        setEventRounds({
          id: eventId,
          rounds: nextRoundsState,
          onSuccess: () =>
            onUpdateSuccess({
              rounds: nextRoundsState,
            }),
        })
      }
    },
    [eventId, rounds, setEventRounds, onUpdateSuccess],
  )

  return {
    gameData: {
      game: { rounds },
    },
    isHost: true,
    readOnly,
    isSaving: updateEventStatus.updatingEvent,
    stage,
    onFinishEditingRounds,
    onGoToAddRound,
    onGoToEditRound,
    onSaveRound,
    onRemoveRound,
    onBumpRoundOrder,
  }
}
