import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { isDevMode } from 'module/utils'
import DebugLiveGame from 'feature/Debug/DebugLiveGame'
import { LiveGameProvider } from 'store/LiveGame'
import ContentPanel from 'component/ContentPanel'
import FullScreenLoader from 'component/FullScreenLoader'

import useSetupData from 'hooks/useSetupData'
import useNav from 'hooks/useNav'
import useAuth from 'hooks/useAuth'

import storeApi from 'store/storeApi'
import useIsMounted from 'hooks/useIsMounted'

import {
  LIVE_GAME_STATUS_PENDING,
  LIVE_GAME_STATUS_PAUSED,
  LIVE_GAME_STATUS_IN_PROGRESS,
  LIVE_GAME_STATUS_ENDED,
} from 'api/apiConstants'
import useLiveGame from '../useLiveGame'
import usePublicGameData from '../usePublicGameData'
import GamePlay from './GamePlay'

function ConnectedGameLive() {
  const { gameLinkId } = useParams()
  const { navToGame } = useNav()
  const { setupData } = useSetupData()
  const liveGame = useLiveGame({
    gameLinkId,
  })
  const { isConnecting, gameData = {} } = liveGame
  const { public: publicData = {} } = gameData
  const { status, useVideo } = publicData

  const onInactivityDetected = useCallback(() => {
    // navigate user back to the game waiting room if they've not been up to much
    // to save us some video connection time
    console.log('*** inactive user detected, booting you back to waiting room for this quiz ***')
    navToGame({ gameLinkId })
  }, [gameLinkId, navToGame])

  // hacktastcially check for minVersion, as means the app setup data has loaded
  // TODO: make me bette
  if (isConnecting || !setupData || !setupData.minVersion) {
    return <FullScreenLoader />
  }

  if (status === LIVE_GAME_STATUS_ENDED) {
    return <ContentPanel>Game has ended</ContentPanel>
  } else if (status === LIVE_GAME_STATUS_IN_PROGRESS) {
    const basicGamePlayMode = useVideo === 'no-video'
    return (
      <LiveGameProvider value={liveGame}>
        {isDevMode() && <DebugLiveGame />}
        <GamePlay basicMode={basicGamePlayMode} onInactivityDetected={onInactivityDetected} />
      </LiveGameProvider>
    )
  } else if (status === LIVE_GAME_STATUS_PENDING || status === LIVE_GAME_STATUS_PAUSED) {
    return <ContentPanel>WAITING FOR YOUR HOST TO JOIN</ContentPanel>
  } else {
    return <ContentPanel>Game not found - sorry!</ContentPanel>
  }
}

export default function GameLive() {
  const { userId } = useAuth()
  const { gameLinkId } = useParams()
  const { publicGameData = {} } = usePublicGameData({
    gameLinkId,
  })
  const isMounted = useIsMounted()
  const { navToGame } = useNav()
  const [checkingAccessStatus, setCheckingAccessStatus] = useState(true)
  const [hasAccess, setHasAccess] = useState(false)

  const { details = {} } = publicGameData
  const { status, hostId, name } = details

  const isHost = userId === hostId
  const gamePaused = status === LIVE_GAME_STATUS_PAUSED

  const onSuccess = useCallback(
    ({ data = {} }) => {
      const { isParticipant } = data
      if (isMounted.current) {
        if (isParticipant) {
          setCheckingAccessStatus(false)
          setHasAccess(true)
        } else {
          // user does noy have access so nav them to game waiting room
          // TODO: rename the below nav function!
          navToGame({ gameLinkId })
        }
      }
    },
    [isMounted, setCheckingAccessStatus, setHasAccess, navToGame, gameLinkId],
  )

  const onError = useCallback(() => {
    if (isMounted.current) {
      navToGame({ gameLinkId })
    }
  }, [isMounted, gameLinkId, navToGame])

  useEffect(() => {
    storeApi({
      method: 'getIsGameParticipant',
      params: {
        gameLinkId,
        onSuccess,
        onError,
      },
    })
  }, [gameLinkId, onSuccess, onError])

  // send host back to the waiting room if the game is paused (or somehow reset to pending state)
  // so that they can restart the quiz (and video sessions etc)

  useEffect(() => {
    if (isHost && (status === LIVE_GAME_STATUS_PENDING || status === LIVE_GAME_STATUS_PAUSED)) {
      navToGame({ gameLinkId })
    }
  }, [isHost, status, gameLinkId, navToGame])

  if (checkingAccessStatus || !hasAccess) {
    return <FullScreenLoader />
  }

  if (gamePaused) {
    return (
      <ContentPanel>
        <h1>{name}</h1>
        <div style={{ paddingTop: '1em' }}>Game paused - waiting for host to resume game</div>
      </ContentPanel>
    )
  }

  return <ConnectedGameLive />
}
