import React, { useCallback } from 'react'
import { Form, Button } from 'semantic-ui-react'

import Input from 'component/Input'
import ImageInput from 'component/ImageInput'
import AudioInput from 'component/AudioInput'
import ConfirmButton from 'component/ConfirmButton'
import LabelInfoPopup from 'component/LabelInfoPopup'

import { HiddenSubmitButton } from 'hooks/useForm'

import useAddEditQuestion from 'feature/QuizManager/components/AddEditQuestion/useAddEditQuestion'
import QuestionAnswerOptions from 'feature/QuizManager/components/AddEditQuestion/QuestionAnswerOptions'

export function AddEditQuestion({
  readOnly,
  mode,
  fieldsToDisplay,
  fields,
  fieldValidity,
  setFieldValidity,
  handleInputChange,
  onSetAnswerOptions,
  onSave,
  onCancel,
  onDelete,
}) {
  const saveButtonText = readOnly ? 'OK' : 'Save'
  const saveButtonProps = readOnly
    ? {}
    : {
        color: 'green',
      }

  const setQuestionImageFieldValidity = useCallback(
    ({ isValid }) => {
      setFieldValidity((fields) => {
        return {
          ...fields,
          questionImage: {
            isValid,
          },
        }
      })
    },
    [setFieldValidity],
  )

  const setQuestionAudioFieldValidity = useCallback(
    ({ isValid }) => {
      setFieldValidity((fields) => {
        return {
          ...fields,
          questionAudio: {
            isValid,
          },
        }
      })
    },
    [setFieldValidity],
  )

  const renderQuestionImageHelpContent = useCallback(() => {
    return (
      <div>
        <p>Add the source URL of an image to include it in the question</p>
        <ul style={{ paddingLeft: '1.5em' }}>
          <li>
            Find the image you want (e.g on{' '}
            <a href="https://www.google.com/imghp" target="_blank">
              google images
            </a>
            )
          </li>
          <li>Right click the image and select 'Copy Image Address'</li>
          <li>Paste the URL here and if it's valid a preview of the image will appear below</li>
        </ul>
      </div>
    )
  }, [])

  const renderQuestionAudioHelpContent = useCallback(() => {
    return (
      <div>
        <p>Add the share URL of any SoundCloud track to use in a question</p>
        <ul style={{ paddingLeft: '1.5em' }}>
          <li>
            Find the track you want to use on{' '}
            <a href="https://soundcloud.com/search?q=" target="_blank">
              SoundCloud
            </a>
          </li>
          <li>Select the 'share' icon for that track</li>
          <li>Copy and paste the share URL here</li>
          <li>e.g. https://soundcloud.com/radiohead/creep</li>
        </ul>
        <p>
          Note: the track is only played on the hosts device and so relies on the sound of the hosts
          computer being shared on a video conference in order for the players to hear it
        </p>
        <p>Only the first 10 seconds of the track will be played</p>
      </div>
    )
  }, [])

  return (
    <div>
      <Form>
        <HiddenSubmitButton />
        {fieldsToDisplay.indexOf('questionText') > -1 && (
          <Form.Field>
            <label>Question</label>
            <Input
              autoFocus={mode === 'add'}
              placeholder="Question"
              required
              onChange={handleInputChange}
              value={fields.questionText || ''}
              name="questionText"
              type="text"
              disabled={readOnly}
            />
          </Form.Field>
        )}
        <Form.Group inline>
          {fieldsToDisplay.indexOf('hasQuestionImage') > -1 && (
            <Form.Checkbox
              label="Include Image"
              onChange={handleInputChange}
              name="hasQuestionImage"
              checked={!!fields.hasQuestionImage}
              disabled={readOnly}
            />
          )}
          {fieldsToDisplay.indexOf('hasQuestionAudio') > -1 && (
            <Form.Checkbox
              label="Include Audio"
              onChange={handleInputChange}
              name="hasQuestionAudio"
              checked={!!fields.hasQuestionAudio}
              disabled={readOnly}
            />
          )}
        </Form.Group>
        {fieldsToDisplay.indexOf('questionImage') > -1 && (
          <Form.Field>
            <label>
              Question Image <LabelInfoPopup renderContent={renderQuestionImageHelpContent} />
            </label>
            <ImageInput
              placeholder="Image"
              onChange={handleInputChange}
              value={fields.questionImage || ''}
              name="questionImage"
              type="text"
              disabled={readOnly}
              setFieldValidity={setQuestionImageFieldValidity}
            />
          </Form.Field>
        )}
        {fieldsToDisplay.indexOf('questionAudio') > -1 && (
          <Form.Field>
            <label>
              Question Audio <LabelInfoPopup renderContent={renderQuestionAudioHelpContent} />
            </label>
            <AudioInput
              placeholder="Audio"
              onChange={handleInputChange}
              value={fields.questionAudio || ''}
              name="questionAudio"
              type="text"
              disabled={readOnly}
              error={fieldValidity.questionAudio === false}
              setFieldValidity={setQuestionAudioFieldValidity}
            />
          </Form.Field>
        )}
        {fieldsToDisplay.indexOf('answerText') > -1 && (
          <Form.Field>
            <label>Answer</label>
            <Input
              placeholder="Answer"
              required
              onChange={handleInputChange}
              value={fields.answerText || ''}
              name="answerText"
              type="text"
              disabled={readOnly}
            />
          </Form.Field>
        )}
        {fieldsToDisplay.indexOf('answerOptions') > -1 && (
          <Form.Field>
            <label>Options</label>
            <QuestionAnswerOptions
              readOnly={readOnly}
              answerOptions={fields.answerOptions}
              onSetAnswerOptions={onSetAnswerOptions}
            />
          </Form.Field>
        )}
        <Form.Group inline>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex' }}>
              {fieldsToDisplay.indexOf('cancelButton') > -1 && (
                <Form.Field>
                  <Button color="grey" onClick={onCancel}>
                    {readOnly ? 'OK' : 'Cancel'}
                  </Button>
                </Form.Field>
              )}
              {fieldsToDisplay.indexOf('saveButton') > -1 && !readOnly && (
                <Form.Field>
                  <Button {...saveButtonProps} onClick={onSave}>
                    {saveButtonText}
                  </Button>
                </Form.Field>
              )}
            </div>
            {fieldsToDisplay.indexOf('deleteButton') > -1 && !readOnly && (
              <ConfirmButton
                renderButton={() => <Button color="red">Remove</Button>}
                renderConfirmButton={() => (
                  <Button size="tiny" color="red" content="Delete" onClick={onDelete} />
                )}
                renderCancelButton={({ onCancel }) => (
                  <Button size="tiny" color="black" content="Cancel" onClick={onCancel} />
                )}
              />
            )}
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

export default ({ readOnly, mode, questionType, question, onSubmit, onCancel, onDelete }) => {
  const useAddEditQuestionProps = useAddEditQuestion({
    mode,
    questionType,
    question,
    onSubmit,
    onCancel,
    onDelete,
  })
  return <AddEditQuestion mode={mode} readOnly={readOnly} {...useAddEditQuestionProps} />
}
