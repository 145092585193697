import React, { useState, useCallback } from 'react'
import { Tab } from 'semantic-ui-react'

import ContentPanel from 'component/ContentPanel'

import EventDetails from './EventDetails'
import GameLinks from './GameLinks'
import EventQuizManager from './EventRounds/EventQuizManager'

import useUserData from 'hooks/useUserData'
import {
  getCanEditEvent,
  getUserHasHostVideoAccess,
  getUserHasSuperPowers,
} from 'store/App/selectors'

export default function AddEditEvent({ mode: initialMode = 'add', initialData = {} }) {
  const state = useUserData()
  const [mode, setMode] = useState(initialMode)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [eventId, setEventId] = useState(initialData.details ? initialData.details.id : '')
  const [gameLinkId, setGameLinkId] = useState(
    initialData.details ? initialData.details.gameLinkId : '',
  )
  const canEditEvent = initialMode === 'add' ? true : getCanEditEvent({ state, eventId })
  const userHasHostVideoAccess = getUserHasHostVideoAccess(state)
  const userHasSuperPowers = getUserHasSuperPowers(state)
  const title = initialMode === 'edit' ? (canEditEvent ? 'Edit Quiz' : 'Quiz') : 'New Quiz'

  const onSetActiveTabIndex = useCallback(
    (event, { activeIndex }) => {
      setActiveTabIndex(activeIndex)
    },
    [setActiveTabIndex],
  )

  const onAddEventSuccess = useCallback(
    ({ eventId, gameLinkId }) => {
      if (mode === 'add') {
        setEventId(eventId)
        setGameLinkId(gameLinkId)
        setMode('edit')
        setActiveTabIndex(1)
      }
    },
    [mode, setActiveTabIndex, setEventId, setGameLinkId, setMode],
  )

  const panes = [
    {
      menuItem: 'Details',
      pane: (
        <Tab.Pane key="details-tab">
          <EventDetails
            mode={mode}
            eventId={eventId}
            gameLinkId={gameLinkId}
            initialData={initialData.details}
            onAddEventSuccess={onAddEventSuccess}
            readOnly={!canEditEvent}
            canCreateVideoEvents={userHasHostVideoAccess}
            canChooseVideoEventType={userHasSuperPowers}
          />
        </Tab.Pane>
      ),
    },
  ]
  if (mode === 'edit') {
    panes.push({
      menuItem: 'Rounds',
      pane: (
        <Tab.Pane key="rounds-tab">
          <EventQuizManager
            eventId={eventId}
            initialData={initialData.rounds}
            readOnly={!canEditEvent}
          />
        </Tab.Pane>
      ),
    })
  }

  return (
    <ContentPanel style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>{title}</h1>
            <div>
              <GameLinks eventId={eventId} />
            </div>
          </div>
          <Tab
            activeIndex={activeTabIndex}
            panes={panes}
            onTabChange={onSetActiveTabIndex}
            renderActiveOnly={false}
          />
        </div>
        <div>
          {!canEditEvent && <div>Event is in progress or in the past so cannot be edited</div>}
        </div>
      </div>
    </ContentPanel>
  )
}
