import React from 'react'
import { Modal } from 'semantic-ui-react'

import useSetupData from 'hooks/useSetupData'
import useAuth from 'hooks/useAuth'

import { getSemVerStatus } from 'module/semVer'

import UnderMaintenance from 'feature/UnderMaintenance'
import UpgradeTime from 'feature/UpgradeTime'

export default function SetupResponder() {
  const { setupData = {}, appVersion = '0.0.0' } = useSetupData()
  const { userIsAuthorised } = useAuth()
  const { minVersion, underMaintenance } = setupData

  if (userIsAuthorised && underMaintenance) {
    return (
      <Modal open closeOnDimmerClick={false} closeOnEscape={false}>
        <UnderMaintenance />
      </Modal>
    )
  }

  if (userIsAuthorised && minVersion) {
    const semVerStatus = getSemVerStatus({
      version: appVersion,
      minVersion: setupData.minVersion,
    })

    if (semVerStatus === 'behind') {
      return (
        <Modal open closeOnDimmerClick={false} closeOnEscape={false}>
          <UpgradeTime />
        </Modal>
      )
    }
  }
  return null
}
