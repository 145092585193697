import { useEffect } from 'react'

import { colors } from 'module/theme'

const bgImgSrc = require('images/colorgrid.svg')

export default function MegaBackground() {
  useEffect(() => {
    const body = document.querySelector('body')
    body.style.backgroundImage = `url(${bgImgSrc})`
    body.style.backgroundSize = 'cover'
    body.style.backgroundColor = colors.primary
    return () => {
      const body = document.querySelector('body')
      body.style.backgroundImage = ''
      body.style.backgroundSize = ''
      body.style.backgroundColor = ''
    }
  }, [])
  return null
}
