import React from 'react'

// import { getIsSupportedBrowser } from 'module/browserSupport'
import { HEADER_NAV_HEIGHT } from 'feature/HeaderNav'

import './content-panel.css'

// const supportedBrowser = getIsSupportedBrowser()

const CONTENT_PADDING = 20
const CONTENT_PADDING_X2 = CONTENT_PADDING * 2

export default function ContentPanel({
  children,
  containerClassName = '',
  panelClassName = '',
  style = {},
  fullScreenHeight = false,
  subPanelContent,
}) {
  const minHeight = fullScreenHeight
    ? '100vh'
    : `calc(100vh - ${HEADER_NAV_HEIGHT + CONTENT_PADDING_X2}px)`
  return (
    <div
      className={`content-panel-container ${containerClassName}`}
      style={{
        minHeight,
        paddingTop: CONTENT_PADDING,
        paddingBottom: CONTENT_PADDING,
      }}
    >
      <div className={`content-panel-container-panel ${panelClassName}`} style={style}>
        {children}
      </div>
      {!!subPanelContent && <div className="content-panel-sub-content">{subPanelContent}</div>}
    </div>
  )
}
