import { useState, useEffect } from 'react'
import _set from 'lodash/set'
import _get from 'lodash/get'

import {
  getCurrentRound,
  getRound,
  getNextRoundOrderNum,
  getGameState,
  getBumpRoundOrderUpdates,
  getOrderedRoundQuestions,
  getHasQuizEnded,
} from 'feature/QuizManager/selectors'
import {
  ROUND_STATUS_PENDING,
  ROUND_STATUS_IN_PROGRESS,
  ROUND_STATUS_PAUSED,
  ROUND_STATUS_COMPLETED,
} from 'module/constants'
import { getUuid } from 'module/utils'

import useQuestionDisplayActions from 'feature/QuizManager/useQuestionDisplayActions'
import useGameHostActions from 'feature/Game/GameLive/GamePlay/useGameHostActions'

export default function useInGameQuizManager({ gameData, isHost, playerId } = {}) {
  const { setGameState, setRoundState, deleteRound } = useGameHostActions({ gameData })
  const questionDisplayActions = useQuestionDisplayActions({
    gameData,
  })
  const { id: currentRoundId, status: currentRoundStatus, roundName } =
    getCurrentRound({
      gameData,
    }) || {}
  const hasQuizEnded = getHasQuizEnded({ gameData })

  const [stage, setStage] = useState({ id: 'rounds', title: 'Rounds' })

  function onGoToEditRounds() {
    setStage({
      id: 'rounds',
      title: 'Rounds',
      params: {
        editing: true,
      },
    })
  }
  function onFinishEditingRounds() {
    setStage({
      id: 'rounds',
      title: 'Rounds',
      params: {
        editing: false,
      },
    })
  }
  function onGoToAddRound() {
    setStage({
      id: 'add-edit-round',
      title: 'Add Round',
      params: {
        mode: 'add',
      },
    })
  }
  function onGoToEditRound({ roundId }) {
    setStage({
      id: 'add-edit-round',
      title: 'Edit Round',
      params: {
        mode: 'edit',
        roundId,
      },
    })
  }

  async function onSaveRound({ round: roundProp, roundId: roundIdProp, onSuccess }) {
    const roundId = roundIdProp || getUuid({ prefix: 'round' })
    const existingRound = getRound({ gameData, roundId })
    const nextRoundOrderNum = getNextRoundOrderNum({ gameData })
    const round = {
      ...existingRound,
      ...roundProp,
    }
    round.status = round.status || ROUND_STATUS_PENDING
    round.order = typeof round.order !== 'undefined' ? round.order : nextRoundOrderNum
    await setRoundState({ round, roundId })
    if (onSuccess) {
      onSuccess({ roundId })
    }
  }

  async function onRemoveRound({ roundId, onSuccess }) {
    await deleteRound({ roundId })
    if (onSuccess) {
      onSuccess()
    }
  }

  function onBumpRoundOrder({ roundId, direction }) {
    const existingGameState = getGameState({
      gameData,
    })
    const updatedRounds = getBumpRoundOrderUpdates({ gameData, roundId, direction })
    const updatedGameState = {
      ...existingGameState,
      rounds: {
        ...existingGameState.rounds,
        ...updatedRounds,
      },
    }
    setGameState(updatedGameState)
  }

  function updateRoundStatus({ roundId, status }) {
    const existingGameState = getGameState({
      gameData,
    })
    const updatedGameState = {
      ...existingGameState,
    }
    // set the status
    _set(updatedGameState, `rounds.${roundId}.status`, status)
    // if no activeQuestionId, then set it to be the first one in the ordered list
    if (!_get(updatedGameState, `rounds.${roundId}.activeQuestionId`)) {
      const orderedRoundQuestions = getOrderedRoundQuestions({ gameData, roundId })
      if (orderedRoundQuestions[0]) {
        _set(updatedGameState, `rounds.${roundId}.activeQuestionId`, orderedRoundQuestions[0].id)
      }
    }
    // we also update the overall control 'state' for the game (used for initial start of the quiz)
    _set(updatedGameState, `control.started`, true)
    _set(updatedGameState, `control.activeRoundId`, roundId)
    setGameState(updatedGameState)
  }

  function onStartRound({ roundId }) {
    updateRoundStatus({
      roundId,
      status: ROUND_STATUS_IN_PROGRESS,
    })
  }

  function onPauseCurrentRound() {
    updateRoundStatus({
      roundId: currentRoundId,
      status: ROUND_STATUS_PAUSED,
    })
  }

  function onEndQuiz() {
    const existingGameState = getGameState({
      gameData,
    })
    const updatedGameState = {
      ...existingGameState,
    }
    _set(updatedGameState, `control.ended`, true)
    setGameState(updatedGameState)
  }

  function onContinueEndedQuiz() {
    const existingGameState = getGameState({
      gameData,
    })
    const updatedGameState = {
      ...existingGameState,
    }
    _set(updatedGameState, `control.ended`, false)
    setGameState(updatedGameState)
  }

  useEffect(() => {
    if (hasQuizEnded) {
      setStage({ id: 'ended', title: 'Winner' })
    } else if (currentRoundStatus === ROUND_STATUS_IN_PROGRESS) {
      setStage({ id: 'in-play', title: roundName })
    } else if (
      currentRoundStatus === ROUND_STATUS_PAUSED ||
      currentRoundStatus === ROUND_STATUS_COMPLETED
    ) {
      setStage({ id: 'rounds', title: 'Rounds' })
    }
  }, [currentRoundStatus, roundName, hasQuizEnded])

  return {
    gameData,
    isHost,
    playerId,
    stage,
    onGoToEditRounds,
    onFinishEditingRounds,
    onGoToAddRound,
    onGoToEditRound,
    onSaveRound,
    onRemoveRound,
    onBumpRoundOrder,
    onStartRound,
    onPauseCurrentRound,
    onEndQuiz,
    onContinueEndedQuiz,
    ...questionDisplayActions,
  }
}
