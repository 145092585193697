import { useCallback } from 'react'

import storeApi from 'store/storeApi'

const useGamePlayerActions = ({ gameData }) => {
  const { gameLinkId } = gameData
  const setPlayerMedia = useCallback(
    ({ playerId, mediaType, mediaActive, connectionId }) => {
      storeApi({
        method: 'playerAction',
        params: {
          gameLinkId,
          action: {
            type: 'setPlayerMedia',
            playerId,
            mediaType,
            mediaActive,
            connectionId,
          },
        },
      })
    },
    [gameLinkId],
  )
  const respondToQuestion = useCallback(
    ({ playerId, roundId, questionId, response }) => {
      storeApi({
        method: 'playerAction',
        params: {
          gameLinkId,
          action: {
            type: 'respondToQuestion',
            playerId,
            roundId,
            questionId,
            response,
          },
        },
      })
    },
    [gameLinkId],
  )
  return { setPlayerMedia, respondToQuestion }
}

export default useGamePlayerActions
