import { getUuid } from 'module/utils'

const TARGET_ENVIRON = process.env.REACT_APP_TARGET_ENVIRON

const nonLiveEnvironNames = {
  beta: 'beta',
  demo: 'beta - demo',
}

const firebaseConfig = {
  live: {
    apiKey: 'AIzaSyBaNa9AEesIOqlW_uhc2zkWIqefeS9KR5E',
    authDomain: 'quizhouselive.firebaseapp.com',
    databaseURL: 'https://quizhouselive.firebaseio.com',
    projectId: 'quizhouselive',
    storageBucket: 'quizhouselive.appspot.com',
    messagingSenderId: '742537974850',
    appId: '1:742537974850:web:dd3deef72332b5a30d49db',
    measurementId: 'G-5J8K485JPN',
  },
  beta: {
    apiKey: 'AIzaSyBOiE8W1yckJnhjII0pz_IuDBLkjVPGR30',
    authDomain: 'quizhousebeta.firebaseapp.com',
    databaseURL: 'https://quizhousebeta.firebaseio.com',
    projectId: 'quizhousebeta',
  },
  demo: {
    apiKey: 'AIzaSyBI2vSRPsW8lBYdwQM3jKXes6rRXAUxwxs',
    authDomain: 'quizhousedemo.firebaseapp.com',
    databaseURL: 'https://quizhousedemo.firebaseio.com',
    projectId: 'quizhousedemo',
  },
}

const providerConfig = {
  JITSI: {
    hosts: {
      domain: 'meet.jit.si',
      muc: 'conference.meet.jit.si',
    },
    bosh: `wss://meet.jit.si/xmpp-websocket`,
    clientNode: 'http://jitsi.org/jitsimeet',
  },
}

export function getNonLiveEnvironName() {
  return nonLiveEnvironNames[TARGET_ENVIRON]
}

export function getEnvironSupportsSocialLogin() {
  // only the live environ supports social login
  return TARGET_ENVIRON === 'live'
}

export function getFirebaseConfig() {
  return firebaseConfig[TARGET_ENVIRON]
}

export function getGameLinkId() {
  // hacktastically prefix game links with their environ name
  // so we can tell what environ they were generated for
  // in order to stop clean up processes running in other environs
  // and wiping them out as invalid game links!
  // DO NOT CHANGE THIS NAME FORMAT WITHOUT UPDATING THE RELATED FUNCTIONS TOO
  return getUuid({ prefix: `link-${TARGET_ENVIRON}` })
}

export function getEnvironOriginPath() {
  let originPath = ''
  switch (TARGET_ENVIRON) {
    case 'live':
      originPath = 'https://quizhouse.live'
      break
    case 'beta':
      originPath = 'https://quizhousebeta.web.app'
      break
    case 'demo':
      originPath = 'https://quizhousedemo.web.app'
      break
    default:
      break
  }
  return originPath
}

export function getVideoConfig() {
  let videoConfig = {}
  // take action when user has 5 minutes of inactivity
  const inactivityDetectionPeriod = 5 * 60 * 1000
  if (TARGET_ENVIRON === 'live') {
    const videoMode = 'TWILIO'
    videoConfig = {
      videoMode,
      providerConfig: providerConfig[videoMode],
      useDebugToken: false,
      inactivityDetectionPeriod,
    }
  }
  if (TARGET_ENVIRON === 'beta') {
    let videoMode = 'JITSI'
    videoMode = 'TWILIO'
    // videoMode = 'OPENTOK'
    videoConfig = {
      videoMode,
      providerConfig: providerConfig[videoMode],
      useDebugToken: false,
      // useDebugToken: true,
      inactivityDetectionPeriod,
    }
  }
  if (TARGET_ENVIRON === 'demo') {
    let videoMode = 'JITSI'
    videoMode = 'TWILIO'
    videoConfig = {
      videoMode,
      providerConfig: providerConfig[videoMode],
      useDebugToken: false,
      inactivityDetectionPeriod,
    }
  }
  return videoConfig
}
