import _cloneDeep from 'lodash/cloneDeep'

export function delay(delayMs = 2000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delayMs)
  })
}

export function isDevMode() {
  return process.env.NODE_ENV === 'development'
}

function uid() {
  return `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function getUuid({ prefix = 'uuid' } = {}) {
  return `${prefix}-${uid()}`
}

export function hasValue(value) {
  return typeof value !== 'undefined' && value !== '' && value !== null
}

export function clone(item) {
  return _cloneDeep(item)
}

export function sanitizeData(data = {}) {
  return JSON.parse(JSON.stringify(data))
}

export function replaceWithHtmlEntities(str) {
  // strips out the following characters from a string and replaces with the equivalent HTML entity: & < > "
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
}
