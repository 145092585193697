const brandColors = {
  pink: '#EC0A68',
  purple: '#C401F5',
  orange: '#F69F0D',
}

const altTextGrey = 'rgba(0, 0, 0, 0.4)'

export const colors = {
  primary: brandColors.pink,
  secondary: brandColors.orange,
  tertiary: brandColors.purple,
  alt: altTextGrey,
}
