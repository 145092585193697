import React, { useReducer, useContext } from 'react'

import { AppStateContext, AppDispatchContext } from 'store/App/context'
import {
  ACTION_SET_AUTH_LISTENER_INITIALISED,
  ACTION_SET_AUTHENTICATED_USER,
  ACTION_SET_UNAUTHENTICATED_USER,
  ACTION_SET_USER_DATA_LISTENER_INITIALISED,
  ACTION_SET_USER_DATA,
  ACTION_SET_SETUP_DATA,
  ACTION_USER_SIGN_UP_START,
  ACTION_USER_SIGN_UP_SUCCESS,
  ACTION_USER_SIGN_UP_ERROR,
  ACTION_USER_SIGN_IN_START,
  ACTION_USER_SIGN_IN_SUCCESS,
  ACTION_USER_SIGN_IN_ERROR,
  ACTION_CREATE_EVENT_START,
  ACTION_CREATE_EVENT_SUCCESS,
  ACTION_CREATE_EVENT_ERROR,
  ACTION_UPDATE_EVENT_START,
  ACTION_UPDATE_EVENT_SUCCESS,
  ACTION_UPDATE_EVENT_ERROR,
  ACTION_DELETE_EVENT_START,
  ACTION_DELETE_EVENT_SUCCESS,
  ACTION_DELETE_EVENT_ERROR,
} from 'store/App/actionTypes'

import {
  USER_STATUS_UNDETERMINED,
  USER_STATUS_AUTHENTICATED,
  USER_STATUS_NOT_AUTHENTICATED,
} from 'store/App/constants'

import { isDevMode } from 'module/utils'
import Debug from 'feature/Debug'
import { APP_VERSION } from 'app-version'

const defaultAppState = {
  appVersion: APP_VERSION,
  authListenerInitialised: false,
  userStatus: USER_STATUS_UNDETERMINED,
  user: undefined,
  userSigningIn: false,
  userSignInErrorDisplay: '',
  userSigningUp: false,
  userSignUpErrorDisplay: '',
  userDataListenerInitialised: false,
  userData: undefined,
  setupData: undefined,
  creatingEvent: false,
  creatingEventErrorDisplay: '',
  updatingEvents: {},
  updatingEventsErrorDisplay: {},
  deletingEvents: {},
  deletingEventsErrorDisplay: {},
}

function appReducer(state, action) {
  const { type, payload = {} } = action
  switch (type) {
    case ACTION_SET_AUTH_LISTENER_INITIALISED: {
      return {
        ...state,
        authListenerInitialised: payload.initialised,
      }
    }
    case ACTION_SET_AUTHENTICATED_USER: {
      return {
        ...state,
        userStatus: USER_STATUS_AUTHENTICATED,
        user: payload.user,
        userSigningIn: false,
        userSignInErrorDisplay: '',
        userSigningUp: false,
        userSignUpErrorDisplay: '',
      }
    }
    case ACTION_SET_UNAUTHENTICATED_USER: {
      return {
        ...state,
        userStatus: USER_STATUS_NOT_AUTHENTICATED,
        user: undefined,
        userSigningUp: false,
        userSignUpErrorDisplay: '',
        userSigningIn: false,
        userSignInErrorDisplay: '',
        userData: undefined,
      }
    }
    case ACTION_SET_USER_DATA_LISTENER_INITIALISED: {
      return {
        ...state,
        userDataListenerInitialised: payload.initialised,
      }
    }
    case ACTION_SET_USER_DATA: {
      return {
        ...state,
        userData: payload.userData,
      }
    }
    case ACTION_SET_SETUP_DATA: {
      return {
        ...state,
        setupData: payload.setupData,
      }
    }
    case ACTION_USER_SIGN_UP_START:
    case ACTION_USER_SIGN_IN_START: {
      const prop1 = type === ACTION_USER_SIGN_UP_START ? 'userSigningUp' : 'userSigningIn'
      const prop2 =
        type === ACTION_USER_SIGN_UP_START ? 'userSignUpErrorDisplay' : 'userSignInErrorDisplay'
      return {
        ...state,
        [prop1]: true,
        [prop2]: '',
      }
    }
    case ACTION_USER_SIGN_UP_SUCCESS:
    case ACTION_USER_SIGN_IN_SUCCESS: {
      const prop1 = type === ACTION_USER_SIGN_UP_SUCCESS ? 'userSigningUp' : 'userSigningIn'
      const prop2 =
        type === ACTION_USER_SIGN_UP_SUCCESS ? 'userSignUpErrorDisplay' : 'userSignInErrorDisplay'
      return {
        ...state,
        [prop1]: false,
        [prop2]: '',
      }
    }
    case ACTION_USER_SIGN_UP_ERROR:
    case ACTION_USER_SIGN_IN_ERROR: {
      const prop1 = type === ACTION_USER_SIGN_UP_ERROR ? 'userSigningUp' : 'userSigningIn'
      const prop2 =
        type === ACTION_USER_SIGN_UP_ERROR ? 'userSignUpErrorDisplay' : 'userSignInErrorDisplay'
      return {
        ...state,
        [prop1]: false,
        [prop2]: payload.errorMsg || 'Error',
      }
    }
    case ACTION_CREATE_EVENT_START: {
      return {
        ...state,
        creatingEvent: true,
        creatingEventErrorDisplay: '',
      }
    }
    case ACTION_CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        creatingEvent: false,
        creatingEventErrorDisplay: '',
      }
    }
    case ACTION_CREATE_EVENT_ERROR: {
      return {
        ...state,
        creatingEvent: false,
        creatingEventErrorDisplay: payload.errorMsg || 'Error',
      }
    }
    case ACTION_UPDATE_EVENT_START: {
      return {
        ...state,
        updatingEvents: {
          ...state.updatingEvents,
          [payload.eventId]: true,
        },
        updatingEventsErrorDisplay: {
          ...state.updatingEventsErrorDisplay,
          [payload.eventId]: '',
        },
      }
    }
    case ACTION_UPDATE_EVENT_SUCCESS: {
      const { [payload.eventId]: remove1, ...remainingUpdatingEvents } = state.updatingEvents
      const {
        [payload.eventId]: remove2,
        ...remainingUpdatingEventsErrorDisplay
      } = state.updatingEventsErrorDisplay
      return {
        ...state,
        updatingEvents: remainingUpdatingEvents,
        updatingEventsErrorDisplay: remainingUpdatingEventsErrorDisplay,
      }
    }
    case ACTION_UPDATE_EVENT_ERROR: {
      return {
        ...state,
        updatingEvents: {
          ...state.updatingEvents,
          [payload.eventId]: false,
        },
        updatingEventsErrorDisplay: {
          ...state.updatingEventsErrorDisplay,
          [payload.eventId]: payload.errorMsg || 'Error',
        },
      }
    }
    case ACTION_DELETE_EVENT_START: {
      return {
        ...state,
        deletingEvents: {
          ...state.deletingEvents,
          [payload.eventId]: true,
        },
        deletingEventsErrorDisplay: {
          ...state.deletingEventsErrorDisplay,
          [payload.eventId]: '',
        },
      }
    }
    case ACTION_DELETE_EVENT_SUCCESS: {
      const { [payload.eventId]: remove1, ...remainingDeletingEvents } = state.deletingEvents
      const {
        [payload.eventId]: remove2,
        ...remainingDeletingEventsErrorDisplay
      } = state.deletingEventsErrorDisplay
      return {
        ...state,
        deletingEvents: remainingDeletingEvents,
        deletingEventsErrorDisplay: remainingDeletingEventsErrorDisplay,
      }
    }
    case ACTION_DELETE_EVENT_ERROR: {
      return {
        ...state,
        deletingEvents: {
          ...state.deletingEvents,
          [payload.eventId]: false,
        },
        deletingEventsErrorDisplay: {
          ...state.deletingEventsErrorDisplay,
          [payload.eventId]: payload.errorMsg || 'Error',
        },
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, defaultAppState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {isDevMode() && <Debug />}
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppProvider')
  }
  return context
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within an AppProvider')
  }
  return context
}

export { AppProvider, useAppState, useAppDispatch }
