import React from 'react'

import useUserData from 'hooks/useUserData'
import { getIsFetchingInitialUserData } from 'store/App/selectors'

import AddEditEvent from './AddEditEvent'

import FullScreenLoader from 'component/FullScreenLoader'

export default function AddEvent() {
  const state = useUserData()
  const isFetchingInitialUserData = getIsFetchingInitialUserData(state)
  if (isFetchingInitialUserData) {
    return <FullScreenLoader />
  }
  return <AddEditEvent mode="add" />
}
