import { APP_MODE, APP_MODE_MOCK } from 'mode'
import api from 'api'
import mockApi from 'api/mock'

const storeApi = APP_MODE === APP_MODE_MOCK ? mockApi : api

export function getServerTimeStamp() {
  if (APP_MODE === APP_MODE_MOCK) {
    return mockApi.getServerTimeStamp()
  } else {
    return api.getServerTimeStamp()
  }
}

export default ({ method, params }) => {
  if (!storeApi[method]) {
    throw new Error(`Method "${method}" not available in mode "${APP_MODE}"`)
  }
  return storeApi[method](params)
}
