import * as firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/functions'

import { getFirebaseConfig } from 'module/environ'

const firebaseConfig = getFirebaseConfig()

export var firebaseCallFunction

export default () => {
  firebase.initializeApp(firebaseConfig)
  const firebaseFunctions = firebase.app().functions('europe-west2')
  firebaseCallFunction = (functionName) => {
    return firebaseFunctions.httpsCallable(functionName)
  }
}
