import React from 'react'

import { QuizManagerProvider } from 'feature/QuizManager/context'

import useEventQuizManager from 'feature/Events/EventRounds/useEventQuizManager'
import EventSceneController from 'feature/Events/EventRounds/EventSceneController'

export function EventQuizManager() {
  return <EventSceneController />
}

export default function ({ eventId, initialData, readOnly }) {
  const quizManager = useEventQuizManager({ eventId, initialData, readOnly })
  return (
    <QuizManagerProvider value={quizManager}>
      <EventSceneController />
    </QuizManagerProvider>
  )
}
