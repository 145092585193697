import React, { useCallback } from 'react'
import { Image, Popup, Button } from 'semantic-ui-react'

import './quizbee-help.css'

import storeApi from 'store/storeApi'
import { useLiveGameData } from 'store/LiveGame'
import { getUserHasSuperPowers } from 'store/App/selectors'

import useUserData from 'hooks/useUserData'

const imgSrc = require('images/need-help.png')

export default function QuizbeeHelp() {
  const state = useUserData()
  const { gameData } = useLiveGameData()
  const userHasSuperPowers = getUserHasSuperPowers(state)

  const { public: publicData = {}, gameLinkId } = gameData
  const { connectionType } = publicData

  const onRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  const switchSessionTo = useCallback(
    ({ videoMode, connectionType }) => {
      console.log(`switch to `, {
        videoMode,
        connectionType,
      })
      storeApi({
        method: 'switchRoomConnection',
        params: {
          gameLinkId,
          videoMode,
          connectionType,
          onSuccess: ({ data } = {}) => {
            console.log('success')
          },
          onError: ({ errorMsg = 'Error starting game' } = {}) => {
            console.log('error')
          },
        },
      })
    },
    [gameLinkId],
  )

  return (
    <Popup
      style={{ marginRight: 10 }}
      trigger={
        <div className="quizbee-help-container">
          <div className="quizbee-help">
            <Image alt="Need Help?" src={imgSrc} className="quizbee-help-image" />
          </div>
        </div>
      }
      on="click"
      position="top right"
      content={
        <div>
          <div>
            Having issues?
            <br />
            <Button size="tiny" style={{ marginTop: 10 }} onClick={onRefresh}>
              Fix Me
            </Button>
          </div>
          {!!userHasSuperPowers && (
            <div style={{ marginTop: '1em' }}>
              Switch Session Type
              <br />
              <Button
                primary={connectionType === 'peer-to-peer'}
                size="tiny"
                style={{ marginTop: 10 }}
                onClick={() => {
                  switchSessionTo({ videoMode: 'TWILIO', connectionType: 'peer-to-peer' })
                }}
              >
                Peer To Peer
              </Button>
              <Button
                primary={connectionType === 'group-small'}
                size="tiny"
                style={{ marginTop: 10 }}
                onClick={() => {
                  switchSessionTo({ videoMode: 'TWILIO', connectionType: 'group-small' })
                }}
              >
                Small Group
              </Button>
              <Button
                primary={connectionType === 'group'}
                size="tiny"
                style={{ marginTop: 10 }}
                onClick={() => {
                  switchSessionTo({ videoMode: 'TWILIO', connectionType: 'group' })
                }}
              >
                Group
              </Button>
            </div>
          )}
        </div>
      }
    />
  )
}
