import React from 'react'

import { QuizManagerProvider } from 'feature/QuizManager/context'

import useInGameQuizManager from 'feature/Game/GameLive/GamePlay/InGameQuizManager/useInGameQuizManager'
import InGameSceneController from 'feature/Game/GameLive/GamePlay/InGameQuizManager/InGameSceneController'

export function InGameQuizManager() {
  return <InGameSceneController />
}

export default function ({
  gameData,
  isHost,
  playerId,
  onLocalSoundtrackStarted,
  onLocalSoundtrackStopped,
}) {
  const quizManager = useInGameQuizManager({ gameData, isHost, playerId })
  return (
    <QuizManagerProvider value={quizManager}>
      <InGameSceneController
        onLocalSoundtrackStarted={onLocalSoundtrackStarted}
        onLocalSoundtrackStopped={onLocalSoundtrackStopped}
      />
    </QuizManagerProvider>
  )
}
