import React, { useCallback, useMemo } from 'react'

import Input from 'component/Input'
import Typography from 'component/Typography'
import SoundPlayer from 'component/SoundPlayer'

export default function AudioInput({ setFieldValidity, ...otherInputProps }) {
  const onTrackLoadError = useCallback(() => {
    if (setFieldValidity) {
      setFieldValidity({ isValid: false })
    }
  }, [setFieldValidity])

  const onTrackLoaded = useCallback(() => {
    if (setFieldValidity) {
      setFieldValidity({ isValid: true })
    }
  }, [setFieldValidity])

  const isASoundCloudUrl = useMemo(() => {
    const source = otherInputProps.value || ''
    return source.indexOf('https://soundcloud.com/') === 0
  }, [otherInputProps])

  const inputInfo = useMemo(() => {
    if (!!otherInputProps.value && !isASoundCloudUrl) {
      return (
        <Typography.smallText alt style={{ marginLeft: 2 }}>
          valid soundcloud URLs start with <b>"https://soundcloud.com/"</b>
        </Typography.smallText>
      )
    }
    return undefined
  }, [otherInputProps, isASoundCloudUrl])

  return (
    <div>
      <Input {...otherInputProps} additionalInfo={inputInfo} />
      {!!otherInputProps.value && !!isASoundCloudUrl && (
        <SoundPlayer
          trackUrl={otherInputProps.value}
          onTrackLoaded={onTrackLoaded}
          onTrackLoadError={onTrackLoadError}
        />
      )}
    </div>
  )
}
