import React from 'react'
import { useParams } from 'react-router-dom'

import useUserData from 'hooks/useUserData'
import { getIsFetchingInitialUserData, getHostEvent } from 'store/App/selectors'

import AddEditEvent from './AddEditEvent'

import RenderOnDelay from 'component/RenderOnDelay'
import FullScreenLoader from 'component/FullScreenLoader'
import ContentPanel from 'component/ContentPanel'

export default function EditEvent() {
  const { eventId } = useParams()
  const state = useUserData()
  const isFetchingInitialUserData = getIsFetchingInitialUserData(state)
  const event = getHostEvent({ state, eventId })
  if (isFetchingInitialUserData) {
    return <FullScreenLoader />
  }
  if (!isFetchingInitialUserData && !event) {
    return (
      <RenderOnDelay>
        <ContentPanel>Event not found</ContentPanel>
      </RenderOnDelay>
    )
  }
  return <AddEditEvent mode="edit" initialData={event} />
}
