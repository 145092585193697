import React from 'react'

import { LiveGameContext } from 'store/LiveGame'

class DebugLiveGame extends React.Component {
  componentDidMount() {
    window.QHLDEBUG = window.QHLDEBUG || {}
    window.QHLDEBUG.logLiveGameState = () => {
      console.log('*** LiveGameContext', {
        context: this.context,
      })
    }
  }
  render() {
    return null
  }
}
DebugLiveGame.contextType = LiveGameContext

export default DebugLiveGame
