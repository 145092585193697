import React from 'react'

import './game-play-basic.css'

import { useLiveGameData } from 'store/LiveGame'
import useAuth from 'hooks/useAuth'

import LiveLeaderBoard from './LiveLeaderBoard'
import QuizbeeHelp from './QuizbeeHelp'

import InGameQuizManager from 'feature/Game/GameLive/GamePlay/InGameQuizManager'
import SupportingVideoConferenceInfo from 'feature/Game/GameLive/GamePlay/SupportingVideoConferenceInfo'
import ContentPanel from 'component/ContentPanel'
import { getSupportingVideoConferenceDetails } from 'feature/QuizManager/selectors'

export default function GamePlayBasic({ onInactivityDetected = () => {} } = {}) {
  const { user = {} } = useAuth()
  const { gameData } = useLiveGameData()
  const { host = {} } = gameData
  const isHost = user.id === host.id

  const {
    supportingVideoConference,
    supportingVideoConferenceInfo,
  } = getSupportingVideoConferenceDetails({ gameData })

  const subPanelContent =
    supportingVideoConference === 'yes' && supportingVideoConferenceInfo ? (
      <SupportingVideoConferenceInfo
        supportingVideoConferenceInfo={supportingVideoConferenceInfo}
      />
    ) : null

  return (
    <ContentPanel
      panelClassName="game-play-basic-container-panel"
      containerClassName="game-play-basic"
      subPanelContent={subPanelContent}
    >
      <QuizbeeHelp />

      <div className="game-play-basic-controls">
        <InGameQuizManager gameData={gameData} isHost={isHost} playerId={user.id} />
      </div>

      <div className="game-play-basic-leader-board">
        <LiveLeaderBoard mini={false} showBonusPoints={true} canSetBonusPoints={true} />
      </div>
    </ContentPanel>
  )
}
