import React from 'react'
import { Tab } from 'semantic-ui-react'

import SignIn from 'feature/AuthDialog/SignIn'
import SignUp from 'feature/AuthDialog/SignUp'

export default function TabbedAuth({ defaultActiveIndex = 0, onSignInSuccess } = {}) {
  const panes = [
    {
      menuItem: 'Sign In',
      pane: (
        <Tab.Pane key="sign-in-tab">
          <div style={{ padding: '0.2em 1em 1em 1em' }}>
            <SignIn onSuccess={onSignInSuccess} />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Sign Up',
      pane: (
        <Tab.Pane key="sign-up-tab">
          <div style={{ padding: '0.2em 1em 1em 1em' }}>
            <SignUp onSuccess={onSignInSuccess} />
          </div>
        </Tab.Pane>
      ),
    },
  ]

  return <Tab defaultActiveIndex={defaultActiveIndex} panes={panes} renderActiveOnly={false} />
}
