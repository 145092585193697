import firebase from 'firebase'

import { firebaseUpdate } from 'api/firebaseUtils'

let userDataListener
let quizHouseSetupListener

export async function startUserDataListener({ onUpdate, onUpdateSetup }) {
  const userId = firebase.auth().currentUser.uid
  const userDataRefPath = `/users/${userId}`
  try {
    if (!quizHouseSetupListener) {
      quizHouseSetupListener = firebase.database().ref('/setup')
      quizHouseSetupListener.on('value', (snapshot) => {
        onUpdateSetup(snapshot.val() || {})
      })
    }
    if (!userDataListener) {
      console.log('** startUserDataListener - started **')
      const existingData = await firebase.database().ref(userDataRefPath).once('value')
      // if this host doesn't have any data yet, then add an initial placeholder
      // so we can add a listener to their data
      if (!existingData || !existingData.val()) {
        await firebaseUpdate({
          path: userDataRefPath,
          data: {
            listenerPlaceholder: 'init',
          },
        })
      }
      // start a listener on the user data
      userDataListener = firebase.database().ref(userDataRefPath)
      userDataListener.on('value', (snapshot) => {
        onUpdate(snapshot.val() || {})
      })
    } else {
      console.log('** startUserDataListener - already started **')
    }
  } catch (error) {
    console.error(error)
  }
}

export function stopUserDataListener() {
  if (quizHouseSetupListener) {
    quizHouseSetupListener.off()
    quizHouseSetupListener = undefined
  }
  if (userDataListener) {
    console.log('** stopUserDataListener - stopped **')
    userDataListener.off()
    userDataListener = undefined
  } else {
    console.log('** stopUserDataListener - nothing to stop **')
  }
}
