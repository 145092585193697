import React, { useState, useCallback } from 'react'
import { Placeholder, Button, Table } from 'semantic-ui-react'

import './event-dashboard.css'

import useUserData from 'hooks/useUserData'
import useNav from 'hooks/useNav'
import { dateTimeDisplay } from 'module/dates'
import ContentPanel from 'component/ContentPanel/index'

import { getIsWithinGamePlayTimePeriod } from 'module/game'
import { GAME_PERIOD_POST } from 'module/constants'

import {
  getAllEvents,
  getUserHasHostAccess,
  getIsFetchingInitialUserData,
} from 'store/App/selectors'

export default function UserDashboard() {
  const state = useUserData()
  const { navToAddEvent, navToEditEvent, navToGame, copyGameLink } = useNav()
  const allEvents = getAllEvents(state)
  const isHost = getUserHasHostAccess(state)
  const isFetchingInitialUserData = getIsFetchingInitialUserData(state)
  const [eventsToShow, setEventsToShow] = useState('upcoming')
  const eventsList = allEvents[eventsToShow]

  const toggleEventsToShow = useCallback(() => {
    setEventsToShow((currentValue) => {
      if (currentValue === 'upcoming') {
        return 'historic'
      } else {
        return 'upcoming'
      }
    })
  }, [setEventsToShow])

  return (
    <ContentPanel>
      <div style={{ marginBottom: '1em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>My Quizzes</h1>
          <div>
            {!isFetchingInitialUserData && allEvents.historic && !!allEvents.historic.length && (
              <Button onClick={toggleEventsToShow}>
                {eventsToShow === 'upcoming' ? 'Previous' : 'Upcoming'} Quizzes
              </Button>
            )}
          </div>
        </div>
        {isHost && (
          <Button primary onClick={navToAddEvent}>
            Create New Quiz
          </Button>
        )}
      </div>

      {isFetchingInitialUserData && (
        <Placeholder fluid>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}

      {!isFetchingInitialUserData && eventsList.length === 0 && <div>No upcoming events</div>}

      {!isFetchingInitialUserData && eventsList.length > 0 && (
        <Table celled>
          <Table.Header className="event-dashboard-table-header">
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>When</Table.HeaderCell>
              <Table.HeaderCell style={{ width: '390px' }}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {eventsList.length > 0 &&
              eventsList.map(
                ({
                  id,
                  name,
                  dateTime,
                  gameLinkId,
                  isHost,
                  addedToGamePool = false,
                  canEditEvent = false,
                  maxDurationMinutes,
                }) => {
                  let showCopyGameLinkButton = false
                  let showNavToGameLink = false
                  const { gamePeriodStatus } = getIsWithinGamePlayTimePeriod({
                    dateTime,
                    maxDurationMinutes,
                  })
                  const gameHasBeenAndGone = gamePeriodStatus === GAME_PERIOD_POST

                  if (eventsToShow === 'upcoming') {
                    if (isHost) {
                      showNavToGameLink = addedToGamePool && !gameHasBeenAndGone
                      showCopyGameLinkButton = showNavToGameLink
                    } else {
                      showNavToGameLink = !gameHasBeenAndGone
                    }
                  }
                  return (
                    <Table.Row key={id}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>{dateTimeDisplay({ dateTime, mode: 'friendly' })}</Table.Cell>
                      <Table.Cell>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          {showNavToGameLink && (
                            <Button
                              style={{ marginLeft: '0.25em', marginRight: '0.25em' }}
                              size="small"
                              color="green"
                              onClick={() => {
                                navToGame({ gameLinkId })
                              }}
                            >
                              Go To Quiz
                            </Button>
                          )}
                          {showCopyGameLinkButton && (
                            <Button
                              style={{ marginLeft: '0.25em', marginRight: '0.25em' }}
                              primary
                              size="small"
                              onClick={() => {
                                copyGameLink({
                                  gameLinkId,
                                })
                              }}
                            >
                              Copy Join Link
                            </Button>
                          )}
                          {isHost && (
                            <>
                              <div className="event-dashboard-mobile-button-split"></div>
                              <Button
                                style={{ marginLeft: '0.25em' }}
                                size="small"
                                onClick={() => {
                                  navToEditEvent({ eventId: id })
                                }}
                              >
                                {canEditEvent ? 'EDIT' : 'VIEW'}
                              </Button>
                            </>
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )
                },
              )}
          </Table.Body>
        </Table>
      )}
    </ContentPanel>
  )
}
