import React, { useState } from 'react'
import _isEqual from 'lodash/isEqual'

// use the HiddenSubmitButton for forms with more than one button
// OR to disable submit completely
// in order to trigger a single submit handler (otherwise when the user
// presses enter in a field it will trigger the action of the first button
// it finds in the form)

function HiddenSubmitButtonComponent({ handleSubmit = () => {} }) {
  return (
    <button
      type="submit"
      style={{ display: 'none' }}
      aria-hidden="true"
      onClick={handleSubmit}
    ></button>
  )
}
export const HiddenSubmitButton = React.memo(HiddenSubmitButtonComponent)

const useForm = ({ onSubmit, initialValues = {} } = {}) => {
  const [originValues, setOriginValues] = useState(initialValues)
  const [inputs, setInputs] = useState(initialValues)
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (onSubmit) {
      onSubmit(inputs, {
        setFormOriginValues,
        clearForm,
      })
    }
  }
  const handleInputChange = (event, data) => {
    const { name, value, checked, type } = data
    let thisValue = value
    if (type === 'checkbox') {
      thisValue = !!checked
    }
    if (type === 'number') {
      thisValue = parseInt(value)
    }
    setInputs((inputs) => ({ ...inputs, [name]: thisValue }))
  }
  const clearForm = () => {
    setInputs((inputs) => {
      const update = {}
      Object.keys(inputs).forEach((key) => (update[key] = ''))
      return update
    })
  }
  const resetForm = () => {
    setInputs(initialValues)
  }
  const setFormOriginValues = ({ values }) => {
    setInputs(values)
    setOriginValues(values)
  }
  const setInputValue = ({ name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }
  const hasChanges = !_isEqual(originValues, inputs)
  return {
    inputs,
    handleSubmit,
    handleInputChange,
    clearForm,
    resetForm,
    setFormOriginValues,
    setInputValue,
    hasChanges,
  }
}

export default useForm
