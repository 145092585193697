import { useEffect, useCallback, useState, useRef } from 'react'

import { useAppState, useAppDispatch } from 'store/App'
import {
  getUser,
  getUserId,
  getIsUserAuthenticated,
  getIsUserStatusDetermined,
  getIsAuthListenerInitialised,
  getSignInUpStatus,
  getIsUserDataListenerInitialised,
} from 'store/App/selectors'
import {
  startAuthListener,
  stopUserDataListener,
  signOut as signOutAction,
  signIn as signInAction,
  signUp as signUpAction,
  resetPassword as resetPasswordAction,
} from 'store/App/actions'

import storeApi from 'store/storeApi'

const useAuth = () => {
  const state = useAppState()
  const dispatch = useAppDispatch()
  const [passwordResetMessage, setPasswordResetMessage] = useState('')

  const isAuthListenerInitialised = getIsAuthListenerInitialised(state)
  const isUserAuthenticated = getIsUserAuthenticated(state)
  const isUserDataListenerInitialised = getIsUserDataListenerInitialised(state)

  const clearPasswordResetMessageTimer = useRef()

  useEffect(() => {
    if (!isAuthListenerInitialised) {
      startAuthListener(dispatch)
    }
  }, [dispatch, isAuthListenerInitialised])

  useEffect(() => {
    if (isUserDataListenerInitialised && !isUserAuthenticated) {
      stopUserDataListener(dispatch)
    }
  }, [dispatch, isUserDataListenerInitialised, isUserAuthenticated])

  const signUp = useCallback(
    ({ name, email, password, onSuccess }) => {
      signUpAction({ dispatch, name, email, password, onSuccess })
    },
    [dispatch],
  )

  const signIn = useCallback(
    ({ email, password, onSuccess }) => {
      signInAction({ dispatch, email, password, onSuccess })
    },
    [dispatch],
  )

  const signOut = useCallback(() => {
    signOutAction(dispatch)
  }, [dispatch])

  const resetPassword = useCallback(() => {
    resetPasswordAction(dispatch)
    setPasswordResetMessage('Password reset email on its way (if account exists)')
    clearPasswordResetMessageTimer.current = setTimeout(() => {
      setPasswordResetMessage('')
    }, 3000)
  }, [dispatch, setPasswordResetMessage])

  const signInWithGoogle = useCallback(({ onSuccess }) => {
    storeApi({
      method: 'signInWithProvider',
      params: {
        providerName: 'google',
        onSuccess,
      },
    })
  }, [])

  useEffect(() => {
    return () => {
      if (clearPasswordResetMessageTimer.current) {
        clearTimeout(clearPasswordResetMessageTimer.current)
        clearPasswordResetMessageTimer.current = undefined
      }
    }
  }, [clearPasswordResetMessageTimer])

  return {
    isUserStatusDetermined: getIsUserStatusDetermined(state),
    userIsAuthorised: isUserAuthenticated,
    user: getUser(state),
    userId: getUserId(state),
    signInUpStatus: getSignInUpStatus(state),
    signUp,
    signIn,
    signOut,
    resetPassword,
    passwordResetMessage,
    signInWithGoogle,
  }
}

export default useAuth
