import React from 'react'
import * as Sentry from '@sentry/browser'

import OopsDisplay from './OopsDisplay'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true }
  }

  componentDidCatch = (error, info) => {
    Sentry.captureException(error)
    console.error({
      error,
      info,
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <OopsDisplay /> : children
  }
}

export default ErrorBoundary
