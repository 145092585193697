import { useState, useEffect, useCallback, useRef } from 'react'
import Video from 'twilio-video'

import { firebaseCallFunction } from 'firebaseInit'

import { USE_DEBUG_TOKEN_FOR_VIDEO } from 'mode'

const BASE_VIDEO_DIMENSIONS = {
  height: 180,
  width: 240,
}

// https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications
const CONNECTION_OPTIONS = {
  audio: true,
  maxAudioBitrate: 16000, //For music remove this line
  video: {
    frameRate: 24,
    ...BASE_VIDEO_DIMENSIONS,
  },
  bandwidthProfile: {
    video: {
      mode: 'grid',
      maxTracks: 5,
      maxSubscriptionBitrate: 2500000,
      renderDimensions: {
        high: BASE_VIDEO_DIMENSIONS,
        standard: BASE_VIDEO_DIMENSIONS,
        low: BASE_VIDEO_DIMENSIONS,
      },
    },
  },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  networkQuality: { local: 1, remote: 1 },
}

async function getAccessToken({ mode, gameLinkId }) {
  let token = ''
  let connectionId = ''
  const { data } = await firebaseCallFunction('getVideoAccessToken')({
    gameLinkId,
    // debugCode: 'pug-spruce-winchester-flag',
    // debugCode: 'eonder-chicken-eonder-mouse',
  })
  token = data.token
  connectionId = data.connectionId
  if (!token) {
    throw new Error('Missing token id')
  }
  return { token, connectionId }
}

const useVideoParticipantsTwilio = ({
  gameLinkId,
  onSetConnectionId,
  localVideoOff,
  localAudioOff,
}) => {
  const [error, setError] = useState(null)
  const [audioTracks, setAudioTracks] = useState({})
  const [videoTracks, setVideoTracks] = useState({})
  const localTracks = useRef({})
  const participants = useRef({})
  const room = useRef()
  const startedSession = useRef(false)

  const onParticipantTrackSubscribed = useCallback(
    ({ identity, track }) => {
      if (track.kind === 'video') {
        setVideoTracks((existingTracks) => ({
          ...existingTracks,
          [identity]: track,
        }))
      } else if (track.kind === 'audio') {
        setAudioTracks((existingTracks) => ({
          ...existingTracks,
          [identity]: track,
        }))
      }
    },
    [setVideoTracks, setAudioTracks],
  )

  const onParticipantTrackUnsubscribed = useCallback(
    ({ identity, track }) => {
      if (track.kind === 'video') {
        setVideoTracks((existingTracks) => {
          const { [identity]: tracksToRemove, ...remainingTracks } = existingTracks
          return remainingTracks
        })
      } else if (track.kind === 'audio') {
        setAudioTracks((existingTracks) => {
          const { [identity]: tracksToRemove, ...remainingTracks } = existingTracks
          return remainingTracks
        })
      }
    },
    [setVideoTracks, setAudioTracks],
  )

  const onParticipantConnected = useCallback(
    (participant) => {
      const { identity } = participant
      participants.current[identity] = participant
      participant.on('trackSubscribed', (track) => {
        onParticipantTrackSubscribed({ identity, track })
      })
      participant.on('trackUnsubscribed', (track) => {
        onParticipantTrackUnsubscribed({ identity, track })
      })
    },
    [participants, onParticipantTrackSubscribed, onParticipantTrackUnsubscribed],
  )

  const onParticipantDisconnected = useCallback(
    (participant) => {
      const { identity } = participant
      if (participants.current[identity] && participants.current[identity].removeAllListeners) {
        participants.current[identity].removeAllListeners()
      }
      setVideoTracks((existingTracks) => {
        const { [identity]: tracksToRemove, ...remainingTracks } = existingTracks
        return remainingTracks
      })
      setAudioTracks((existingTracks) => {
        const { [identity]: tracksToRemove, ...remainingTracks } = existingTracks
        return remainingTracks
      })
    },
    [setVideoTracks, setAudioTracks],
  )

  const addLocalTracksToRoom = useCallback(
    (tracks = []) => {
      tracks.forEach((track) => {
        if (track.kind === 'video') {
          localTracks.current.video = track
          room.current.localParticipant.publishTrack(track)
          setVideoTracks((existingTracks) => ({
            ...existingTracks,
            [room.current.localParticipant.identity]: track,
          }))
        } else if (track.kind === 'audio') {
          if (localAudioOff) {
            track.disable()
          }
          localTracks.current.audio = track
          room.current.localParticipant.publishTrack(track)
          setAudioTracks((existingTracks) => ({
            ...existingTracks,
            [room.current.localParticipant.identity]: track,
          }))
        }
      })
    },
    [room, localTracks, localAudioOff, setVideoTracks, setAudioTracks],
  )

  const createLocalTracks = useCallback(() => {
    const trackTypes = []
    if (!localVideoOff) {
      trackTypes.push('video')
    }
    if (!localTracks.current.audio) {
      trackTypes.push('audio')
    }
    const createLocalTracksOptions = {}
    if (trackTypes.indexOf('audio') > -1) {
      createLocalTracksOptions.audio = {
        workaroundWebKitBug180748: true,
      }
    }
    if (trackTypes.indexOf('video') > -1) {
      createLocalTracksOptions.video = {
        frameRate: 24,
        ...BASE_VIDEO_DIMENSIONS,
      }
    }
    if (Object.keys(createLocalTracksOptions).length) {
      Video.createLocalTracks(createLocalTracksOptions).then((localTracks) => {
        console.log('** Video.createLocalTracks **', {
          localTracks,
        })
        addLocalTracksToRoom(localTracks)
      })
    }
  }, [localVideoOff, addLocalTracksToRoom])

  const joinRoom = useCallback(
    ({ token }) => {
      if (token) {
        const { audio, video, ...remainingConnectionOptions } = CONNECTION_OPTIONS
        Video.connect(token, {
          name: gameLinkId,
          ...remainingConnectionOptions,
          tracks: [],
        })
          .then((roomConnection) => {
            console.log('** roomConnection**', { roomConnection })
            room.current = roomConnection
            room.current.participants.forEach(onParticipantConnected)
            room.current.on('participantConnected', onParticipantConnected)
            room.current.on('participantDisconnected', onParticipantDisconnected)
            createLocalTracks()
          })
          .catch((err) => {
            console.log('Twilio video connection error', {
              err,
            })
          })
      }
    },
    [gameLinkId, createLocalTracks, onParticipantConnected, onParticipantDisconnected],
  )

  // ** start / end session

  const startSession = useCallback(() => {
    const fetchToken = async () => {
      try {
        setError(null)
        const { token, connectionId } = await getAccessToken({
          gameLinkId,
        })
        onSetConnectionId({ connectionId })
        joinRoom({ token })
      } catch (error) {
        console.error(error)
        setError(error)
      }
    }
    fetchToken()
  }, [gameLinkId, onSetConnectionId, joinRoom])

  const endSession = useCallback(() => {
    if (localTracks.current.video) {
      localTracks.current.video.stop()
    }
    if (localTracks.current.audio) {
      localTracks.current.audio.stop()
    }
    if (room.current) {
      if (room.current.localParticipant.state === 'connected') {
        room.current.localParticipant.tracks.forEach(({ track }) => {
          track.stop()
        })
        room.current.disconnect()
      }
    }
  }, [room, localTracks])

  // ** start/stop local audio and video  **

  useEffect(() => {
    if (room.current) {
      if (localTracks.current.audio) {
        if (localAudioOff) {
          localTracks.current.audio.disable()
        } else {
          localTracks.current.audio.enable()
        }
      }
      if (localVideoOff) {
        if (localTracks.current.video) {
          localTracks.current.video.stop()
          if (room.current.localParticipant) {
            room.current.localParticipant.unpublishTrack(localTracks.current.video)
          }
          localTracks.current.video = undefined
        }
      } else {
        if (!localTracks.current.video) {
          createLocalTracks()
        }
      }
    }
  }, [room, createLocalTracks, localVideoOff, localAudioOff])

  // ** lifecycle events

  useEffect(() => {
    if (!startedSession.current) {
      startedSession.current = true
      if (USE_DEBUG_TOKEN_FOR_VIDEO) {
        console.info(
          `** CONGRATS: You are using a debug_pass_through_token - video will NOT be activated **`,
        )
      } else {
        startSession()
      }
    }
  }, [startSession])

  useEffect(() => {
    // if component un-mounts then clean up this user session
    return endSession
  }, [endSession])

  return {
    error,
    audioTracks,
    videoTracks,
  }
}

export default useVideoParticipantsTwilio
