import React from 'react'
import linkifyIt from 'linkify-it'
import _replace from 'lodash/replace'

import { getUuid, replaceWithHtmlEntities } from 'module/utils'

const linkify = linkifyIt()

export default function TextAreaDisplay({ content: contentProp }) {
  let content = contentProp

  const linksToAdd = []
  const matches = linkify.match(content) || []
  matches.forEach((match) => {
    const { raw, url } = match
    const linkPlaceholder = `[[[${getUuid()}]]]`
    linksToAdd.push({
      linkPlaceholder,
      linkHtml: `<a href="${url}" target="_blank">${raw}</a>`,
    })
    content = _replace(content, raw, linkPlaceholder)
  })
  content = replaceWithHtmlEntities(content)
  linksToAdd.forEach(({ linkPlaceholder, linkHtml }) => {
    content = _replace(content, linkPlaceholder, linkHtml)
  })

  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </div>
  )
}
