import React from 'react'
import { Segment, Button } from 'semantic-ui-react'
import { Modal, Icon } from 'semantic-ui-react'

import Typography from 'component/Typography'

import useAddEditAnswerOptions from 'feature/QuizManager/components/AddEditQuestion/useAddEditAnswerOptions'
import QuestionAnswerOption from 'feature/QuizManager/components/AddEditQuestion/QuestionAnswerOption'

export default function QuestionAnswerOptions({
  answerOptions = {},
  onSetAnswerOptions,
  readOnly,
}) {
  const {
    addEditQuestionModalOpen,
    selectedAnswerOptionId,
    onAddAnswerOption,
    onCancelAddEditAnswerOption,
    onEditAnswerOption,
    onSaveAnswerOption,
    onDeleteAnswerOption,
  } = useAddEditAnswerOptions({
    answerOptions,
    onSetAnswerOptions,
  })

  return (
    <div>
      {!readOnly && (
        <Button
          primary
          onClick={onAddAnswerOption}
          style={{ marginTop: '0.5em', marginBottom: '0.25em' }}
        >
          Add Option {selectedAnswerOptionId}
        </Button>
      )}
      {Object.entries(answerOptions).length > 0 && (
        <Segment.Group size="small">
          {Object.entries(answerOptions).map(([answerOptionId, { isCorrect, answerText }]) => {
            return (
              <Segment key={answerOptionId}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      name="check"
                      style={{
                        marginRight: '1em',
                        marginBottom: 5,
                        color: 'green',
                        opacity: isCorrect ? 1 : 0,
                      }}
                    />
                    {answerText}
                  </div>
                  {!readOnly && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        style={{ marginLeft: '1em' }}
                        size="tiny"
                        onClick={() => {
                          onEditAnswerOption({
                            answerOptionId,
                          })
                        }}
                      >
                        {readOnly ? 'VIEW' : 'EDIT'}
                      </Button>
                    </div>
                  )}
                </div>
              </Segment>
            )
          })}
        </Segment.Group>
      )}
      {Object.values(answerOptions).filter(({ isCorrect }) => !!isCorrect).length < 1 && (
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <Typography.smallText alt>
            One option should be selected as the correct answer
          </Typography.smallText>
        </div>
      )}
      {!!addEditQuestionModalOpen && (
        <Modal
          open={addEditQuestionModalOpen}
          onClose={onCancelAddEditAnswerOption}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeIcon
        >
          <Modal.Header>
            {answerOptions[selectedAnswerOptionId] ? (readOnly ? '' : 'Edit') : 'Add'} Option
          </Modal.Header>
          <Modal.Content>
            <QuestionAnswerOption
              readOnly={readOnly}
              answerOptionId={selectedAnswerOptionId}
              initialAnswerOptionValues={answerOptions[selectedAnswerOptionId]}
              onSaveAnswerOption={onSaveAnswerOption}
              onDeleteAnswerOption={onDeleteAnswerOption}
            />
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}
