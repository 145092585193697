import React from 'react'
import { Container } from 'semantic-ui-react'
import { useRouteMatch } from 'react-router-dom'

import { routePathMap } from 'hooks/useNav'

function FullWidthContainer({ children }) {
  return <div>{children}</div>
}

export default function AppContainer({ children }) {
  const { isExact: isGamePlayLink } = useRouteMatch(routePathMap.gamePlay) || {}
  let ContainerComponent = !!isGamePlayLink ? FullWidthContainer : Container
  return <ContainerComponent>{children}</ContainerComponent>
}
