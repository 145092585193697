import React from 'react'
import { createContext, useContext } from 'react'

export const LiveGameContext = createContext()

function LiveGameProvider({ value, children }) {
  return <LiveGameContext.Provider value={value}>{children}</LiveGameContext.Provider>
}

function useLiveGameData() {
  const context = useContext(LiveGameContext)
  if (context === undefined) {
    throw new Error('useLiveGameData must be used within a LiveGameProvider')
  }
  return context
}

export { LiveGameProvider, useLiveGameData }
