import React from 'react'
import { Dropdown, Menu } from 'semantic-ui-react'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import { getHasEditableRounds } from 'feature/QuizManager/selectors'

export default function MoreMenu() {
  const {
    stage = {},
    onFinishEditingRounds,
    onGoToEditRounds,
    onPauseCurrentRound,
    onContinueEndedQuiz,
    gameData,
  } = useQuizManagerContext()
  const { id, params = {} } = stage
  const options = []
  const hasEditableRounds = getHasEditableRounds({ gameData })
  if (id === 'rounds') {
    if (params.editing) {
      options.push({
        key: 'cancel-edit-rounds',
        label: 'Finish Editing',
        onClick: onFinishEditingRounds,
      })
    } else {
      if (hasEditableRounds) {
        options.push({
          key: 'edit-rounds',
          label: 'Edit Rounds',
          onClick: onGoToEditRounds,
        })
      }
    }
  }
  if (id === 'add-edit-round') {
    options.push({
      key: 'cancel-add-edit-round',
      label: 'Cancel',
      onClick: onFinishEditingRounds,
    })
  }
  if (id === 'in-play') {
    options.push({
      key: 'pause-round',
      label: 'Pause Round',
      onClick: onPauseCurrentRound,
    })
  }
  if (id === 'ended') {
    options.push({
      key: 'back-from-ended',
      label: 'Back',
      onClick: onContinueEndedQuiz,
    })
  }
  if (!options.length) {
    return null
  }
  return (
    <Menu
      compact
      size="tiny"
      style={{
        alignSelf: 'flex-end',
        marginBottom: 10,
      }}
    >
      <Dropdown item icon="ellipsis horizontal" direction="left">
        <Dropdown.Menu>
          {options.map(({ key, label, onClick }) => (
            <Dropdown.Item key={key} onClick={onClick}>
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  )
}
