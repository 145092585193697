import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'

import './question-image.css'

import useWindowSize from 'hooks/useWindowSize'

const maxImageHeight = 400

export default function QuestionImage({ questionId, questionImage, minimised, containerStyle }) {
  const containerRef = useRef()
  const imageRef = useRef()
  const [containerWidth, setContainerWidth] = useState()
  const [naturalImageDimensions, setNaturalImageDimensions] = useState({})
  const windowSize = useWindowSize()

  // set natural image dimensions whenever an image is loaded
  const onImageLoad = useCallback(() => {
    if (imageRef.current) {
      if (imageRef.current.naturalWidth && imageRef.current.naturalHeight) {
        setNaturalImageDimensions({
          [questionId]: {
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight,
          },
        })
      }
    }
  }, [imageRef, questionId, setNaturalImageDimensions])

  // set the container width
  // we also listen to windowSize to ensure image dimensions are adjusted if the user
  // resizes their window
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
  }, [containerRef, setContainerWidth, windowSize])

  const inlineImageStyles = useMemo(() => {
    let imageStyles = {
      opacity: 0,
      height: 0,
      width: 0,
    }
    if (containerWidth && questionId && naturalImageDimensions[questionId]) {
      const { width, height } = naturalImageDimensions[questionId]
      const imageHeightWidthRatio = height / width
      const maxImageWidth = containerWidth
      imageStyles.opacity = 1
      // ensure the image fits within our bounding box
      // set the max image width (and calculate the resulting height)
      imageStyles.width = width < maxImageWidth ? width : maxImageWidth
      imageStyles.height = imageStyles.width * imageHeightWidthRatio
      if (imageStyles.height > maxImageHeight) {
        // if the image would be too high, we recalculate based on a height that will fit
        imageStyles.height = maxImageHeight
        imageStyles.width = imageStyles.height / imageHeightWidthRatio
      }
      // if image is in 'minimised' mode then reduce height and width by 4
      if (minimised) {
        imageStyles.width = imageStyles.width / 4
        imageStyles.height = imageStyles.height / 4
      }
    }
    return imageStyles
  }, [minimised, naturalImageDimensions, containerWidth, questionId])

  if (!questionImage) {
    return null
  }

  return (
    <div className={`question-image-container`} style={containerStyle} ref={containerRef}>
      {!!containerWidth && (
        <img
          ref={imageRef}
          onLoad={onImageLoad}
          alt="question"
          src={questionImage}
          className={`question-image`}
          style={inlineImageStyles}
        />
      )}
    </div>
  )
}
