export const GAME_DATA_STATUS_EXISTS = 'GAME_DATA_STATUS_EXISTS'
export const GAME_DATA_STATUS_NOT_FOUND = 'GAME_DATA_STATUS_NOT_FOUND'

export const GAME_JOIN_RESULT_YOU_ARE_IN = 'GAME_JOIN_RESULT_YOU_ARE_IN'
export const GAME_JOIN_RESULT_GAME_FULL = 'GAME_JOIN_RESULT_GAME_FULL'

export const GAME_START_RESULT_SUCCESS = 'GAME_START_RESULT_SUCCESS'
export const GAME_START_RESULT_ERROR = 'GAME_START_RESULT_ERROR'

export const LIVE_GAME_STATUS_PENDING = 'LIVE_GAME_STATUS_PENDING'
export const LIVE_GAME_STATUS_IN_PROGRESS = 'LIVE_GAME_STATUS_IN_PROGRESS'
export const LIVE_GAME_STATUS_PAUSED = 'LIVE_GAME_STATUS_PAUSED'
export const LIVE_GAME_STATUS_ENDED = 'LIVE_GAME_STATUS_ENDED'
