/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import _orderBy from 'lodash/orderBy'
import { Segment, Button, Icon } from 'semantic-ui-react'

import './question-list.css'

import Typography from 'component/Typography'

export default function QuestionList({ questions = {}, onEditQuestion, readOnly }) {
  const orderedList = _orderBy(
    Object.entries(questions).map(([id, question]) => ({
      id,
      ...question,
    })),
    ['order', 'name'],
    ['asc', 'asc'],
  )
  if (orderedList.length === 0) {
    return null
  }
  return (
    <Segment.Group size="small">
      {orderedList.map((question, index) => {
        const { questionImage, questionAudio } = question
        let answerText = question.answerText
        if (question.answerOptions) {
          Object.values(question.answerOptions).forEach((option) => {
            if (option.isCorrect) {
              answerText = option.answerText
            }
          })
        }
        return (
          <Segment key={question.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <Typography.text>
                    Q{index + 1}. {question.questionText}
                  </Typography.text>
                </div>
                {!!questionImage && (
                  <div className="question-list-image-container">
                    <img alt="Question Image" src={questionImage} className="question-list-image" />
                  </div>
                )}
                {answerText && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!!questionAudio && <Icon name="music" style={{ marginRight: 7 }} />}
                    <Typography.text alt>{answerText}</Typography.text>
                  </div>
                )}
              </div>

              <Button
                size="tiny"
                onClick={() => {
                  const { id: questionId, ...rest } = question
                  onEditQuestion({
                    questionId,
                    question: rest,
                  })
                }}
              >
                {readOnly ? 'VIEW' : 'EDIT'}
              </Button>
            </div>
          </Segment>
        )
      })}
    </Segment.Group>
  )
}
