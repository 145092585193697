import React from 'react'
import { Segment, Button, Label } from 'semantic-ui-react'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import ConfirmButton from 'component/ConfirmButton'

import { getOrderedRounds, getRoundIdToStart } from 'feature/QuizManager/selectors'
import {
  ROUND_STATUS_COMPLETED,
  ROUND_STATUS_IN_PROGRESS,
  ROUND_STATUS_PAUSED,
} from 'module/constants'

export default function RoundsList({ editing, isEventEditMode } = {}) {
  const {
    readOnly,
    gameData,
    onStartRound,
    onGoToAddRound,
    onGoToEditRound,
    onRemoveRound,
    onBumpRoundOrder,
    onEndQuiz,
    onFinishEditingRounds,
  } = useQuizManagerContext()
  const orderedRounds = getOrderedRounds({ gameData })
  const roundIdToStart = getRoundIdToStart({ gameData })
  const hasExistingRounds = orderedRounds.length > 0
  const actionButtonText = hasExistingRounds ? 'Add New Round' : 'Create First Round'
  const allRoundsCompleted =
    hasExistingRounds &&
    orderedRounds.filter(({ status }) => status !== ROUND_STATUS_COMPLETED).length === 0
  return (
    <div>
      {!hasExistingRounds && <p>No quiz rounds created</p>}
      {hasExistingRounds && (
        <Segment.Group size="small">
          {orderedRounds.map((round, index) => {
            const { id, status, roundName } = round
            let statusLabel = null
            let canEdit = true
            let canBumpOrder = true
            const hasOneRound = orderedRounds.length === 1
            const isFirstItem = index === 0
            const isLastItem = index === orderedRounds.length - 1
            if (status === ROUND_STATUS_COMPLETED) {
              canEdit = isEventEditMode || false
              statusLabel = <Label color="green">COMPLETE</Label>
            }
            if (status === ROUND_STATUS_IN_PROGRESS) {
              canBumpOrder = false
              statusLabel = (
                <Label style={{ display: 'flex', alignItems: 'center' }}>IN PROGRESS</Label>
              )
            }
            if (readOnly) {
              canBumpOrder = false
            }
            if (!editing && roundIdToStart === id) {
              statusLabel = (
                <Label
                  color="blue"
                  as="a"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    onStartRound({ roundId: id })
                  }}
                >
                  START ROUND
                </Label>
              )
            }
            if (!editing && status === ROUND_STATUS_PAUSED) {
              statusLabel = (
                <Label
                  color="blue"
                  as="a"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    onStartRound({ roundId: id })
                  }}
                >
                  RESUME ROUND
                </Label>
              )
            }
            return (
              <Segment key={id}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {roundName}
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'no-wrap', marginLeft: '1em' }}>
                    {statusLabel}
                    {canEdit && canBumpOrder && editing && (
                      <Button
                        size="tiny"
                        icon="arrow up"
                        style={{
                          display: isFirstItem || hasOneRound ? 'none' : 'inherit',
                        }}
                        onClick={() => {
                          onBumpRoundOrder({
                            direction: 'up',
                            roundId: id,
                          })
                        }}
                      />
                    )}
                    {canEdit && canBumpOrder && editing && (
                      <Button
                        size="tiny"
                        icon="arrow down"
                        style={{
                          display: isLastItem || hasOneRound ? 'none' : 'inherit',
                        }}
                        onClick={() => {
                          onBumpRoundOrder({
                            direction: 'down',
                            roundId: id,
                          })
                        }}
                      />
                    )}
                    {canEdit && editing && (
                      <Button
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        size="tiny"
                        onClick={() => {
                          onGoToEditRound({
                            roundId: id,
                          })
                        }}
                      >
                        {readOnly ? 'VIEW' : 'EDIT'}
                      </Button>
                    )}
                    {canEdit && editing && !readOnly && (
                      <ConfirmButton
                        renderButton={() => (
                          <Button size="tiny" color="red" icon="trash alternate" />
                        )}
                        renderConfirmButton={() => (
                          <Button
                            size="tiny"
                            color="red"
                            content="Delete"
                            onClick={() => {
                              onRemoveRound({
                                roundId: id,
                              })
                            }}
                          />
                        )}
                        renderCancelButton={({ onCancel }) => (
                          <Button size="tiny" color="black" content="Cancel" onClick={onCancel} />
                        )}
                      />
                    )}
                  </div>
                </div>
              </Segment>
            )
          })}
        </Segment.Group>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!!editing && !isEventEditMode && (
          <Button primary onClick={onFinishEditingRounds}>
            Finish Editing
          </Button>
        )}
        {(!editing || isEventEditMode) && !readOnly && (
          <Button primary onClick={onGoToAddRound}>
            {actionButtonText}
          </Button>
        )}
        {!editing && !!allRoundsCompleted && (
          <Button primary onClick={onEndQuiz}>
            End Quiz
          </Button>
        )}
      </div>
    </div>
  )
}
