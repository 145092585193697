import storeApi from 'store/storeApi'
import {
  ACTION_SET_USER_DATA_LISTENER_INITIALISED,
  ACTION_SET_USER_DATA,
  ACTION_SET_SETUP_DATA,
} from 'store/App/actionTypes'

export function startUserDataListener(dispatch) {
  try {
    dispatch({
      type: ACTION_SET_USER_DATA_LISTENER_INITIALISED,
      payload: { initialised: true },
    })
    storeApi({
      method: 'startUserDataListener',
      params: {
        onUpdate: (userData) => {
          dispatch({
            type: ACTION_SET_USER_DATA,
            payload: { userData },
          })
        },
        onUpdateSetup: (setupData) => {
          dispatch({
            type: ACTION_SET_SETUP_DATA,
            payload: { setupData },
          })
        },
      },
    })
  } catch (error) {
    dispatch({
      type: ACTION_SET_USER_DATA_LISTENER_INITIALISED,
      payload: { initialised: false },
    })
    console.warn('error listening to user data', { error })
  }
}

export function stopUserDataListener(dispatch) {
  try {
    dispatch({
      type: ACTION_SET_USER_DATA_LISTENER_INITIALISED,
      payload: { initialised: false },
    })
    storeApi({
      method: 'stopUserDataListener',
    })
  } catch (error) {
    console.warn('error stopping user data listener', { error })
  }
}
