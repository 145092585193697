export function getDurationDisplay({ duration }) {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)

  let durationDisplay = ''
  if (seconds > 0 || minutes > 0) {
    if (minutes > 0) {
      const s = minutes === 1 ? '' : 's'
      durationDisplay = `${minutes} min${s} `
    }
    const s = seconds === 1 ? '' : 's'
    durationDisplay += `${seconds} second${s}`
  }
  return durationDisplay
}
