import { useState, useCallback } from 'react'

import useTimeout from 'hooks/useTimeout'

export default function RenderOnDelay({ delay = 1000, children }) {
  const [displayed, setDisplayed] = useState(false)
  const callback = useCallback(() => {
    setDisplayed(true)
  }, [setDisplayed])
  useTimeout(callback, delay)
  if (!displayed) {
    return null
  }
  return children
}
