import { timeDiff } from 'module/calcs'
import { clone } from 'module/utils'
import { RESPONSE_ASSESSMENT_CORRECT } from 'module/constants'

import {
  getCurrentRound,
  getCurrentQuestionId,
  getCurrentQuestion,
  getOrderedRoundQuestions,
  getCurrentPlayerResponses,
  getCurrentQuestionPlayerAssessments,
  getPlayerImage,
} from 'feature/QuizManager/selectors'

function getResponseDuration({ responseTimeStamp, questionRevealedAt }) {
  if (!responseTimeStamp || !questionRevealedAt) {
    return undefined
  }
  return timeDiff({
    from: questionRevealedAt,
    to: responseTimeStamp,
  })
}

// TOOD: make less hacky
// we strip out the isCorrect option (lame, but enough for now until we add DB rules and protect
// question meta data)
function refineQuestion({ isHost, answerRevealed, question }) {
  let correctAnswerOptionId = ''
  const refinedQuestion = clone(question)
  if (refinedQuestion.answerOptions) {
    Object.keys(refinedQuestion.answerOptions).forEach((optionId) => {
      if (isHost || (!isHost && answerRevealed)) {
        if (refinedQuestion.answerOptions[optionId].isCorrect) {
          correctAnswerOptionId = optionId
        }
      }
      delete refinedQuestion.answerOptions[optionId].isCorrect
    })
  }
  return {
    refinedQuestion,
    correctAnswerOptionId,
  }
}

export function getQuestionDisplayProps({ isHost, gameData }) {
  const currentRound = getCurrentRound({
    gameData,
  })
  const currentQuestionId = getCurrentQuestionId({
    gameData,
  })
  const currentQuestion = getCurrentQuestion({
    gameData,
  })
  const {
    id: roundId,
    questionRevealed,
    questionRevealedAt,
    answerRevealed,
    responseTiming,
    responseCapture,
  } = currentRound
  const orderedRoundQuestions = getOrderedRoundQuestions({
    gameData,
    roundId,
  })
  const lastQuestionId = orderedRoundQuestions.length
    ? orderedRoundQuestions[orderedRoundQuestions.length - 1].id
    : ''
  let question = currentQuestion
  if (!Object.keys(question).length) {
    question = orderedRoundQuestions[0] || {}
  }
  const currentPlayerResponses = getCurrentPlayerResponses({
    gameData,
  })
  const currentPlayerAssessments = getCurrentQuestionPlayerAssessments({
    gameData,
  })
  let allPlayersResponded = true
  let allResponsesAssessed = true
  let hasOneCorrectResponse = false
  const responses = []
  Object.values(currentPlayerResponses).forEach(
    ({ playerId, playerName, pass, answerText, answerOptionId, hasResponded, timeStamp }) => {
      if (!currentPlayerAssessments[playerId] && !pass) {
        allResponsesAssessed = false
      }
      if (hasResponded) {
        responses.push({
          playerId,
          playerImage: getPlayerImage({ gameData, playerId }),
          playerName,
          responseDuration: getResponseDuration({
            responseTimeStamp: timeStamp,
            questionRevealedAt,
          }),
          pass,
          answerText,
          answerOptionId,
          assessment: currentPlayerAssessments[playerId],
        })
        if (currentPlayerAssessments[playerId] === RESPONSE_ASSESSMENT_CORRECT) {
          hasOneCorrectResponse = true
        }
      } else {
        allPlayersResponded = false
      }
    },
  )
  const isFinalQuestionInRound = currentQuestionId === lastQuestionId
  const { refinedQuestion, correctAnswerOptionId = '' } = refineQuestion({
    question,
    isHost,
    answerRevealed,
  })
  return {
    question: refinedQuestion,
    correctAnswerOptionId,
    questionRevealed,
    questionRevealedAt,
    answerRevealed,
    responseTiming,
    responseCapture,
    allPlayersResponded,
    allResponsesAssessed,
    hasOneCorrectResponse,
    isFinalQuestionInRound,
    currentQuestionId,
    responses,
  }
}
