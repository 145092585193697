import storeApi from 'store/storeApi'
import {
  ACTION_SET_AUTH_LISTENER_INITIALISED,
  ACTION_SET_AUTHENTICATED_USER,
  ACTION_SET_UNAUTHENTICATED_USER,
  ACTION_USER_SIGN_IN_START,
  ACTION_USER_SIGN_IN_SUCCESS,
  ACTION_USER_SIGN_IN_ERROR,
  ACTION_USER_SIGN_UP_START,
  ACTION_USER_SIGN_UP_SUCCESS,
  ACTION_USER_SIGN_UP_ERROR,
} from 'store/App/actionTypes'

export function startAuthListener(dispatch) {
  try {
    dispatch({
      type: ACTION_SET_AUTH_LISTENER_INITIALISED,
      payload: { initialised: true },
    })
    storeApi({
      method: 'startAuthListener',
      params: {
        onAuthorised: ({ user }) => {
          dispatch({
            type: ACTION_SET_AUTHENTICATED_USER,
            payload: { user },
          })
        },
        onUnAuthorised: () => {
          dispatch({
            type: ACTION_SET_UNAUTHENTICATED_USER,
          })
        },
      },
    })
  } catch (error) {
    dispatch({
      type: ACTION_SET_AUTH_LISTENER_INITIALISED,
      payload: { initialised: false },
    })
  }
}

export function signOut(dispatch) {
  try {
    storeApi({
      method: 'signOut',
      params: {
        onSuccessfulSignOut: () => {
          dispatch({
            type: ACTION_SET_UNAUTHENTICATED_USER,
          })
        },
      },
    })
  } catch (error) {
    console.warn('error signing user out', { error })
  }
}

export function signIn({ dispatch, email, password, onSuccess }) {
  try {
    dispatch({
      type: ACTION_USER_SIGN_IN_START,
    })
    storeApi({
      method: 'signIn',
      params: {
        email,
        password,
        onSuccess: ({ user } = {}) => {
          if (user) {
            dispatch({
              type: ACTION_SET_AUTHENTICATED_USER,
              payload: { user },
            })
          }
          dispatch({
            type: ACTION_USER_SIGN_IN_SUCCESS,
          })
          if (onSuccess) {
            onSuccess()
          }
        },
        onError: ({ errorMsg = 'Error signing in' } = {}) => {
          dispatch({
            type: ACTION_USER_SIGN_IN_ERROR,
            payload: { errorMsg },
          })
        },
      },
    })
  } catch (error) {
    console.warn('error signing up user', { error })
    dispatch({
      type: ACTION_USER_SIGN_IN_ERROR,
      payload: {
        errorMsg: 'Error signing in',
      },
    })
  }
}

export function signUp({ dispatch, name, email, password, onSuccess }) {
  try {
    dispatch({
      type: ACTION_USER_SIGN_UP_START,
    })
    storeApi({
      method: 'signUp',
      params: {
        name,
        email,
        password,
        onSuccess: ({ user } = {}) => {
          if (user) {
            dispatch({
              type: ACTION_SET_AUTHENTICATED_USER,
              payload: { user },
            })
          }
          dispatch({
            type: ACTION_USER_SIGN_UP_SUCCESS,
          })
          if (onSuccess) {
            onSuccess()
          }
        },
        onError: ({ errorMsg = 'Error signing up' } = {}) => {
          dispatch({
            type: ACTION_USER_SIGN_UP_ERROR,
            payload: { errorMsg },
          })
        },
      },
    })
  } catch (error) {
    console.warn('error signing up user', { error })
    dispatch({
      type: ACTION_USER_SIGN_UP_ERROR,
      payload: {
        errorMsg: 'Error signing up',
      },
    })
  }
}

export function resetPassword() {
  storeApi({
    method: 'resetPassword',
  })
}
