import React from 'react'
import { Modal } from 'semantic-ui-react'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import {
  getRoundTitle,
  getRoundProgressDisplay,
  getQuestionTypeDisplay,
} from 'feature/QuizManager/selectors'

import RoundsList from 'feature/QuizManager/components/RoundsList'
import AddEditRound from 'feature/QuizManager/components/AddEditRound'
import QuestionDisplay from 'feature/QuizManager/components/QuestionDisplay'
import QuizEnd from 'feature/QuizManager/components/QuizEnd'
import MoreMenu from 'feature/QuizManager/components/MoreMenu'
import Placeholder from 'feature/QuizManager/components/Placeholder'
import Typography from 'component/Typography'

export default function InGameSceneController({
  onLocalSoundtrackStarted,
  onLocalSoundtrackStopped,
}) {
  const { stage, isHost, onFinishEditingRounds, gameData } = useQuizManagerContext()
  const { id, title, params = {} } = stage
  let stageTitle = isHost ? title : ''
  let stageSubTitle = []
  let showQuestionDisplay, showRoundsList, showWaitingForHost, showAddEditRound, showQuizEnd
  switch (id) {
    case 'in-play':
      stageTitle = `${getRoundTitle({ gameData })} ${getRoundProgressDisplay({ gameData })}`
      stageSubTitle = getQuestionTypeDisplay({ gameData })
      showQuestionDisplay = true
      break

    case 'rounds':
      if (isHost) {
        showRoundsList = true
      } else {
        showWaitingForHost = true
      }
      break

    case 'add-edit-round':
      showRoundsList = true
      showAddEditRound = true
      break

    case 'ended':
      stageTitle = title
      showQuizEnd = true
      break

    default:
      break
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {(!!stageTitle || !!isHost) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1em',
          }}
        >
          <div>
            <Typography.h3 style={{ margin: 0 }} primary>
              {stageTitle}
            </Typography.h3>
            {!!stageSubTitle && <Typography.smallText alt>{stageSubTitle}</Typography.smallText>}
          </div>
          {isHost && <MoreMenu />}
        </div>
      )}
      {showQuestionDisplay && (
        <QuestionDisplay
          onLocalSoundtrackStarted={onLocalSoundtrackStarted}
          onLocalSoundtrackStopped={onLocalSoundtrackStopped}
        />
      )}
      {showRoundsList && <RoundsList {...params} />}
      {showWaitingForHost && <Placeholder message="Waiting for host" />}
      {showAddEditRound && (
        <Modal
          open
          onClose={onFinishEditingRounds}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeIcon
        >
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <div style={{ minHeight: '50vh' }}>
              <AddEditRound {...params} />
            </div>
          </Modal.Content>
        </Modal>
      )}
      {showQuizEnd && <QuizEnd />}
    </div>
  )
}
