import React from 'react'
import { Modal } from 'semantic-ui-react'

import { useQuizManagerContext } from 'feature/QuizManager/context'

import RoundsList from 'feature/QuizManager/components/RoundsList'
import AddEditRound from 'feature/QuizManager/components/AddEditRound'

export default function EventSceneController() {
  const { stage, onFinishEditingRounds, isSaving } = useQuizManagerContext()
  const { id, title, params = {} } = stage

  return (
    <div>
      <RoundsList editing isEventEditMode />
      {id === 'add-edit-round' && (
        <Modal
          open
          onClose={onFinishEditingRounds}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeIcon
        >
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <div style={{ minHeight: '50vh' }}>
              <AddEditRound {...params} isSaving={isSaving} />
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}
