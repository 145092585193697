import React from 'react'

import './home.css'

import ContentPanel from 'component/ContentPanel'
import Typography from 'component/Typography'
import TabbedAuth from 'feature/AuthDialog/TabbedAuth'

import useNav from 'hooks/useNav'
import useAuth from 'hooks/useAuth'

const imgSrc = require('images/bug-trees.svg')

export function Home({ navToDashboard = () => {}, userIsAuthorised, isUserStatusDetermined }) {
  if (userIsAuthorised) {
    return (
      <ContentPanel panelClassName="home-container-panel-authorised-user">
        <Typography.h1 size="megaText" secondary>
          Get your quiz on
        </Typography.h1>
        <img alt="Quiz Master" src={imgSrc} />
      </ContentPanel>
    )
  }
  return (
    <ContentPanel panelClassName="home-container-panel">
      <div className="home-container-panel-1">
        <Typography.h1 size="megaText" secondary>
          Discover a world of online quizzing
        </Typography.h1>
        <Typography.h3 secondary style={{ margin: 0 }}>
          Sign in or create an account and get gaming with friends.
        </Typography.h3>
        <img alt="Quiz Master" src={imgSrc} />
      </div>
      <div className="home-container-panel-2">
        <TabbedAuth onSignInSuccess={navToDashboard} />
      </div>
    </ContentPanel>
  )
}

export default () => {
  const { navToDashboard } = useNav()
  const { userIsAuthorised, isUserStatusDetermined } = useAuth()
  return (
    <Home
      navToDashboard={navToDashboard}
      userIsAuthorised={userIsAuthorised}
      isUserStatusDetermined={isUserStatusDetermined}
    />
  )
}
