import { useCallback } from 'react'

import storeApi from 'store/storeApi'

const useGameHostActions = ({ gameData }) => {
  const { gameLinkId, game = {} } = gameData
  const setPlayerMedia = useCallback(
    ({ playerId, mediaType, mediaActive }) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'overridePlayerMedia',
            playerId,
            mediaType,
            mediaActive,
          },
        },
      })
    },
    [gameLinkId],
  )

  const setHostMedia = useCallback(
    ({ mediaType, mediaActive, connectionId, hostId }) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'setHostMedia',
            hostId,
            mediaType,
            mediaActive,
            connectionId,
          },
        },
      })
    },
    [gameLinkId],
  )

  const setPlayerPoints = useCallback(
    ({ playerId, points }) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'setPlayerPoints',
            playerId,
            points,
          },
        },
      })
    },
    [gameLinkId],
  )

  const setAllPlayersMedia = useCallback(
    ({ mediaType, mediaActive }) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'setAllPlayersMedia',
            mediaType,
            mediaActive,
          },
        },
      })
    },
    [gameLinkId],
  )

  const setGameState = useCallback(
    (state) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'setGameState',
            state,
          },
        },
      })
    },
    [gameLinkId],
  )

  const setRoundState = useCallback(
    ({ roundId, round }) => {
      setGameState({
        ...game,
        rounds: {
          ...game.rounds,
          [roundId]: round,
        },
      })
    },
    [game, setGameState],
  )

  const deleteRound = useCallback(
    ({ roundId }) => {
      storeApi({
        method: 'hostAction',
        params: {
          gameLinkId,
          action: {
            type: 'deleteRound',
            roundId,
          },
        },
      })
    },
    [gameLinkId],
  )

  return {
    setPlayerMedia,
    setHostMedia,
    setPlayerPoints,
    setAllPlayersMedia,
    setGameState,
    setRoundState,
    deleteRound,
  }
}

export default useGameHostActions
