import React from 'react'
import { Icon } from 'semantic-ui-react'

export default function MediaControl({
  videoOn,
  audioOn,
  toggleVideo = undefined,
  toggleAudio = undefined,
}) {
  const onClickAudioProps = toggleAudio
    ? {
        onClick: toggleAudio,
        link: true,
      }
    : {}
  const onClickVideoProps = toggleVideo
    ? {
        onClick: toggleVideo,
        link: true,
      }
    : {}
  return (
    <div>
      <Icon name={audioOn ? 'microphone' : 'mute'} size="big" {...onClickAudioProps} />
      <Icon name={videoOn ? 'unhide' : 'hide'} size="big" {...onClickVideoProps} />
    </div>
  )
}
