import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

function getPathRequiresBackConfirmation({ nextPath = '' }) {
  let isBackDisabledForPath = false
  if (nextPath.indexOf('/play/') === 0 && nextPath.indexOf('/live') > -1) {
    isBackDisabledForPath = true
  }
  return isBackDisabledForPath
}

export default function NavListener() {
  const history = useHistory()
  const listener = useRef()
  const pathname = useRef()
  const search = useRef()

  const confirmRef = useRef()

  useEffect(() => {
    if (!listener.current) {
      listener.current = history.listen((location, action) => {
        // THE BELOW CONDITIONALLY PROMPTS THE USER TO CONFIRM THEY WANT TO LEAVE THE PAGE
        // FOR GIVEN PATHS
        if (
          getPathRequiresBackConfirmation({
            nextPath: location.pathname,
          })
        ) {
          if (action === 'PUSH') {
            if (location.pathname !== pathname.current || location.search !== search.current) {
              pathname.current = location.pathname
              search.current = location.search
              history.push({
                pathname: location.pathname,
                search: location.search,
              })
            }
          } else {
            if (!confirmRef.current) {
              confirmRef.current = true
              if (window.confirm('You are about to leave this quiz')) {
                confirmRef.current = false
                pathname.current = undefined
                search.current = undefined
                // send the user back one screen
                history.go(-1)
              } else {
                // revert the pop by going forwards in history
                history.go(1)
              }
            } else {
              // handle the second "pop" caused by saying "no" to the above confirmation
              confirmRef.current = false
              history.go(1)
            }
          }
        }

        // THE BELOW COMPLETELY DISABLES BACK BUTTON
        // if (action === 'PUSH') {
        //   if (location.pathname !== pathname.current || location.search !== search.current) {
        //     pathname.current = location.pathname
        //     search.current = location.search
        //     history.push({
        //       pathname: location.pathname,
        //       search: location.search,
        //     })
        //   }
        // } else {
        //   history.go(1)
        // }
      })
    }
  }, [history, listener])
  return null
}
