export const ACTION_SET_AUTH_LISTENER_INITIALISED = 'ACTION_SET_AUTH_LISTENER_INITIALISED'
export const ACTION_SET_AUTHENTICATED_USER = 'ACTION_SET_AUTHENTICATED_USER'
export const ACTION_SET_UNAUTHENTICATED_USER = 'ACTION_SET_UNAUTHENTICATED_USER'

export const ACTION_USER_SIGN_UP_START = 'ACTION_USER_SIGN_UP_START'
export const ACTION_USER_SIGN_UP_SUCCESS = 'ACTION_USER_SIGN_UP_SUCCESS'
export const ACTION_USER_SIGN_UP_ERROR = 'ACTION_USER_SIGN_UP_ERROR'

export const ACTION_USER_SIGN_IN_START = 'ACTION_USER_SIGN_IN_START'
export const ACTION_USER_SIGN_IN_SUCCESS = 'ACTION_USER_SIGN_IN_SUCCESS'
export const ACTION_USER_SIGN_IN_ERROR = 'ACTION_USER_SIGN_IN_ERROR'

export const ACTION_SET_USER_DATA_LISTENER_INITIALISED = 'ACTION_SET_USER_DATA_LISTENER_INITIALISED'
export const ACTION_SET_USER_DATA = 'ACTION_SET_USER_DATA'
export const ACTION_SET_SETUP_DATA = 'ACTION_SET_SETUP_DATA'

export const ACTION_CREATE_EVENT_START = 'ACTION_CREATE_EVENT_START'
export const ACTION_CREATE_EVENT_SUCCESS = 'ACTION_CREATE_EVENT_SUCCESS'
export const ACTION_CREATE_EVENT_ERROR = 'ACTION_CREATE_EVENT_ERROR'

export const ACTION_UPDATE_EVENT_START = 'ACTION_UPDATE_EVENT_START'
export const ACTION_UPDATE_EVENT_SUCCESS = 'ACTION_UPDATE_EVENT_SUCCESS'
export const ACTION_UPDATE_EVENT_ERROR = 'ACTION_UPDATE_EVENT_ERROR'

export const ACTION_DELETE_EVENT_START = 'ACTION_DELETE_EVENT_START'
export const ACTION_DELETE_EVENT_SUCCESS = 'ACTION_DELETE_EVENT_SUCCESS'
export const ACTION_DELETE_EVENT_ERROR = 'ACTION_DELETE_EVENT_ERROR'
