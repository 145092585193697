import _get from 'lodash/get'

import { getIsUserAuthenticated } from './index'

export function getUserHasSuperPowers(state) {
  return _get(state, 'userData.meta.hasSuperPowers', false)
}

export function getUserHasHostAccess(state) {
  return _get(state, 'userData.meta.isHost', false)
}

export function getUserHasHostVideoAccess(state) {
  return _get(state, 'userData.meta.canCreateVideoEvents', false)
}

export function getHostEvents(state) {
  return _get(state, 'userData.events', {})
}

export function getIsUserDataListenerInitialised(state) {
  return !!state.userDataListenerInitialised
}

export function getIsFetchingInitialUserData(state) {
  // we assume the app is fetching initial user data if
  // the user is authorised but their user data is undefined
  return getIsUserAuthenticated(state) && state.userData === undefined
}

export function getGames(state) {
  return _get(state, 'userData.games', {})
}

export function getHasGameAccess({ state, gameLinkId }) {
  const games = getGames(state)
  return !!games[gameLinkId]
}

export function getSetupData(state) {
  return _get(state, 'setupData', {})
}

export function getAppVersion(state) {
  return _get(state, 'appVersion', '0.0.0')
}
