import React, { useRef } from 'react'

import useVideoPlayerSlaveJitsi from 'hooks/useVideoPlayerSlaveJitsi'

import './video-player.css'

export default function VideoPlayerSlaveJitsi({
  isMe,
  audioTrack,
  videoTrack,
  onEnableVideoConnection,
  onDisableVideoConnection,
  onEnableAudioConnection,
  onDisableAudioConnection,
  audioEnabled,
  videoEnabled,
  videoDimensionStyles,
}) {
  const videoRef = useRef()
  const audioRef = useRef()
  useVideoPlayerSlaveJitsi({
    isMe,
    videoRef,
    audioRef,
    audioTrack,
    videoTrack,
    audioEnabled,
    videoEnabled,
    onEnableVideoConnection,
    onDisableVideoConnection,
    onEnableAudioConnection,
    onDisableAudioConnection,
  })
  return (
    <div className="video-container">
      <video ref={videoRef} autoPlay={true} style={videoDimensionStyles} />
      <audio ref={audioRef} autoPlay={true} muted={isMe} />
    </div>
  )
}
