import React, { useState, useCallback } from 'react'
import { Form, Button } from 'semantic-ui-react'

import './player-question-display.css'

import useForm from 'hooks/useForm'

import Input from 'component/Input'
import Typography from 'component/Typography'
import SoundPlayer from 'component/SoundPlayer'

import Placeholder from 'feature/QuizManager/components/Placeholder'
import ResponseList from 'feature/QuizManager/components/QuestionDisplay/ResponseList'
import MultipleChoiceDisplay from 'feature/QuizManager/components/QuestionDisplay/MultipleChoiceDisplay'
import QuestionImage from 'feature/QuizManager/components/QuestionDisplay/QuestionImage'

import { RESPONSE_CAPTURE_TEXT, QUESTION_TYPE_MULTIPLE_CHOICE } from 'module/constants'

export default function PlayerQuestionDisplay({
  playerId,
  question,
  currentQuestionId,
  questionRevealed,
  answerRevealed,
  correctAnswerOptionId,
  responseCapture,
  responses = [],
  onSubmitResponse,
  onPass,
  onLocalSoundtrackStarted,
  onLocalSoundtrackStopped,
}) {
  const { questionType, questionText, questionImage, questionAudio, answerText } = question
  const requiresTextInput = responseCapture === RESPONSE_CAPTURE_TEXT
  const playerResponse = responses.filter((response) => response.playerId === playerId)[0]

  const [localResponses, setLocalResponses] = useState({})
  const playerHasResponded = !!playerResponse || !!localResponses[currentQuestionId]

  const onSubmit = useCallback(
    (inputs, { clearForm }) => {
      if (inputs.answerText) {
        onSubmitResponse({
          playerId,
          answerText: inputs.answerText,
        })
        clearForm()
      } else {
        onSubmitResponse({ playerId })
      }
      setLocalResponses((existingResponses) => ({
        ...existingResponses,
        [currentQuestionId]: true,
      }))
    },
    [onSubmitResponse, currentQuestionId, playerId],
  )

  const { inputs, handleInputChange, handleSubmit } = useForm({ onSubmit })

  if (!questionRevealed) {
    return <Placeholder message="Waiting for question" />
  }
  if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
    return (
      <MultipleChoiceDisplay
        isHost={false}
        questionId={currentQuestionId}
        question={question}
        answerRevealed={answerRevealed}
        correctAnswerOptionId={correctAnswerOptionId}
        playerResponse={playerResponse}
        playerHasResponded={playerHasResponded}
        responseCapture={responseCapture}
        responses={responses}
        onSubmit={({ answerOptionId }) => {
          onSubmitResponse({
            playerId,
            answerOptionId,
          })
        }}
      />
    )
  }
  return (
    <div className="player-question-display-container">
      <div className="player-question-display-question-and-answer">
        <div>
          <Typography.h3>{questionText}</Typography.h3>
        </div>
        {!!questionImage && (
          <QuestionImage
            questionId={currentQuestionId}
            questionImage={questionImage}
            minimised={playerHasResponded}
          />
        )}
        {!!questionAudio && (
          <SoundPlayer
            trackUrl={questionAudio}
            autoPlay
            onTrackStarted={onLocalSoundtrackStarted}
            onTrackStopped={onLocalSoundtrackStopped}
          />
        )}
        {answerRevealed && (
          <div style={{ marginTop: '1em' }}>
            <Typography.h3 alt>{answerText}</Typography.h3>
          </div>
        )}
      </div>
      <div className="player-question-display-response-zone">
        {!playerHasResponded && !answerRevealed && (
          <Form onSubmit={handleSubmit}>
            {questionRevealed && requiresTextInput && (
              <Input
                autoFocus
                placeholder="Answer"
                type="text"
                onChange={handleInputChange}
                name="answerText"
                value={inputs.answerText || ''}
                required
              />
            )}

            {questionRevealed && (
              <Button primary fluid>
                Submit Answer
              </Button>
            )}
            {questionRevealed && !!onPass && (
              <Button
                fluid
                style={{ marginTop: '1em' }}
                onClick={(evt) => {
                  evt.preventDefault()
                  onPass({ playerId })
                }}
              >
                Pass
              </Button>
            )}
          </Form>
        )}
        {(playerHasResponded || answerRevealed) && (
          <ResponseList
            questionType={questionType}
            responseCapture={responseCapture}
            responses={responses}
            answerRevealed={answerRevealed}
          />
        )}
      </div>
    </div>
  )
}
