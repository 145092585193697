import { useEffect } from 'react'

const useVideoPlayerSlaveJitsi = ({
  isMe,
  videoRef,
  audioRef,
  audioTrack,
  videoTrack,
  audioEnabled,
  videoEnabled,
  onEnableAudioConnection,
  onDisableAudioConnection,
  onEnableVideoConnection,
  onDisableVideoConnection,
}) => {
  useEffect(() => {
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
    }
  }, [audioRef, audioTrack])

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
    }
  }, [videoRef, videoTrack])

  useEffect(() => {
    if (isMe) {
      if (audioEnabled) {
        onEnableAudioConnection()
      } else {
        onDisableAudioConnection()
      }
    }
  }, [audioEnabled, isMe, onEnableAudioConnection, onDisableAudioConnection])

  useEffect(() => {
    if (isMe) {
      if (videoEnabled) {
        onEnableVideoConnection()
      } else {
        onDisableVideoConnection()
      }
    }
  }, [videoEnabled, isMe, onEnableVideoConnection, onDisableVideoConnection])
}

export default useVideoPlayerSlaveJitsi
