import { useState, useEffect, useCallback, useRef } from 'react'
import OT from '@opentok/client'

import { firebaseCallFunction } from 'firebaseInit'

import useAuth from 'hooks/useAuth'
import { USE_DEBUG_TOKEN_FOR_VIDEO } from 'mode'

const useVideoParticipantsOpenTok = ({
  gameLinkId,
  autoConnect,
  localVideoOff,
  localAudioOff,
  onSetConnectionId,
}) => {
  const { userId } = useAuth()
  const [error, setError] = useState(null)
  const [connectionId, setConnectionId] = useState(null)
  const [videoTracks, setVideoTracks] = useState({})

  const session = useRef()
  const publisher = useRef()
  const subscriptions = useRef()

  // *** LISTENERS ****

  const listeners = {}

  listeners.localVideoElementCreated = useCallback(
    (event) => {
      const { target, element } = event
      const { streamId } = target
      console.log('**** localVideoElementCreated ', { event, target, element, streamId })
      setConnectionId(streamId)
      setVideoTracks((existingTracks) => ({
        ...existingTracks,
        [streamId]: element,
      }))
    },
    [setConnectionId, setVideoTracks],
  )

  listeners.remoteVideoElementCreated = useCallback(
    (event) => {
      const { target, element } = event
      const { streamId } = target
      console.log('**** remoteVideoElementCreated ', { event, target, element, streamId })
      setVideoTracks((existingTracks) => ({
        ...existingTracks,
        [streamId]: element,
      }))
    },
    [setVideoTracks],
  )

  listeners.remoteStreamCreated = useCallback(
    ({ stream }) => {
      const { streamId } = stream
      console.log('**** remoteStreamCreated ', { streamId, stream })
      // add a listener to this stream
      subscriptions.current = subscriptions.current || {}
      subscriptions.current[streamId] = session.current.subscribe(stream, {
        insertDefaultUI: false,
      })
      subscriptions.current[streamId].on('videoElementCreated', listeners.remoteVideoElementCreated)
    },
    [listeners.remoteVideoElementCreated],
  )

  listeners.remoteStreamDestroyed = useCallback(
    ({ stream }) => {
      const { streamId } = stream
      console.log('**** remoteStreamDestroyed ', { streamId, stream })
      // remove listeners
      if (subscriptions.current && subscriptions.current[streamId]) {
        const { [streamId]: thisSubscription, ...remainingSubscriptions } = subscriptions.current
        thisSubscription.off()
        if (session.current) {
          session.current.unsubscribe(thisSubscription)
        }
        subscriptions.current = remainingSubscriptions
      }
      // remove the video element
      setVideoTracks((existingTracks) => {
        const { [streamId]: thisTrack, ...remainingTracks } = existingTracks
        return remainingTracks
      })
    },
    [setVideoTracks],
  )

  // streamDestroyed

  // ** start / end session

  const endSession = useCallback(() => {
    console.log('** end session **')
    // remove listeners, and generally try and switch everything off!
    if (session.current) {
      session.current.off()
      session.current.disconnect()
      if (publisher.current) {
        session.current.unpublish(publisher.current)
        publisher.current.destroy()
      }
      if (subscriptions.current) {
        Object.values(subscriptions.current).forEach((subscriber) => {
          subscriber.off()
          session.current.unsubscribe(subscriber)
        })
      }
    }
  }, [])

  const startSession = useCallback(() => {
    try {
      if (!session.current) {
        console.log('** start session **')
        const fetchCredentials = async () => {
          const { data } = await firebaseCallFunction('getVideoSessionToken')({
            gameLinkId,
          })
          const { apiKey, sessionId, token } = data || {}
          if (!apiKey || !sessionId || !token) {
            throw new Error('Missing session credentials')
          }
          session.current = OT.initSession(apiKey, sessionId)
          session.current.on('streamCreated', listeners.remoteStreamCreated)
          session.current.on('streamDestroyed', listeners.remoteStreamDestroyed)
          session.current.connect(token, function (error) {
            if (error) {
              console.log('*** error connecting', { error })
              throw new Error(`Error connecting to quiz`)
            } else {
              publisher.current = OT.initPublisher(
                null,
                {
                  insertDefaultUI: false,
                },
                (error) => {
                  if (error) {
                    console.log('*** error creating publisher', { error })
                    throw new Error(`Error creating publisher`)
                  }
                },
              )
              publisher.current.on('videoElementCreated', listeners.localVideoElementCreated)
              session.current.publish(publisher.current, (error) => {
                if (error) {
                  console.log('*** error publishing publisher', { error })
                  throw new Error(`Error publishing publisher`)
                }
              })
            }
          })
        }
        fetchCredentials()
      } else {
        console.log('*** no op - you are trying to start a session that has already started!')
      }
    } catch (error) {
      setError('Error connecting to quiz')
    }
  }, [
    gameLinkId,
    setError,
    listeners.remoteStreamCreated,
    listeners.remoteStreamDestroyed,
    listeners.localVideoElementCreated,
  ])

  // ** video connection handling **

  const onEnableVideoConnection = useCallback(() => {
    if (publisher.current) {
      publisher.current.publishVideo(true)
    }
  }, [])

  const onDisableVideoConnection = useCallback(() => {
    if (publisher.current) {
      publisher.current.publishVideo(false)
    }
  }, [])

  // ** audio connection handling **

  const onEnableAudioConnection = useCallback(() => {
    if (publisher.current) {
      publisher.current.publishAudio(true)
    }
  }, [])

  const onDisableAudioConnection = useCallback(() => {
    if (publisher.current) {
      publisher.current.publishAudio(false)
    }
  }, [])

  // ** lifecycle events

  useEffect(() => {
    if (!userId) {
      console.log('** no user id **')
    } else if (USE_DEBUG_TOKEN_FOR_VIDEO === 0) {
      console.info(
        `** CONGRATS: You are using a debug_pass_through_token - video will NOT be activated **`,
      )
    } else {
      startSession()
    }
  }, [userId, startSession])

  useEffect(() => {
    // trigger API to set the connection id for this user on the server
    // (thats how we tell who's video is who's in the context of the quiz)
    onSetConnectionId({ connectionId })
  }, [connectionId, onSetConnectionId])

  useEffect(() => {
    // if component un-mounts then clean up this user session
    return endSession
  }, [endSession])

  useEffect(() => {
    if (localVideoOff) {
      onDisableVideoConnection()
    }
    if (localAudioOff) {
      onDisableAudioConnection()
    }
  }, [localVideoOff, localAudioOff, onDisableVideoConnection, onDisableAudioConnection])

  return {
    error,
    audioTracks: {},
    videoTracks,
    onEnableVideoConnection,
    onDisableVideoConnection,
    onEnableAudioConnection,
    onDisableAudioConnection,
  }
}

export default useVideoParticipantsOpenTok
