import React, { useState, useCallback } from 'react'
import _get from 'lodash/get'
import { Accordion, Icon } from 'semantic-ui-react'

import useLiveGame from 'feature/Game/useLiveGame'

import TextAreaDisplay from 'component/TextAreaDisplay'
import CollapsibleContent from 'component/CollapsibleContent'

export default function GameConferenceDetails({ gameLinkId }) {
  const [videoConferenceInfoDisplayed, setVideoConferenceInfoDisplayed] = useState(true)
  const { gameData = {} } = useLiveGame({
    gameLinkId,
  })
  const supportingVideoConferenceInfo = _get(
    gameData,
    'preGameData.details.supportingVideoConferenceInfo',
  )

  const toggleVideoConferenceInfoDisplayed = useCallback(() => {
    setVideoConferenceInfoDisplayed((displayed) => !displayed)
  }, [setVideoConferenceInfoDisplayed])

  if (!supportingVideoConferenceInfo) {
    return null
  }
  return (
    <Accordion styled>
      <Accordion.Title
        active={videoConferenceInfoDisplayed}
        onClick={toggleVideoConferenceInfoDisplayed}
      >
        <Icon name="dropdown" />
        Video conference details
      </Accordion.Title>
      <CollapsibleContent collapsed={!videoConferenceInfoDisplayed}>
        <Accordion.Content active>
          <TextAreaDisplay content={supportingVideoConferenceInfo} />
        </Accordion.Content>
      </CollapsibleContent>
    </Accordion>
  )
}
