import React, { useState, useRef, useEffect } from 'react'

// TODO: make me more sophisticated as I currently on support collapsing existing
// displayed content (based on an initial height)

export default function CollapsibleContent({
  collapsed,
  children,
  transition = 'height 0.5s ease',
}) {
  const [initialHeight, setInitialHeight] = useState('inherit')
  const contentRef = useRef(null)
  useEffect(() => {
    if (contentRef.current) {
      setInitialHeight(contentRef.current.offsetHeight)
    }
  }, [contentRef, setInitialHeight])
  return (
    <div
      ref={contentRef}
      style={{
        height: collapsed ? 0 : initialHeight,
        transition,
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  )
}
