import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'

export default function ConfirmButton({
  header,
  message = 'You sure?',
  renderButton,
  renderConfirmButton,
  renderCancelButton,
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Popup
      header={header}
      trigger={renderButton()}
      content={
        <div>
          {!header && !!message && <div style={{ marginBottom: '0.5em' }}>{message}</div>}
          <div style={{ display: 'flex' }}>
            {renderCancelButton &&
              renderCancelButton({
                onCancel: () => {
                  setIsOpen(false)
                },
              })}
            {renderConfirmButton({
              closeConfirm: () => {
                setIsOpen(false)
              },
            })}
          </div>
        </div>
      }
      onOpen={() => {
        setIsOpen(true)
      }}
      open={isOpen}
      on="click"
      position="top right"
    />
  )
}
