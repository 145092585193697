import React from 'react'
import { Button } from 'semantic-ui-react'

import useUserData from 'hooks/useUserData'
import useNav from 'hooks/useNav'

import { getHostEventDetails } from 'store/App/selectors'

import { getIsWithinGamePlayTimePeriod } from 'module/game'
import { GAME_PERIOD_POST } from 'module/constants'

export default function GameLinks({ eventId }) {
  const state = useUserData()
  const { navToGame, copyGameLink } = useNav()
  const { dateTime, maxDurationMinutes, gameLinkId, addedToGamePool } =
    getHostEventDetails({
      state,
      eventId,
    }) || {}
  const { gamePeriodStatus } = getIsWithinGamePlayTimePeriod({
    dateTime,
    maxDurationMinutes,
  })
  const gameHasBeenAndGone = gamePeriodStatus === GAME_PERIOD_POST
  const showLinks = addedToGamePool && !gameHasBeenAndGone

  return (
    <>
      {showLinks && (
        <Button
          size="small"
          color="green"
          onClick={() => {
            navToGame({ gameLinkId })
          }}
        >
          Go to Quiz
        </Button>
      )}
      {showLinks && (
        <Button
          style={{ marginLeft: '0.5em' }}
          primary
          size="small"
          onClick={() => {
            copyGameLink({
              gameLinkId,
            })
          }}
        >
          Copy Join Link
        </Button>
      )}
    </>
  )
}
