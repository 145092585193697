import React, { useCallback, useState, useLayoutEffect, useRef, useMemo } from 'react'
import _get from 'lodash/get'

import './game-play.css'

import { RESPONSE_ASSESSMENT_CORRECT } from 'module/constants'

import { useLiveGameData } from 'store/LiveGame'
import {
  getOrderedPlayerData,
  getPlayerAssessmentPoints,
  getPlayerConnectionId,
  getHostConnectionData,
  getPlayerConnectionData,
  getCurrentPlayerResponseMap,
  getIsCurrentQuestionSoundtrackPlayingLocally,
} from 'feature/QuizManager/selectors'

import useAuth from 'hooks/useAuth'
import useVideoParticipantsTwilio from 'hooks/useVideoParticipantsTwilio'
import useVideoParticipantsJitsi from 'hooks/useVideoParticipantsJitsi'
import useVideoParticipantsOpenTok from 'hooks/useVideoParticipantsOpenTok'
import useInactivityDetection from 'hooks/useInactivityDetection'
import useWindowSize from 'hooks/useWindowSize'

import useLocalAudioOverride from './useLocalAudioOverride'
import useGameHostActions from './useGameHostActions'
import useGamePlayerActions from './useGamePlayerActions'

import HostCard from './HostCard'
import PlayerCard from './PlayerCard'
import LiveLeaderBoard from './LiveLeaderBoard'
import QuizbeeHelp from './QuizbeeHelp'

import InGameQuizManager from 'feature/Game/GameLive/GamePlay/InGameQuizManager'
import OopsDisplay from 'feature/ErrorBoundary/OopsDisplay'
import ContentPanel from 'component/ContentPanel'

import { VIDEO_MODE, VIDEO_INACTIVITY_TIMEOUT } from 'mode'

const minVideoWidth = 140
const maxVideoWidth = 240
const maxVideoHeight = 180
const videoHeightRatio = maxVideoHeight / maxVideoWidth

let useVideoParticipants
if (VIDEO_MODE === 'OPENTOK') {
  useVideoParticipants = useVideoParticipantsOpenTok
} else if (VIDEO_MODE === 'JITSI') {
  useVideoParticipants = useVideoParticipantsJitsi
} else if (VIDEO_MODE === 'TWILIO') {
  useVideoParticipants = useVideoParticipantsTwilio
}

export default function GamePlay({ onInactivityDetected = () => {} } = {}) {
  const playersRowRef = useRef()
  const { user = {} } = useAuth()
  const {
    gameData,
    localSoundtracksPlaying,
    onLocalSoundtrackStarted,
    onLocalSoundtrackStopped,
  } = useLiveGameData()

  useInactivityDetection({
    inActiveFor: VIDEO_INACTIVITY_TIMEOUT,
    onInactivityDetected,
  })

  const { gameLinkId, game = {}, host = {} } = gameData
  const { playerData = {} } = game
  const orderedPlayerData = getOrderedPlayerData({ gameData })
  const playerAssessmentPoints = getPlayerAssessmentPoints({ gameData })
  const isHost = user.id === host.id
  const {
    setPlayerMedia: setPlayerMediaAsHost,
    setPlayerPoints,
    setHostMedia,
    setAllPlayersMedia,
  } = useGameHostActions({ gameData })
  const { setPlayerMedia: setPlayerMediaAsPlayer } = useGamePlayerActions({
    gameData,
  })
  const onSetConnectionId = useCallback(
    ({ connectionId }) => {
      if (isHost) {
        setHostMedia({
          hostId: user.id,
          connectionId,
          audio: true,
          video: true,
        })
      } else {
        setPlayerMediaAsPlayer({
          playerId: user.id,
          connectionId,
        })
      }
    },
    [isHost, user, setHostMedia, setPlayerMediaAsPlayer],
  )

  const windowSize = useWindowSize()
  const [videoDimensionStyles, setVideoDimensionStyles] = useState()

  const {
    connectionId: hostConnectionId,
    audio: hostAudio,
    video: hostVideo,
  } = getHostConnectionData({
    gameData,
    hostId: host.id,
  })
  // const playerConnectionId = getPlayerConnectionId({ gameData, playerId })
  let videoOff = false
  let audioOff = false

  if (isHost) {
    videoOff = !hostVideo
    audioOff = !hostAudio
  } else {
    const { audio: playerAudio, video: playerVideo } = getPlayerConnectionData({
      gameData,
      playerId: user.id,
    })
    videoOff = !playerVideo
    audioOff = !playerAudio
  }

  // users should be able to turn off their local video/audio
  // without needing to wait for it to be set on the backend
  const [localVideoOff, setLocalVideoOff] = useState(videoOff)
  const [localAudioOff, setLocalAudioOff] = useState(audioOff)

  const { error, audioTracks, videoTracks } = useVideoParticipants({
    gameLinkId,
    onSetConnectionId,
    localVideoOff,
    localAudioOff,
  })
  const adjustPlayerPoints = useCallback(
    ({ playerId, direction }) => {
      if (playerData[playerId]) {
        const currentPoints = playerData[playerId].points || 0
        const points = direction === 'add' ? currentPoints + 1 : currentPoints - 1
        setPlayerPoints({
          playerId,
          points,
        })
      }
    },
    [playerData, setPlayerPoints],
  )

  const hasPlayers = Object.values(playerData).length > 0
  let allMuted = hasPlayers ? true : false
  // we have players so check if they are all muted
  Object.values(playerData).forEach(({ audio }) => {
    if (!!audio) {
      allMuted = false
    }
  })

  const numberOfPlayers = useMemo(() => {
    return orderedPlayerData.length
  }, [orderedPlayerData])

  const isCurrentQuestionSoundtrackPlayingLocally = getIsCurrentQuestionSoundtrackPlayingLocally({
    gameData,
    localSoundtracksPlaying,
  })

  useLayoutEffect(() => {
    if (playersRowRef.current && numberOfPlayers) {
      let width
      const offset = numberOfPlayers * 6 // takes in to account margin and padding on card
      const rowWidth = playersRowRef.current.offsetWidth - offset
      let maxFitWidth = 99999999
      maxFitWidth = rowWidth / numberOfPlayers
      if (maxFitWidth < maxVideoWidth) {
        if (maxFitWidth < minVideoWidth) {
          width = minVideoWidth
        } else {
          width = maxFitWidth
        }
      } else {
        width = maxVideoWidth
      }
      setVideoDimensionStyles({
        width,
        height: width * videoHeightRatio,
      })
    }
  }, [playersRowRef, setVideoDimensionStyles, numberOfPlayers, windowSize])

  useLocalAudioOverride({
    audioOff,
    localAudioOff,
    setLocalAudioOff,
    shouldOverrideAudioAsOff: isCurrentQuestionSoundtrackPlayingLocally,
  })

  if (error) {
    return <OopsDisplay />
  }

  const hostAudioTrack = audioTracks[hostConnectionId]
  const hostVideoTrack = videoTracks[hostConnectionId]

  const playerResponses = getCurrentPlayerResponseMap({
    gameData,
    includeAssessment: true,
  })

  return (
    <ContentPanel panelClassName="game-play-container-panel" fullScreenHeight>
      <QuizbeeHelp />
      <div className="game-play-host-row">
        <div className="game-play-host">
          <HostCard
            setHostMedia={setHostMedia}
            setAllPlayersMedia={setAllPlayersMedia}
            setLocalVideoOff={setLocalVideoOff}
            setLocalAudioOff={setLocalAudioOff}
            gameLinkId={gameLinkId}
            isHost={isHost}
            host={{
              ...host,
              audio: hostAudio,
              video: hostVideo,
            }}
            audioTrack={hostAudioTrack}
            videoTrack={hostVideoTrack}
            allMuted={allMuted}
            hasPlayers={hasPlayers}
            isCurrentQuestionAudioPlayingLocally={isCurrentQuestionSoundtrackPlayingLocally}
          />
        </div>

        <div className="game-play-controls-container">
          <div className="game-play-controls">
            <InGameQuizManager
              gameData={gameData}
              isHost={isHost}
              playerId={user.id}
              onLocalSoundtrackStarted={onLocalSoundtrackStarted}
              onLocalSoundtrackStopped={onLocalSoundtrackStopped}
            />
          </div>
        </div>

        <div className="game-play-leader-board">
          <LiveLeaderBoard />
        </div>
      </div>
      <div
        className="game-play-players-row"
        ref={playersRowRef}
        style={{ opacity: videoDimensionStyles ? 1 : 0 }}
      >
        {orderedPlayerData.map((player, index) => {
          const { playerId } = player
          const isMe = user.id === playerId
          const assessmentPoints = playerAssessmentPoints[playerId] || 0
          const hasResponded = _get(playerResponses, `${playerId}.hasResponded`, false)
          const hasRespondedCorrectly =
            _get(playerResponses, `${playerId}.assessment`, '') === RESPONSE_ASSESSMENT_CORRECT
          return (
            <div key={playerId} className="game-play-player">
              <PlayerCard
                videoDimensionStyles={videoDimensionStyles}
                isMe={isMe}
                isHost={isHost}
                playerId={playerId}
                player={player}
                hasResponded={isHost && hasResponded}
                hasRespondedCorrectly={hasRespondedCorrectly}
                assessmentPoints={assessmentPoints}
                audioTrack={audioTracks[getPlayerConnectionId({ gameData, playerId })]}
                videoTrack={videoTracks[getPlayerConnectionId({ gameData, playerId })]}
                isCurrentQuestionAudioPlayingLocally={isCurrentQuestionSoundtrackPlayingLocally}
                setLocalAudioOff={setLocalAudioOff}
                gamePlayerData={playerData[playerId]}
                localAudioOff={localAudioOff}
                localVideoOff={localVideoOff}
                onAddPoints={() => {
                  adjustPlayerPoints({
                    direction: 'add',
                    playerId,
                  })
                }}
                onSubtractPoints={() => {
                  adjustPlayerPoints({
                    direction: 'subtract',
                    playerId,
                  })
                }}
                onToggleVideo={(mediaActive) => {
                  if (isHost) {
                    setPlayerMediaAsHost({
                      playerId,
                      mediaType: 'video',
                      mediaActive,
                    })
                  } else if (isMe) {
                    setLocalVideoOff(!mediaActive)
                    setPlayerMediaAsPlayer({
                      playerId,
                      mediaType: 'video',
                      mediaActive,
                    })
                  }
                }}
                onToggleAudio={(mediaActive) => {
                  if (isHost) {
                    setPlayerMediaAsHost({
                      playerId,
                      mediaType: 'audio',
                      mediaActive,
                    })
                  } else if (isMe) {
                    setLocalAudioOff(!mediaActive)
                    setPlayerMediaAsPlayer({
                      playerId,
                      mediaType: 'audio',
                      mediaActive,
                    })
                  }
                }}
              />
            </div>
          )
        })}
      </div>
    </ContentPanel>
  )
}
