import React, { useCallback } from 'react'
import { Icon, Popup } from 'semantic-ui-react'

import TextAreaDisplay from 'component/TextAreaDisplay'

export default function SupportingVideoConferenceInfo({ supportingVideoConferenceInfo }) {
  const onClick = useCallback((evt) => {
    evt.preventDefault()
  }, [])
  return (
    <Popup
      wide
      on="click"
      trigger={
        <a href="#video-conference-details" onClick={onClick}>
          <div style={{ display: 'flex' }}>
            <Icon name="video" link style={{ marginRight: '1em', marginTop: 2 }} />
            <p>Video conference details</p>
          </div>
        </a>
      }
      content={<TextAreaDisplay content={supportingVideoConferenceInfo} />}
    />
  )
}
