import firebase from 'firebase'

export async function startAuthListener({ onAuthorised, onUnAuthorised }) {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      onAuthorised({
        user: {
          id: user.uid,
          name: user.displayName,
          image: user.photoURL,
        },
      })
    } else {
      onUnAuthorised()
    }
  })
}

export async function signOut({ onSuccessfulSignOut } = {}) {
  try {
    await firebase.auth().signOut()
    if (onSuccessfulSignOut) {
      onSuccessfulSignOut()
    }
  } catch (error) {
    console.error('Sign Out Error', error)
  }
}

export async function signIn({ email, password, onSuccess, onError } = {}) {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password)
    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    console.error('Sign In Error', error)
    if (onError) {
      onError({
        errorMsg: error.message,
      })
    }
  }
}

export async function signUp({ name, email, password, onSuccess, onError } = {}) {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password)
    var currentUser = firebase.auth().currentUser
    await currentUser.updateProfile({ displayName: name })
    const { uuid, displayName } = firebase.auth().currentUser
    if (onSuccess) {
      onSuccess({
        user: {
          id: uuid,
          name: displayName,
        },
      })
    }
  } catch (error) {
    console.error('Sign Up Error', error)
    if (onError) {
      onError({
        errorMsg: error.message,
      })
    }
  }
}

export function resetPassword({ onComplete } = {}) {
  var email = prompt('Please enter your email address', '')
  if (email != null) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch(() => {})
      .finally(() => {
        if (onComplete) {
          onComplete()
        }
      })
  }
}

export function signInWithProvider({ providerName = 'google', onSuccess } = {}) {
  if (providerName === 'google') {
    var provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(function (error) {
        console.error(`Error signing in with providerName: ${providerName}`, error)
      })
  }
}
