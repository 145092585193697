import React, { useCallback, useState, useEffect } from 'react'
import { Button, Loader } from 'semantic-ui-react'

import './join-game.css'

import useGameParticipation from 'feature/Game/useGameParticipation'
import useNav from 'hooks/useNav'
import ContentPanel from 'component/ContentPanel'
import RenderOnInterval from 'component/RenderOnInterval'

import { dateTimeDisplay } from 'module/dates'
import { getIsWithinGamePlayTimePeriod } from 'module/game'

import { GAME_PERIOD_POST } from 'module/constants'

import PlayerList from 'feature/Game/PlayerList'
import GameConferenceDetails from 'feature/Game/GameConferenceDetails'

export default function JoinGame({ gameLinkId, publicGameData }) {
  const [tryingToJoin, setTryingToJoin] = useState(false)
  const { navToLiveGame } = useNav()
  const { hasGameAccess, joinGame, joiningGameErrorDisplay } = useGameParticipation({
    gameLinkId,
  })
  const { details = {} } = publicGameData || {}
  const { dateTime, maxDurationMinutes } = details || {}
  const { gamePeriodStatus } = getIsWithinGamePlayTimePeriod({ dateTime, maxDurationMinutes })
  const gameHasBeenAndGone = gamePeriodStatus === GAME_PERIOD_POST

  const tryToJoinGame = useCallback(() => {
    setTryingToJoin(true)
    joinGame()
  }, [joinGame, setTryingToJoin])

  const onNavToLiveGame = useCallback(() => {
    navToLiveGame({ gameLinkId })
  }, [navToLiveGame, gameLinkId])

  const canEnterGame = useCallback(() => {
    return getIsWithinGamePlayTimePeriod({
      dateTime,
      maxDurationMinutes,
    }).isWithinGamePlayTimePeriod
  }, [dateTime, maxDurationMinutes])

  const canJoinGame = useCallback(() => {
    // TODO: check the num of players too??
    const { isWithinOrPriorToGamePlayTimePeriod } = getIsWithinGamePlayTimePeriod({
      dateTime,
      maxDurationMinutes,
    })
    return isWithinOrPriorToGamePlayTimePeriod
  }, [dateTime, maxDurationMinutes])

  useEffect(() => {
    if (!!hasGameAccess || !!joiningGameErrorDisplay) {
      setTryingToJoin(false)
    }
  }, [hasGameAccess, joiningGameErrorDisplay, setTryingToJoin])

  return (
    <ContentPanel>
      <h2>{details.name}</h2>
      <h4>
        {gameHasBeenAndGone ? '' : 'Starts on: '}
        {dateTimeDisplay({ dateTime, mode: 'friendly' })}
      </h4>
      {!!gameHasBeenAndGone && <p>Game has ended</p>}
      {!!joiningGameErrorDisplay && <div>{joiningGameErrorDisplay}</div>}
      {!!tryingToJoin && <Loader active inline />}
      {!gameHasBeenAndGone && (
        <>
          <RenderOnInterval interval={1000} test={canJoinGame}>
            {!hasGameAccess && !tryingToJoin && <Button onClick={tryToJoinGame}>Join Game</Button>}
            {!!hasGameAccess && <PlayerList gameLinkId={gameLinkId} />}
            {!!hasGameAccess && (
              <div style={{ marginTop: '0.5em' }}>
                <GameConferenceDetails gameLinkId={gameLinkId} />
              </div>
            )}
          </RenderOnInterval>
          <div style={{ marginTop: '2em' }}>
            <RenderOnInterval interval={1000} test={canEnterGame}>
              {!!hasGameAccess && (
                <Button primary onClick={onNavToLiveGame}>
                  Enter Game
                </Button>
              )}
            </RenderOnInterval>
          </div>
        </>
      )}
    </ContentPanel>
  )
}
