import { VIDEO_MODE } from 'mode'

import VideoPlayerSlaveOpenTok from './VideoPlayerSlaveOpenTok'
import VideoPlayerSlaveJitsi from './VideoPlayerSlaveJitsi'
import VideoPlayerSlaveTwilio from './VideoPlayerSlaveTwilio'

let VideoPlayerSlave

if (VIDEO_MODE === 'OPENTOK') {
  VideoPlayerSlave = VideoPlayerSlaveOpenTok
} else if (VIDEO_MODE === 'JITSI') {
  VideoPlayerSlave = VideoPlayerSlaveJitsi
} else if (VIDEO_MODE === 'TWILIO') {
  VideoPlayerSlave = VideoPlayerSlaveTwilio
}

export default VideoPlayerSlave
