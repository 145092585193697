import { useState, useCallback } from 'react'

import { getUuid } from 'module/utils'

export default function useAddEditQuestion({ answerOptions = {}, onSetAnswerOptions } = {}) {
  const [addEditQuestionModalOpen, setAddEditQuestionModalOpen] = useState(false)
  const [selectedAnswerOptionId, setSelectedAnswerOptionId] = useState('')

  const onSaveAnswerOption = useCallback(
    ({ answerOptionId: answerOptionIdProp, answerOption }) => {
      setSelectedAnswerOptionId('')
      setAddEditQuestionModalOpen(false)
      const answerOptionId = answerOptionIdProp || getUuid({ prefix: 'answer-option' })
      let updatedAnswerOptions = {}
      if (answerOption.isCorrect) {
        Object.entries(answerOptions || {}).forEach(([id, answerOption]) => {
          updatedAnswerOptions[id] = {
            ...answerOption,
            isCorrect: false,
          }
        })
      } else {
        updatedAnswerOptions = {
          ...answerOptions,
        }
      }
      updatedAnswerOptions[answerOptionId] = answerOption
      onSetAnswerOptions({
        answerOptions: updatedAnswerOptions,
      })
    },
    [answerOptions, onSetAnswerOptions, setSelectedAnswerOptionId, setAddEditQuestionModalOpen],
  )

  const onDeleteAnswerOption = useCallback(
    ({ answerOptionId }) => {
      const { [answerOptionId]: toRemove, ...remainingAnswerOptions } = answerOptions
      setSelectedAnswerOptionId('')
      setAddEditQuestionModalOpen(false)
      onSetAnswerOptions({
        answerOptions: remainingAnswerOptions,
      })
    },
    [answerOptions, onSetAnswerOptions, setSelectedAnswerOptionId, setAddEditQuestionModalOpen],
  )

  const onCancelAddEditAnswerOption = useCallback(() => {
    setSelectedAnswerOptionId('')
    setAddEditQuestionModalOpen(false)
  }, [setAddEditQuestionModalOpen])

  const onAddAnswerOption = useCallback(() => {
    setSelectedAnswerOptionId('')
    setAddEditQuestionModalOpen(true)
  }, [setSelectedAnswerOptionId, setAddEditQuestionModalOpen])

  const onEditAnswerOption = useCallback(
    ({ answerOptionId }) => {
      setSelectedAnswerOptionId(answerOptionId)
      setAddEditQuestionModalOpen(true)
    },
    [setSelectedAnswerOptionId, setAddEditQuestionModalOpen],
  )

  return {
    addEditQuestionModalOpen,
    selectedAnswerOptionId,
    onAddAnswerOption,
    onDeleteAnswerOption,
    onSaveAnswerOption,
    onCancelAddEditAnswerOption,
    onEditAnswerOption,
  }
}
