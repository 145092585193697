import moment from 'moment'

import { GAME_PERIOD_WITHIN, GAME_PERIOD_PRIOR, GAME_PERIOD_POST } from 'module/constants'

export function getIsWithinGamePlayTimePeriod({ dateTime, maxDurationMinutes }) {
  let gamePeriodStatus = GAME_PERIOD_WITHIN
  if (!maxDurationMinutes) {
    console.warn('No maxDurationMinutes provided')
    return {
      isWithinGamePlayTimePeriod: false,
      gamePeriodStatus: GAME_PERIOD_POST,
    }
  }
  const now = new Date()
  const endTime = moment(dateTime).add(maxDurationMinutes, 'm')
  if (!moment(now).isAfter(dateTime)) {
    gamePeriodStatus = GAME_PERIOD_PRIOR
  }
  if (moment(now).isAfter(endTime)) {
    gamePeriodStatus = GAME_PERIOD_POST
  }
  return {
    isWithinGamePlayTimePeriod: gamePeriodStatus === GAME_PERIOD_WITHIN,
    isWithinOrPriorToGamePlayTimePeriod:
      gamePeriodStatus === GAME_PERIOD_WITHIN || gamePeriodStatus === GAME_PERIOD_PRIOR,
    gamePeriodStatus,
  }
}
