import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'

export default function LabelInfoPopup({ content, renderContent }) {
  return (
    <Popup trigger={<Icon name="question circle" link />} on="click" hideOnScroll wide>
      {!!content && content}
      {!!renderContent && renderContent()}
    </Popup>
  )
}
