import { useEffect, useRef, useCallback } from 'react'

const useInterval = ({ callback, delay = 100, autoStart = true }) => {
  const savedCallback = useRef()
  const intervalRef = useRef()

  const start = useCallback(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      intervalRef.current = setInterval(tick, delay)
      return () => clearInterval(intervalRef.current)
    }
    return () => {}
  }, [delay])

  const stop = useCallback(() => {
    clearInterval(intervalRef.current)
  }, [intervalRef])

  useEffect(() => {
    savedCallback.current = callback
    if (autoStart) {
      start()
    }
    return stop
  }, [callback, autoStart, start, stop])

  return {
    start,
    stop,
  }
}

export default useInterval
