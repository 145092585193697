import { useRef, useEffect } from 'react'

export default function useLocalAudioOverride({
  audioOff,
  localAudioOff,
  setLocalAudioOff,
  shouldOverrideAudioAsOff,
}) {
  const shouldOverrideAudioAsOffValueCache = useRef(shouldOverrideAudioAsOff)
  useEffect(() => {
    if (shouldOverrideAudioAsOff !== shouldOverrideAudioAsOffValueCache.current) {
      shouldOverrideAudioAsOffValueCache.current = shouldOverrideAudioAsOff
      if (shouldOverrideAudioAsOff) {
        setLocalAudioOff(true)
      } else {
        setLocalAudioOff(audioOff)
      }
    }
  }, [
    audioOff,
    localAudioOff,
    setLocalAudioOff,
    shouldOverrideAudioAsOffValueCache,
    shouldOverrideAudioAsOff,
  ])
  return {}
}
