import React, { useState, useEffect, useCallback } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

import './date-picker-style-overrides.css'

import { getUuid } from 'module/utils'
import { getLocaleDateFormat } from 'module/dates'

const displayFormat = getLocaleDateFormat()

// NOTE we assume date strings are of format 'YYYY-MM-DD'

export default function DatePicker({
  id = getUuid({ prefix: 'date-picker' }),
  initialDateString = moment().format('YYYY-MM-DD'),
  onDateChanged,
  error = false,
  disabled = false,
} = {}) {
  const [initialised, setInitialised] = useState()
  const [eventMomentDate, setEventMoment] = useState(null)
  const [datePickerFocused, setDatePickerFocused] = useState(false)

  const dateChanged = useCallback(
    (momentDate) => {
      setEventMoment(momentDate)
      if (onDateChanged) {
        if (momentDate) {
          onDateChanged(momentDate.format('YYYY-MM-DD'))
        } else {
          onDateChanged('')
        }
      }
    },
    [onDateChanged],
  )

  const onFocusChange = ({ focused }) => {
    setDatePickerFocused(focused)
  }

  useEffect(() => {
    if (initialDateString && !initialised) {
      setInitialised(true)
      dateChanged(moment(initialDateString))
    }
  }, [initialDateString, initialised, dateChanged])

  const derivedClassNames = error ? 'error field' : ''

  return (
    <div className={derivedClassNames}>
      <SingleDatePicker
        id={id}
        date={eventMomentDate}
        onDateChange={dateChanged}
        focused={datePickerFocused}
        onFocusChange={onFocusChange}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        displayFormat={displayFormat}
        disabled={disabled}
      />
    </div>
  )
}
