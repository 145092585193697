import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

const cow = require('images/avatars/cow.svg')
const chameleon = require('images/avatars/chameleon.svg')
const elephant = require('images/avatars/elephant.svg')
const frog = require('images/avatars/frog.svg')
const giraffe = require('images/avatars/giraffe.svg')
const hippo = require('images/avatars/hippo.svg')
const jellyfish = require('images/avatars/jellyfish.svg')
const monkey = require('images/avatars/monkey.svg')
const panda = require('images/avatars/panda.svg')
const penguin = require('images/avatars/penguin.svg')
const pig = require('images/avatars/pig.svg')
const sloth = require('images/avatars/sloth.svg')

export const orderedAvatars = [
  cow,
  sloth,
  hippo,
  jellyfish,
  chameleon,
  elephant,
  frog,
  giraffe,
  monkey,
  panda,
  penguin,
  pig,
]

export const getPlayerData = ({ gameData }) => {
  return _get(gameData, `players`, {})
}

export const getPlayerGameData = ({ gameData }) => {
  return _get(gameData, `game.playerData`, {})
}

export const getOrderedPlayerData = ({ gameData }) => {
  const playerGameData = getPlayerGameData({ gameData })
  const playerData = getPlayerData({ gameData })
  const participantConnectionData = _get(gameData, 'public.participantConnections', {})
  const orderedPlayerData = _orderBy(
    Object.entries(playerGameData).map(([playerId, { playerOrder }]) => {
      const thisPlayerData = playerData[playerId] || {}
      const thisPlayerConnection = participantConnectionData[playerId] || {}
      return {
        playerId,
        playerOrder,
        ...thisPlayerData,
        ...thisPlayerConnection,
      }
    }),
    ['playerOrder', 'name'],
    ['asc', 'asc'],
  )
  return orderedPlayerData.map((player, index) => ({
    ...player,
    image: getPlayerImage({ gameData, index }),
  }))
}

export const getPlayerImage = ({ gameData, playerId, index = -1 }) => {
  let playerOrderIndex = index
  if (playerOrderIndex < 0) {
    const orderedPlayerData = getOrderedPlayerData({
      gameData,
    })
    orderedPlayerData.forEach((player, index) => {
      if (playerId === player.playerId) {
        playerOrderIndex = index
      }
    })
  }

  const avatar = orderedAvatars[playerOrderIndex]
  if (avatar) {
    return orderedAvatars[playerOrderIndex]
    // return `https://react.semantic-ui.com/images/avatar/large/${imageName}`
  } else {
    console.warn(`** we don't have enough images!!`)
    return ''
  }
}
