import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import merge from 'deepmerge'

import {
  ROUND_STATUS_COMPLETED,
  ROUND_STATUS_PENDING,
  ROUND_STATUS_IN_PROGRESS,
  ROUND_STATUS_PAUSED,
  RESPONSE_ASSESSMENT_CORRECT,
  RESPONSE_TIMING_EVERYONE,
  ROUND_DEFAULT_DATA,
} from 'module/constants'

import { questionTypeLookup, responseTimingDisplayLookup } from 'module/lookups'

import { getPlayerGameData, getPlayerData, getPlayerImage } from 'feature/QuizManager/selectors'

export function getRounds({ gameData }) {
  return _get(gameData, `game.rounds`, {})
}

export function getRound({ gameData, roundId }) {
  const roundData = _get(gameData, `game.rounds.${roundId}`, ROUND_DEFAULT_DATA)
  return merge(ROUND_DEFAULT_DATA, roundData)
}

export function getExistingRoundCount({ gameData }) {
  return Object.keys(_get(gameData, `game.rounds`, {})).length
}

export function getNextRoundOrderNum({ gameData }) {
  let nextRoundOrderNum = 0
  Object.values(getRounds({ gameData })).forEach(({ order }) => {
    // the "next" round order number will be 1 greater than
    // the largest existing round order number
    if (nextRoundOrderNum <= order) {
      nextRoundOrderNum = order + 1
    }
  })
  return nextRoundOrderNum
}

export function getHasEditableRounds({ gameData }) {
  let hasEditableRounds = false
  const rounds = _get(gameData, `game.rounds`, {})
  Object.values(rounds).forEach(({ status }) => {
    if (
      status === ROUND_STATUS_PENDING ||
      status === ROUND_STATUS_IN_PROGRESS ||
      status === ROUND_STATUS_PAUSED
    ) {
      hasEditableRounds = true
    }
  })
  return hasEditableRounds
}

export const getIsHost = ({ userId, gameData }) => {
  return _get(gameData, 'public.hostId', '') === userId
}

export const getGameState = ({ gameData }) => {
  return _get(gameData, `game`, {})
}

export const getCurrentRoundId = ({ gameData }) => {
  return _get(gameData, 'game.control.activeRoundId', '')
}

export const getCurrentRound = ({ gameData }) => {
  const currentRoundId = getCurrentRoundId({ gameData })
  const activeRound = _get(gameData, `game.rounds.${currentRoundId}`, {})
  return {
    id: currentRoundId,
    ...activeRound,
  }
}

export const getRoundTitle = ({ gameData }) => {
  return getCurrentRound({ gameData }).roundName
}

export const getQuestionTypeDisplay = ({ gameData }) => {
  const { responseTiming } = getCurrentRound({ gameData })
  const { questionType } = getCurrentQuestion({ gameData })
  const questionTypeDisplay = questionTypeLookup[questionType]
  let responseTypeDisplay = ''
  if (responseTiming !== RESPONSE_TIMING_EVERYONE) {
    if (responseTimingDisplayLookup[responseTiming]) {
      responseTypeDisplay = ` - ${responseTimingDisplayLookup[responseTiming]}`
    }
  }
  return `${questionTypeDisplay}${responseTypeDisplay}`
}

export const getIsStarted = ({ gameData }) => {
  return _get(gameData, 'game.control.started', false)
}

export const getCurrentQuestion = ({ gameData }) => {
  const currentRound = getCurrentRound({ gameData })
  const activeQuestionId = currentRound.activeQuestionId
  return _get(currentRound, `questions.${activeQuestionId}`, {})
}

export const getCurrentQuestionId = ({ gameData }) => {
  const currentRound = getCurrentRound({ gameData })
  return currentRound.activeQuestionId
}

export const getCorrectAnswerOptionIdForCurrentQuestion = ({ gameData }) => {
  const { answerOptions = {} } = getCurrentQuestion({ gameData })
  const correctAnswerOptionId =
    Object.entries(answerOptions)
      .filter(([optionId, { isCorrect }]) => !!isCorrect)
      .map(([id]) => id)[0] || ''
  return correctAnswerOptionId
}

export const getCurrentPlayerAssessments = ({ gameData }) => {
  const { responseAssessments = {} } = getCurrentRound({ gameData })
  return responseAssessments || {}
}

export const getCurrentQuestionPlayerAssessments = ({ gameData }) => {
  const { responseAssessments = {} } = getCurrentRound({ gameData })
  const currentQuestionId = getCurrentQuestionId({ gameData })
  return responseAssessments[currentQuestionId] || {}
}

export const getCurrentPlayerResponseMap = ({ gameData, includeAssessment = false }) => {
  const players = gameData.players || {}
  const playerResponses = {}
  const currentRoundId = getCurrentRoundId({ gameData })
  const currentQuestionId = getCurrentQuestionId({ gameData })
  Object.entries(players).forEach(([playerId, player]) => {
    const response = _get(player, `responses.${currentRoundId}.${currentQuestionId}`, {})
    playerResponses[playerId] = {
      playerId,
      playerName: player.name,
      hasResponded: !!Object.keys(response).length,
      timeStamp: response.timeStamp,
      pass: response.pass,
      answerText: response.answerText,
      answerOptionId: response.answerOptionId,
    }
    if (includeAssessment) {
      const currentQuestionPlayerAssessments = getCurrentQuestionPlayerAssessments({ gameData })
      playerResponses[playerId].assessment = currentQuestionPlayerAssessments[playerId]
    }
  })
  return playerResponses
}

export const getCurrentPlayerResponses = ({ gameData }) => {
  const playerResponses = getCurrentPlayerResponseMap({ gameData })
  return _orderBy(playerResponses, ['timeStamp'], ['asc'])
}

export const getOrderedRoundQuestions = ({ gameData, roundId }) => {
  // order by order attribute, then alphabetically (in case no order set)
  // and augment round with it's id
  const questions = _get(gameData, `game.rounds.${roundId}.questions`, {})
  const orderedQuestions = _orderBy(
    Object.entries(questions).map(([id, question]) => ({
      id,
      ...question,
    })),
    ['order', 'name'],
    ['asc', 'asc'],
  )
  return orderedQuestions
}

export const getRoundProgressDisplay = ({ gameData }) => {
  const { id: roundId, questionRevealed } = getCurrentRound({ gameData })
  const currentQuestionId = getCurrentQuestionId({ gameData })
  const orderedRoundQuestions = getOrderedRoundQuestions({ gameData, roundId })
  if (!currentQuestionId) {
    return ''
  } else {
    let thisRoundQuestionNum = 1
    orderedRoundQuestions.forEach(({ id }, index) => {
      if (id === currentQuestionId) {
        thisRoundQuestionNum = index + 1
      }
    })
    const totalNumOfQuestions = orderedRoundQuestions.length
    // only show this when question is revealed
    if (!questionRevealed) {
      return ''
    }
    return `(Q${thisRoundQuestionNum} of ${totalNumOfQuestions})`
  }
}

export const getNextQuestionId = ({ gameData }) => {
  let nextQuestionId
  let activeQuestionIdFound
  const { id: roundId, activeQuestionId } = getCurrentRound({ gameData })
  const orderedRoundQuestions = getOrderedRoundQuestions({
    gameData,
    roundId,
  })
  if (!activeQuestionId && orderedRoundQuestions.length) {
    nextQuestionId = orderedRoundQuestions[0].id
  } else {
    orderedRoundQuestions.forEach(({ id: questionId }) => {
      if (questionId === activeQuestionId) {
        activeQuestionIdFound = true
      } else if (activeQuestionIdFound && !nextQuestionId) {
        nextQuestionId = questionId
      }
    })
  }
  return nextQuestionId
}

export const getOrderedRounds = ({ gameData }) => {
  // order by order attribute, then alphabetically (in case no order set)
  // and augment round with it's id
  const rounds = _get(gameData, `game.rounds`, {})
  const orderedRounds = _orderBy(
    Object.entries(rounds).map(([id, round]) => ({
      id,
      ...round,
    })),
    ['order', 'name'],
    ['asc', 'asc'],
  )
  return orderedRounds
}

export const getRoundIdToStart = ({ gameData }) => {
  // return the next round id for a given quiz that can be started
  // note: we do NOT return any round id if an existing round is in progress
  let roundIdToStart
  let hasInProgressRounds
  const orderedRounds = getOrderedRounds({ gameData })
  orderedRounds.forEach(({ id, status }) => {
    if (status === ROUND_STATUS_IN_PROGRESS || status === ROUND_STATUS_PAUSED) {
      hasInProgressRounds = true
    } else if (!roundIdToStart && status === ROUND_STATUS_PENDING) {
      roundIdToStart = id
    }
  })
  if (!hasInProgressRounds && roundIdToStart) {
    return roundIdToStart
  }
  return ''
}

export const getBumpRoundOrderUpdates = ({ roundId, direction, gameData }) => {
  const orderedRounds = getOrderedRounds({ gameData })
  // kinda crude but might just work
  const updatedRounds = {}
  let thisRound
  let roundBefore
  let roundAfter
  orderedRounds.forEach((round) => {
    if (round.id === roundId) {
      thisRound = round
    }
    if (!thisRound) {
      roundBefore = round
    }
    if (thisRound && !roundAfter && round.id !== roundId) {
      roundAfter = round
    }
  })
  if (thisRound) {
    const { id: thisRoundId, ...thisRoundData } = thisRound
    const thisRoundOrder = thisRoundData.order
    // we have a matching round - woo
    if (direction === 'up') {
      // move this round up, by switching order number with
      // the roundBefore
      if (roundBefore) {
        const { id: roundBeforeId, ...roundBeforeData } = roundBefore
        const roundBeforeOrder = roundBeforeData.order
        updatedRounds[thisRoundId] = {
          ...thisRoundData,
          order: roundBeforeOrder,
        }
        updatedRounds[roundBeforeId] = {
          ...roundBeforeData,
          order: thisRoundOrder,
        }
        if (roundBeforeOrder === thisRoundOrder) {
          updatedRounds[thisRoundId].order = updatedRounds[thisRoundId].order - 0.1
        }
      }
    }
    if (direction === 'down') {
      // move this round down, by switching order number with
      // the roundAfter
      if (roundAfter) {
        const { id: roundAfterId, ...roundAfterData } = roundAfter
        const roundAfterOrder = roundAfterData.order
        updatedRounds[thisRoundId] = {
          ...thisRoundData,
          order: roundAfterOrder,
        }
        updatedRounds[roundAfterId] = {
          ...roundAfterData,
          order: thisRoundOrder,
        }
        if (roundAfterOrder === thisRoundOrder) {
          updatedRounds[thisRoundId].order = updatedRounds[thisRoundId].order + 0.1
        }
      }
    }
  }
  // if any of the rounds involved in the update are complete or in progress
  // then DO NOT UPDATE
  if (
    Object.values(updatedRounds).filter(
      ({ status }) =>
        status === ROUND_STATUS_COMPLETED ||
        status === ROUND_STATUS_IN_PROGRESS ||
        status === ROUND_STATUS_PAUSED,
    ).length
  ) {
    return {}
  }
  return updatedRounds
}

export const getHasQuizEnded = ({ gameData }) => {
  return _get(gameData, `game.control.ended`, false)
}

export const getPlayerAssessmentPoints = ({ gameData }) => {
  const playerAssessmentPoints = {}
  const rounds = getRounds({ gameData })
  Object.values(rounds).forEach(({ pointsPerQuestion, responseAssessments = {} }) => {
    Object.values(responseAssessments).forEach((questionAssessment) => {
      Object.entries(questionAssessment).forEach(([playerId, assessment]) => {
        if (assessment === RESPONSE_ASSESSMENT_CORRECT) {
          playerAssessmentPoints[playerId] = playerAssessmentPoints[playerId] || 0
          playerAssessmentPoints[playerId] += pointsPerQuestion
        }
      })
    })
  })
  return playerAssessmentPoints
}

export const getLeaderBoardData = ({ gameData }) => {
  const playerGameData = getPlayerGameData({ gameData })
  const playerData = getPlayerData({ gameData })
  const playerAssessmentPoints = getPlayerAssessmentPoints({ gameData })
  const leaderBoardPlayerData = []

  // we combine assessment points with player (bonus) points
  Object.entries(playerGameData).forEach(([playerId, { points = 0 }]) => {
    const assessmentPoints = playerAssessmentPoints[playerId] || 0
    const totalPoints = points + assessmentPoints
    if (playerData[playerId]) {
      leaderBoardPlayerData.push({
        playerId,
        name: playerData[playerId].name,
        // image: playerData[playerId].image || getPlayerImage({ gameData, playerId }),
        image: getPlayerImage({ gameData, playerId }),
        points: totalPoints,
        bonusPoints: points,
        assessmentPoints,
      })
    }
  })

  return _orderBy(leaderBoardPlayerData, ['points', 'name'], ['desc', 'asc'])
}

export const getQuizWinners = ({ gameData }) => {
  const winners = []
  const leaderBoardData = getLeaderBoardData({ gameData })
  if (leaderBoardData[0]) {
    const topScore = leaderBoardData[0].points
    leaderBoardData.forEach((player) => {
      if (player.points === topScore) {
        winners.push(player)
      }
    })
  }
  return winners
}

export const getPlayerConnectionId = ({ gameData, playerId }) => {
  return _get(gameData, `public.participantConnections.${playerId}.connectionId`, '')
}

export const getHostConnectionData = ({ gameData, hostId }) => {
  return _get(gameData, `public.participantConnections.${hostId}`, {})
}

export const getPlayerConnectionData = ({ gameData, playerId }) => {
  return _get(gameData, `public.participantConnections.${playerId}`, {})
}

export const getSupportingVideoConferenceDetails = ({ gameData }) => {
  return {
    supportingVideoConference: _get(
      gameData,
      `preGameData.details.supportingVideoConference`,
      'no',
    ),
    supportingVideoConferenceInfo: _get(
      gameData,
      `preGameData.details.supportingVideoConferenceInfo`,
      '',
    ),
  }
}

export const getIsCurrentQuestionSoundtrackPlayingLocally = ({
  gameData,
  localSoundtracksPlaying,
}) => {
  const currentQuestion =
    getCurrentQuestion({
      gameData,
    }) || {}
  const { questionAudio } = currentQuestion
  return !!localSoundtracksPlaying[questionAudio]
}
