import firebase from 'firebase'
import _get from 'lodash/get'

import { firebaseUpdate } from 'api/firebaseUtils'
import { firebaseCallFunction } from 'firebaseInit'

import {
  GAME_DATA_STATUS_EXISTS,
  GAME_DATA_STATUS_NOT_FOUND,
  GAME_START_RESULT_SUCCESS,
  LIVE_GAME_STATUS_IN_PROGRESS,
} from 'api/apiConstants'

import {
  ROUND_STATUS_IN_PROGRESS,
  ROUND_STATUS_PAUSED,
  ROUND_STATUS_COMPLETED,
} from 'module/constants'

let gameListener

let preGameDataListener
let publicDataListener
let publicDataListenerCallback

let preGameDataListenerCallback
let publicGameDataListener
let publicGameDataListenerCallback

export async function getPublicGameData({ gameLinkId, onSuccess, onError }) {
  try {
    const path = `/games/${gameLinkId}/public`
    const snapshot = await firebase.database().ref(path).once('value')
    const data = {}
    if (snapshot && !!snapshot.val()) {
      data.status = GAME_DATA_STATUS_EXISTS
      data.details = snapshot.val()
    } else {
      data.status = GAME_DATA_STATUS_NOT_FOUND
    }
    if (onSuccess) {
      onSuccess({
        data,
      })
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export function connectToPublicGameData({ gameLinkId, onUpdate }) {
  console.log('** connectToPublicGameData **', { gameLinkId })
  if (!publicGameDataListener) {
    const path = `/games/${gameLinkId}/public`
    publicGameDataListener = firebase.database().ref(path)
    publicGameDataListenerCallback = (snapshot) => {
      const publicData = snapshot.val() || {}
      onUpdate({ publicData })
    }
    publicGameDataListener.on('value', publicGameDataListenerCallback)
  }
}

export function disconnectFromPublicGameData({ gameLinkId }) {
  console.log('** disconnectFromPublicGameData **', { gameLinkId })
  if (publicGameDataListener) {
    publicGameDataListener.off('value', publicGameDataListenerCallback)
    publicGameDataListener = undefined
    publicGameDataListenerCallback = undefined
  }
}

export async function getIsGameParticipant({ gameLinkId, onSuccess, onError }) {
  try {
    const { data } = await firebaseCallFunction('getIsGameParticipant')({
      gameLinkId,
    })
    const { status, ...otherData } = data || {}
    if (status === 'success') {
      if (onSuccess) {
        onSuccess({
          data: otherData,
        })
      }
    } else {
      console.error('** non success status returned from getIsGameParticipant API')
      if (onError) {
        onError()
      }
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export async function joinGame({ gameLinkId, onSuccess, onError }) {
  try {
    const user = firebase.auth().currentUser
    const { data } = await firebaseCallFunction('joinGame')({
      gameLinkId,
      userDisplayName: user.displayName,
    })
    const { status, ...otherData } = data || {}
    if (status === 'success') {
      if (onSuccess) {
        onSuccess({
          data: otherData,
        })
      }
    } else {
      console.error('** non success status returned from joinGame API')
      if (onError) {
        onError()
      }
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export async function startGame({ gameLinkId, onSuccess, onError }) {
  try {
    const user = firebase.auth().currentUser
    const { data } = await firebaseCallFunction('startGame')({
      gameLinkId,
      userDisplayName: user.displayName,
    })
    const { status, ...otherData } = data || {}
    if (status === 'success') {
      if (onSuccess) {
        onSuccess({
          data: otherData,
        })
      }
    } else {
      console.error('** non success status returned from startGame API')
      if (onError) {
        onError()
      }
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export async function switchRoomConnection({
  gameLinkId,
  videoMode,
  connectionType,
  onSuccess,
  onError,
}) {
  try {
    const { data } = await firebaseCallFunction('switchRoomConnection')({
      gameLinkId,
      videoMode,
      connectionType,
    })
    const { status, ...otherData } = data || {}
    if (status === 'success') {
      if (onSuccess) {
        onSuccess({
          data: otherData,
        })
      }
    } else {
      console.error('** non success status returned from switchRoomConnection API')
      if (onError) {
        onError()
      }
    }
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

function sanitizedGameDataForPlayers({ gameData: gameDataProp = {}, playerId }) {
  const gameData = JSON.parse(JSON.stringify(gameDataProp))
  const { game = {}, players } = gameData
  const { control = {}, rounds = {} } = game
  const { activeRoundId } = control
  let hasPlayerRespondedToActiveQuestion = false

  // ***************************
  // *** sanitize round data ***
  // ***************************
  // 1) only include completed, paused or in-progress rounds
  // 2) only include previous questions and current question (if revealed) for the active round
  // 2a) show only the question type for current question if not yet revealed
  // 3) only include the answer for current question if this player has responded
  const sanitizedRoundsData = {}
  // 1) only include completed, paused or in-progress rounds
  Object.entries(rounds).forEach(([roundId, round]) => {
    if (
      [ROUND_STATUS_IN_PROGRESS, ROUND_STATUS_PAUSED, ROUND_STATUS_COMPLETED].indexOf(
        round.status,
      ) > -1
    ) {
      sanitizedRoundsData[roundId] = round
    }
  })
  // 2) only include previous and current questions for the active round
  if (sanitizedRoundsData[activeRoundId]) {
    const { activeQuestionId, questions, questionRevealed } = sanitizedRoundsData[activeRoundId]
    const activeRoundQuestions = {}
    const activeRoundQuestion = questions[activeQuestionId]
    if (activeRoundQuestion) {
      const { order: activeRoundQuestionOrderNumber } = activeRoundQuestion
      if (questionRevealed) {
        hasPlayerRespondedToActiveQuestion = !!_get(
          players,
          `${playerId}.responses.${activeRoundId}.${activeQuestionId}`,
        )
      }
      Object.entries(questions).forEach(([questionId, question]) => {
        if (question.order <= activeRoundQuestionOrderNumber) {
          activeRoundQuestions[questionId] = question
          if (!questionRevealed && questionId === activeQuestionId) {
            // 2a) show only the question type for current question if not yet revealed
            activeRoundQuestions[questionId] = {
              questionType: activeRoundQuestions[questionId].questionType,
            }
          } else if (questionId === activeQuestionId && !hasPlayerRespondedToActiveQuestion) {
            // 3) strip out answer for active question if player has not responded yet
            delete activeRoundQuestions[questionId].answerText
            if (question.answerOptions) {
              Object.keys(question.answerOptions).forEach((optionId) => {
                delete activeRoundQuestions[questionId].answerOptions[optionId].isCorrect
              })
            }
          }
        } else {
          // we pass through an 'empty' question (as this is used to display how many questions there are in the round)
          activeRoundQuestions[questionId] = {
            order: question.order,
          }
        }
      })
    }
    sanitizedRoundsData[activeRoundId] = {
      ...sanitizedRoundsData[activeRoundId],
      questions: activeRoundQuestions,
    }
  }

  // ****************************
  // *** sanitize player data ***
  // ****************************
  // 1) only include other player responses if this user has answered the current question
  let sanitizedPlayersData = {}
  if (hasPlayerRespondedToActiveQuestion) {
    sanitizedPlayersData = players
  } else {
    // strip out response data for other players
    Object.entries(players).forEach(([id, playerData]) => {
      sanitizedPlayersData[id] = playerData
      if (playerId !== id) {
        delete sanitizedPlayersData[id].responses
      }
    })
  }

  // strip out some stuff we definitely don't need to duplicate
  const { public: publicData, session, ...remainingGameData } = gameData
  return {
    ...remainingGameData,
    game: {
      ...gameData.game,
      rounds: sanitizedRoundsData,
    },
    players: sanitizedPlayersData,
  }
}

async function updateGamePlayData({ gameLinkId, gameData = {} }) {
  // bit of a mega hack, but we keep player specific copies of the main game data
  // so that we can hide things they shouldn't be able to see
  // TODO: make this better
  const { players = {} } = gameData
  const gamePlayUpdates = {}
  Object.keys(players).forEach((playerId) => {
    gamePlayUpdates[playerId] = sanitizedGameDataForPlayers({ gameData, playerId })
  })
  firebaseUpdate({
    path: `/gamePlay/${gameLinkId}`,
    data: gamePlayUpdates,
  })
}

async function connectToGameAsHost({ gameLinkId, onUpdate }) {
  console.log('*** connect to game as host ****')
  const gamePath = `/games/${gameLinkId}`
  // start a listener on root game data
  gameListener = firebase.database().ref(gamePath)
  gameListener.on('value', (snapshot) => {
    const gameData = snapshot.val() || {}
    onUpdate({ gameData })
    updateGamePlayData({
      gameLinkId,
      gameData,
    })
  })
}

async function connectToGameAsPlayer({ userId, gameLinkId, onUpdate }) {
  console.log('*** connect to game as player ****')
  // set up listeners
  // 1 - pre game data
  // 2 - for public game data (eg participant connections)
  // 3 - the game link data copy (which is on a slight delay)

  if (!preGameDataListener) {
    const path = `/games/${gameLinkId}/preGameData`
    preGameDataListener = firebase.database().ref(path)
    preGameDataListenerCallback = async (snapshot) => {
      const preGameData = snapshot.val() || {}
      onUpdate({
        setGameDataCallback: (existingGameData) => {
          return {
            gameLinkId,
            ...existingGameData,
            preGameData,
          }
        },
      })
    }
    preGameDataListener.on('value', preGameDataListenerCallback)
  }

  if (!publicDataListener) {
    const path = `/games/${gameLinkId}/public`
    publicDataListener = firebase.database().ref(path)
    publicDataListenerCallback = async (snapshot) => {
      const publicData = snapshot.val() || {}
      onUpdate({
        setGameDataCallback: (existingGameData) => {
          return {
            gameLinkId,
            ...existingGameData,
            public: publicData,
          }
        },
      })
    }
    publicDataListener.on('value', publicDataListenerCallback)
  }

  // we keep player specific copies of the game data so we can control what data
  // they are able to see at any one time
  // TODO: make this better
  const path = `/gamePlay/${gameLinkId}/${userId}`
  const existingData = await firebase.database().ref(path).once('value')
  if (!existingData || !existingData.val()) {
    await firebaseUpdate({
      path,
      data: {
        listenerPlaceholder: 'init',
      },
    })
  }
  gameListener = firebase.database().ref(path)
  gameListener.on('value', (snapshot) => {
    const updatedGameData = snapshot.val() || {}
    // we preserve any existing public game data - as that has a direct data listener
    // (rather than from the game link data copy - so that we don't need to rely on the host being
    // in the session for controlling participant connections, game status etc)
    const { public: publicData, ...remainingUpdatedGameData } = updatedGameData
    onUpdate({
      setGameDataCallback: (existingGameData) => {
        return {
          gameLinkId,
          ...existingGameData,
          ...remainingUpdatedGameData,
        }
      },
    })
  })
}

export async function connectToGame({ gameLinkId, onUpdate }) {
  try {
    if (!gameListener) {
      console.log('** connectToGame - started **', { gameLinkId })
      const path = `/games/${gameLinkId}/public`
      const snapshot = await firebase.database().ref(path).once('value')
      const { hostId } = snapshot.val() || {}
      const userId = firebase.auth().currentUser.uid
      if (userId === hostId) {
        console.log('** connectToGame - as HOST **')
        connectToGameAsHost({
          gameLinkId,
          onUpdate,
        })
      } else {
        console.log('** connectToGame - as PLAYER **')
        connectToGameAsPlayer({
          userId,
          gameLinkId,
          onUpdate,
        })
      }
    } else {
      console.log('** connectToGame - already started **', { gameLinkId })
    }
  } catch (error) {
    console.error(error)
  }
}

export async function disconnectFromGame({ gameLinkId }) {
  if (gameListener) {
    console.log('** disconnectFromGame - gameListener **', { gameLinkId })
    gameListener.off()
    gameListener = undefined
  }
  if (publicDataListener) {
    console.log('** disconnectFromGame - publicDataListener **', { gameLinkId })
    publicDataListener.off('value', publicDataListenerCallback)
    publicDataListener = undefined
    publicDataListenerCallback = undefined
  }
  if (preGameDataListener) {
    preGameDataListener.off('value', preGameDataListenerCallback)
    preGameDataListener = undefined
    preGameDataListenerCallback = undefined
  }
}

export async function playerAction({ gameLinkId, action }) {
  console.log('** playerAction **', { action })
  const { type } = action
  switch (type) {
    case 'setPlayerMedia':
      {
        const { playerId, mediaType, mediaActive, connectionId } = action
        const path = `/games/${gameLinkId}/public/participantConnections/${playerId}`
        const newData = {}
        if (connectionId) {
          newData.connectionId = connectionId
        }
        if (mediaType) {
          newData[mediaType] = !!mediaActive
        }
        await firebaseUpdate({
          path,
          data: newData,
        })
      }
      break

    case 'respondToQuestion':
      {
        const { playerId, roundId, questionId, response } = action
        const path = `/games/${gameLinkId}/players/${playerId}/responses/${roundId}/${questionId}`
        await firebaseUpdate({
          path,
          data: response,
        })
      }
      break

    default:
      console.warn(`API playerAction not supported: ${type}`)
      break
  }
}

export async function hostAction({ gameLinkId, action = {} } = {}) {
  console.log('** hostAction **', { action })
  const { type } = action
  switch (type) {
    case 'overridePlayerMedia':
      {
        const { playerId, mediaType, mediaActive } = action
        const path = `/games/${gameLinkId}/game/playerData/${playerId}`
        await firebaseUpdate({
          path,
          data: {
            [mediaType]: !!mediaActive,
          },
        })
      }
      break

    case 'setHostMedia':
      {
        const { mediaType, mediaActive, connectionId, hostId } = action
        const path = `/games/${gameLinkId}/public/participantConnections/${hostId}`
        const newData = {}
        if (connectionId) {
          newData.connectionId = connectionId
        }
        if (mediaType) {
          newData[mediaType] = !!mediaActive
        }
        await firebaseUpdate({
          path,
          data: newData,
        })
      }
      break

    case 'setPlayerPoints':
      {
        const { playerId, points = 0 } = action
        const path = `/games/${gameLinkId}/game/playerData/${playerId}`
        await firebaseUpdate({
          path,
          data: {
            points,
          },
        })
      }

      break

    case 'setAllPlayersMedia':
      {
        const { mediaType, mediaActive } = action
        const path = `/games/${gameLinkId}/game/playerData`
        const allPlayersSnapshot = await firebase.database().ref(path).once('value')
        const allPlayers = allPlayersSnapshot.val() || {}
        const updates = {}
        Object.keys(allPlayers).forEach((playerId) => {
          updates[`/games/${gameLinkId}/game/playerData/${playerId}/${mediaType}`] = mediaActive
        })
        if (Object.keys(updates).length) {
          await firebaseUpdate({
            data: updates,
          })
        }
      }
      break

    case 'setGameState':
      {
        const { state } = action
        const path = `/games/${gameLinkId}/game`
        await firebaseUpdate({
          path,
          data: state,
        })
      }
      break

    case 'deleteRound':
      {
        const { roundId } = action
        const path = `/games/${gameLinkId}/game/rounds/${roundId}`
        await firebase.database().ref(path).remove()
      }
      break

    default:
      console.warn(`API hostAction not supported: ${type}`)
      break
  }
}
