import React from 'react'
import { useParams } from 'react-router-dom'

import useAuth from 'hooks/useAuth'
import usePublicGameData from './usePublicGameData'
import { GAME_DATA_STATUS_EXISTS, GAME_DATA_STATUS_NOT_FOUND } from 'api/apiConstants'
import JoinGame from './JoinGame'
import StartGame from './StartGame'
import ContentPanel from 'component/ContentPanel'
import FullScreenLoader from 'component/FullScreenLoader'

export default function Game() {
  const { user = {} } = useAuth()
  const { gameLinkId } = useParams()
  const {
    fetchingPublicGameData,
    fetchingPublicGameDataErrorDisplay,
    publicGameData,
  } = usePublicGameData({
    gameLinkId,
  })
  if (fetchingPublicGameData) {
    return <FullScreenLoader />
  }
  if (fetchingPublicGameDataErrorDisplay) {
    return <ContentPanel>{fetchingPublicGameDataErrorDisplay}</ContentPanel>
  }
  if (publicGameData) {
    const { status, details } = publicGameData
    if (status === GAME_DATA_STATUS_EXISTS && publicGameData && details) {
      const { hostId } = details
      const { id } = user
      if (hostId === id) {
        return <StartGame gameLinkId={gameLinkId} publicGameData={publicGameData} />
      } else {
        return <JoinGame gameLinkId={gameLinkId} publicGameData={publicGameData} />
      }
    }
    if (status === GAME_DATA_STATUS_NOT_FOUND) {
      return <ContentPanel>Game Not Found</ContentPanel>
    }
  }
  return <ContentPanel>Error</ContentPanel>
}
