import React from 'react'

import { useQuizManagerContext } from 'feature/QuizManager/context'
import { getQuizWinners } from 'feature/QuizManager/selectors'

import QuizWinner from 'feature/QuizManager/components/QuizWinner'

export default function QuizEnd() {
  const { gameData } = useQuizManagerContext()
  const winners = getQuizWinners({
    gameData,
  })
  return <QuizWinner winners={winners} />
}
