import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { AppProvider } from 'store/App'

import AuthWrapper from 'component/AuthWrapper'

import NavListener from 'feature/NavListener'
import MegaBackground from 'feature/MegaBackground'
import HeaderNav from 'feature/HeaderNav'
import AppContainer from 'feature/AppContainer'

import Home from 'feature/Home'
import UserDashboard from 'feature/UserDashboard'
import AddEvent from 'feature/Events/AddEvent'
import EditEvent from 'feature/Events/EditEvent'
import GameWaitingRoom from 'feature/Game/GameWaitingRoom'
import GameLive from 'feature/Game/GameLive'
import ErrorBoundary from 'feature/ErrorBoundary'

import PageNotFound from 'feature/PageNotFound'
import AuthDialog from 'feature/AuthDialog'
import SetupResponder from 'feature/SetupResponder'

import DebugGeneral from './feature/Debug/DebugGeneral'

function App() {
  return (
    <ErrorBoundary>
      <AppProvider>
        <Router>
          <NavListener />
          <MegaBackground />
          <HeaderNav />
          <AppContainer>
            <Switch>
              <Route exact path={['/', '/sign-in']}>
                <Home />
              </Route>
              <Route path="/dashboard">
                <AuthWrapper>
                  <UserDashboard />
                </AuthWrapper>
              </Route>
              <Route path="/event/add">
                <AuthWrapper>
                  <AddEvent />
                </AuthWrapper>
              </Route>
              <Route path="/event/edit/:eventId">
                <AuthWrapper>
                  <EditEvent />
                </AuthWrapper>
              </Route>
              <Route exact path="/play/:gameLinkId">
                <AuthWrapper>
                  <GameWaitingRoom />
                </AuthWrapper>
              </Route>
              <Route path="/play/:gameLinkId/live">
                <AuthWrapper>
                  <GameLive />
                </AuthWrapper>
              </Route>
              <Route exact path="/debug">
                <DebugGeneral />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </AppContainer>
          <AuthDialog />
          <SetupResponder />
        </Router>
      </AppProvider>
    </ErrorBoundary>
  )
}

export default App
