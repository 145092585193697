import {
  ROUND_TYPE_ADHOC,
  ROUND_TYPE_OPEN_QUESTION,
  ROUND_TYPE_Q_AND_A,
  ROUND_TYPE_MULTIPLE_CHOICE,
  ROUND_TYPE_FILL_THE_BLANK,
  RESPONSE_TIMING_FF_FIRST,
  RESPONSE_TIMING_EVERYONE,
  RESPONSE_CAPTURE_TEXT,
  RESPONSE_CAPTURE_AV,
  QUESTION_TYPE_OPEN_QUESTION,
  QUESTION_TYPE_QUESTION_AND_ANSWER,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_FILL_THE_BLANK,
} from 'module/constants'

export const roundTypeLookup = {
  [ROUND_TYPE_ADHOC]: 'Adhoc',
  [ROUND_TYPE_OPEN_QUESTION]: 'Open Question',
  [ROUND_TYPE_Q_AND_A]: 'Question and Answer',
  [ROUND_TYPE_MULTIPLE_CHOICE]: 'Multiple Choice',
  [ROUND_TYPE_FILL_THE_BLANK]: 'Fill the blank',
}

export const questionTypeLookup = {
  [QUESTION_TYPE_OPEN_QUESTION]: 'Open Question',
  [QUESTION_TYPE_QUESTION_AND_ANSWER]: 'Question and Answer',
  [QUESTION_TYPE_MULTIPLE_CHOICE]: 'Multiple Choice',
  [QUESTION_TYPE_FILL_THE_BLANK]: 'Fill the blank',
}

export const responseTimingLookup = {
  [RESPONSE_TIMING_FF_FIRST]: 'Fastest correct answer',
  [RESPONSE_TIMING_EVERYONE]: 'All correct answers',
}

export const responseTimingDescriptionLookup = {
  [RESPONSE_TIMING_FF_FIRST]: 'Points go to the player who answers correctly the quickest',
  [RESPONSE_TIMING_EVERYONE]: 'Points go to every player who answers correctly',
}

export const responseTimingDisplayLookup = {
  [RESPONSE_TIMING_FF_FIRST]: 'fastest finger first',
  [RESPONSE_TIMING_EVERYONE]: '',
}

export const playerResponseLookup = {
  [RESPONSE_CAPTURE_TEXT]: 'Type or select answer',
  [RESPONSE_CAPTURE_AV]: 'Say, show or perform answer',
}
