import { delay } from 'module/utils'

import { userData as mockUserData, MOCK_API_DELAY } from './_mockData'

window.QHLDEBUG = window.QHLDEBUG || {}
window.QHLDEBUG.onUpdateUserData = undefined
window.QHLDEBUG.userData = mockUserData

export async function startUserDataListener({ onUpdate, onUpdateSetup }) {
  console.log('** startUserDataListener **')
  window.QHLDEBUG.onUpdateUserData = onUpdate
  await delay(MOCK_API_DELAY)
  onUpdateSetup({
    minVersion: '0.3.0',
  })
  window.QHLDEBUG.onUpdateUserData(window.QHLDEBUG.userData)
}

export function stopUserDataListener() {
  console.log('** stopUserDataListener **')
}
