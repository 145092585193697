import * as Sentry from '@sentry/browser'

import { isDevMode } from 'module/utils'
import { USE_SENTRY_IN_DEV_MODE } from 'mode'

const dsn = 'https://38dbe658caea4ebc86f92bcf537a09ca@o359398.ingest.sentry.io/5206435'

export default () => {
  if (isDevMode()) {
    if (USE_SENTRY_IN_DEV_MODE) {
      console.info('** sentry initialised in de dev mode **')
      Sentry.init({
        dsn,
      })
    }
  } else {
    Sentry.init({
      dsn,
    })
  }
}
