import { useEffect, useCallback, useRef } from 'react'
import _throttle from 'lodash/throttle'

import useInterval from 'hooks/useInterval'

const DEFAULT_INACTIVE_TIME = 10 * 60 * 1000 // 10 minutes

const DEFAULT_ACTIVITY_EVENTS = [
  'click',
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'focus',
]

const useInactivityDetection = ({
  activityEvents = DEFAULT_ACTIVITY_EVENTS,
  inActiveFor = DEFAULT_INACTIVE_TIME,
  onInactivityDetected,
}) => {
  const lastActiveTimeStampRef = useRef()
  const handleUserActivityEvent = useRef(
    _throttle(() => {
      const now = new Date()
      lastActiveTimeStampRef.current = now.getTime()
    }, 1000),
  ).current

  const onCheckActivityStatus = useCallback(() => {
    const now = new Date()
    const nowTimeStamp = now.getTime()
    const lastActiveTimeStamp = lastActiveTimeStampRef.current
    const timeSinceLastActivity = nowTimeStamp - lastActiveTimeStamp
    const inactiveThresholdReached = timeSinceLastActivity > inActiveFor
    if (inactiveThresholdReached) {
      onInactivityDetected()
    }
  }, [inActiveFor, lastActiveTimeStampRef, onInactivityDetected])

  // add / remove activity listeners
  useEffect(() => {
    const now = new Date()
    lastActiveTimeStampRef.current = now.getTime()
    activityEvents.forEach((eventName) =>
      window.addEventListener(eventName, handleUserActivityEvent),
    )
    return () => {
      activityEvents.forEach((eventName) =>
        window.removeEventListener(eventName, handleUserActivityEvent),
      )
    }
  }, [activityEvents, handleUserActivityEvent])

  // start interval to check activity status
  useInterval({
    callback: onCheckActivityStatus,
    delay: inActiveFor,
    autoStart: true,
  })
}

export default useInactivityDetection
