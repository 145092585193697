import React, { useCallback } from 'react'
import { Card } from 'semantic-ui-react'

import './player-card.css'

import PointsAdjustButtons from 'component/PointsAdjustButtons'
import MediaControl from 'component/MediaControl'

import VideoPlayerSlave from 'component/VideoPlayerSlave'

const useToggle = ([playerSetting, gameSetting, onToggleProp, isHost, isMe, overrideOff]) => {
  // master game settings trumps player settings if game settings are disabled
  // i.e. if no master override is set then just use player settings
  let toggleEnabled = false
  let isOn = playerSetting
  if (gameSetting === false) {
    isOn = false
  }
  if (isHost) {
    toggleEnabled = true
  } else if (isMe && gameSetting !== false) {
    toggleEnabled = true
  }
  if (overrideOff) {
    isOn = false
    toggleEnabled = false
  }
  const onToggle = useCallback(() => {
    if (toggleEnabled && onToggleProp) {
      onToggleProp(!isOn)
    }
  }, [isOn, toggleEnabled, onToggleProp])
  return [isOn, onToggle, toggleEnabled]
}

export default function PlayerCard({
  playerId,
  player = {},
  gamePlayerData = {},
  hasResponded,
  hasRespondedCorrectly,
  assessmentPoints,
  audioTrack,
  videoTrack,
  isMe = false,
  isHost = false,
  localAudioOff,
  localVideoOff,
  onToggleVideo,
  onToggleAudio,
  onAddPoints,
  onSubtractPoints,
  videoDimensionStyles,
  isCurrentQuestionAudioPlayingLocally,
} = {}) {
  const { name } = player
  const { video: playerVideo, audio: playerAudio, image } = player
  const { points, video: gamePlayerVideo, audio: gamePlayerAudio } = gamePlayerData

  let audioOffOverride
  if (isMe && isCurrentQuestionAudioPlayingLocally) {
    audioOffOverride = true
  }

  const [audioOn, toggleAudio, toggleAudioEnabled] = useToggle([
    playerAudio,
    gamePlayerAudio,
    onToggleAudio,
    isHost,
    isMe,
    audioOffOverride,
  ])

  const [videoOn, toggleVideo, toggleVideoEnabled] = useToggle([
    playerVideo,
    gamePlayerVideo,
    onToggleVideo,
    isHost,
    isMe,
  ])

  const me = isMe ? ' (me)' : ''

  let mediaOverrideDisplay = ''
  if (gamePlayerVideo === false || gamePlayerAudio === false) {
    if (gamePlayerVideo === false && gamePlayerAudio === false) {
      mediaOverrideDisplay = 'Video and Microphone disabled by host'
    } else if (gamePlayerVideo === false) {
      mediaOverrideDisplay = 'Video disabled by host'
    } else if (gamePlayerAudio === false) {
      mediaOverrideDisplay = 'Microphone disabled by host'
    }
  }

  if (isMe && isCurrentQuestionAudioPlayingLocally && !mediaOverrideDisplay) {
    mediaOverrideDisplay = 'Microphone is disabled while playing sound track'
  }

  let playerCardStyle = ''
  if (hasResponded) {
    playerCardStyle = 'player-has-responded'
  }
  if (hasRespondedCorrectly) {
    playerCardStyle = 'player-has-responded-correctly'
  }

  const additionalWidthRequiredForPadding = 4

  return (
    <Card
      className={`player-card ${playerCardStyle}`}
      style={{
        width:
          videoDimensionStyles && videoDimensionStyles.width
            ? videoDimensionStyles.width + additionalWidthRequiredForPadding
            : undefined,
      }}
    >
      <div
        className="video-content-container"
        style={{ position: 'relative', ...videoDimensionStyles }}
      >
        {!!audioTrack || !!videoTrack ? (
          <div>
            <VideoPlayerSlave
              isMe={isMe}
              audioTrack={audioTrack}
              videoTrack={videoTrack}
              audioEnabled={isMe && localAudioOff ? false : audioOn}
              videoEnabled={isMe && localVideoOff ? false : videoOn}
              videoDimensionStyles={videoDimensionStyles}
            />
            {!!videoOn && (
              <div style={{ position: 'absolute', bottom: 6, right: 6 }}>
                <img alt="Host" className="video-placeholder-image-micro" src={image} />
              </div>
            )}
            {!videoOn && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'white',
                }}
              >
                <img className="video-placeholder-image" src={image} style={videoDimensionStyles} />
              </div>
            )}
          </div>
        ) : (
          <img className="video-placeholder-image" src={image} style={videoDimensionStyles} />
        )}
        {!!mediaOverrideDisplay && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <div className="override-msg">{mediaOverrideDisplay}</div>
          </div>
        )}
      </div>

      <Card.Content>
        <Card.Header>
          <div className="player-card-name-display">
            <div className="player-card-name-display-name">{name}</div>
            <div className="player-card-name-display-me">{me}</div>
          </div>
        </Card.Header>
        <Card.Meta>
          <span style={{ marginRight: '1em' }}>
            Points: {assessmentPoints} ({points})
          </span>
        </Card.Meta>
        <div
          style={{
            display: 'flex',
            paddingTop: isHost ? '1em' : 0,
            justifyContent: isHost ? 'space-between' : 'flex-end',
          }}
        >
          {isHost && <PointsAdjustButtons onAdd={onAddPoints} onSubtract={onSubtractPoints} />}
          <MediaControl
            audioOn={
              isMe && (isCurrentQuestionAudioPlayingLocally || localAudioOff) ? false : audioOn
            }
            videoOn={isMe && localVideoOff ? false : videoOn}
            toggleAudio={toggleAudioEnabled ? toggleAudio : undefined}
            toggleVideo={toggleVideoEnabled ? toggleVideo : undefined}
          />
        </div>
      </Card.Content>
    </Card>
  )
}
