import React from 'react'
import { Image } from 'semantic-ui-react'

const containerStyle = {
  minHeight: 140,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1em',
}

export default function QuizWinner({ winners = [] }) {
  if (winners.length === 0) {
    return (
      <div style={containerStyle}>
        <h3>No winners!</h3>
      </div>
    )
  } else if (winners.length === 1) {
    const { name, image } = winners[0]
    return (
      <div style={containerStyle}>
        <Image src={image} rounded style={{ width: 180 }} />
        <div style={{ marginTop: '1em' }}>
          <h4>{name}</h4>
        </div>
      </div>
    )
  } else {
    return (
      <div style={containerStyle}>
        <h3>We have a tie</h3>
        <p>Tie break round needed!</p>
      </div>
    )
  }
}
