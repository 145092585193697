import React from 'react'

import GamePlayVideo from 'feature/Game/GameLive/GamePlay/GamePlayVideo'
import GamePlayBasic from 'feature/Game/GameLive/GamePlay/GamePlayBasic'

export default function GamePlay(props) {
  if (props.basicMode) {
    return <GamePlayBasic {...props} />
  }
  return <GamePlayVideo {...props} />
}
