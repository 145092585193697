import React from 'react'
import { Image } from 'semantic-ui-react'

const imgSrc = require('images/logo.svg')

export default function Placeholder({ message }) {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        width: '100%',
        height: '100%',
      }}
    >
      <Image src={imgSrc} rounded style={{ width: 100 }} />
      <h4>{message}</h4>
    </div>
  )
}
