import _set from 'lodash/set'
import _orderBy from 'lodash/orderBy'

import { getServerTimeStamp } from 'store/storeApi'

import {
  ROUND_STATUS_COMPLETED,
  RESPONSE_ASSESSMENT_CORRECT,
  RESPONSE_ASSESSMENT_CORRECT_NO_POINTS,
  RESPONSE_ASSESSMENT_WRONG,
  RESPONSE_TIMING_FF_FIRST,
  QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'module/constants'
import { clone } from 'module/utils'

import {
  getCurrentRoundId,
  getCurrentRound,
  getNextQuestionId,
  getCurrentQuestionId,
  getCurrentQuestion,
  getCorrectAnswerOptionIdForCurrentQuestion,
  getCurrentPlayerAssessments,
  getCurrentPlayerResponses,
} from 'feature/QuizManager/selectors'

import useGameHostActions from 'feature/Game/GameLive/GamePlay/useGameHostActions'
import useGamePlayerActions from 'feature/Game/GameLive/GamePlay/useGamePlayerActions'

export default function useQuestionDisplayActions({ gameData }) {
  const { setRoundState } = useGameHostActions({
    gameData,
  })
  const { respondToQuestion } = useGamePlayerActions({
    gameData,
  })

  // ************************************
  // ** general use internal functions **
  // ************************************

  function getRoundUpdateData({ gameData, update }) {
    const currentRound = getCurrentRound({
      gameData,
    })
    if (Object.keys(currentRound).length) {
      return {
        ...currentRound,
        ...update,
      }
    } else {
      return gameData
    }
  }

  function updateCurrentRound(update = {}) {
    const currentRound = getCurrentRound({
      gameData,
    })
    setRoundState({
      roundId: currentRound.id,
      round: getRoundUpdateData({
        gameData,
        update,
      }),
    })
  }

  function setCurrentQuestionAssessmentForPlayer({ playerId, assessment }) {
    const currentQuestionId = getCurrentQuestionId({ gameData })
    const currentPlayerAssessments = getCurrentPlayerAssessments({
      gameData,
    })
    const responseAssessments = {
      ...currentPlayerAssessments,
    }
    _set(responseAssessments, `${currentQuestionId}.${playerId}`, assessment)
    updateCurrentRound({
      responseAssessments,
    })
  }

  function setPlayerResponse({ playerId, response }) {
    const currentRoundId = getCurrentRoundId({ gameData })
    const currentQuestionId = getCurrentQuestionId({ gameData })
    respondToQuestion({
      playerId,
      roundId: currentRoundId,
      questionId: currentQuestionId,
      response,
    })
  }

  // ******************
  // ** host actions **
  // ******************

  function onRevealQuestion() {
    updateCurrentRound({
      questionRevealed: true,
      questionRevealedAt: getServerTimeStamp(),
    })
  }

  function onGoToNextQuestion() {
    const nextQuestionId = getNextQuestionId({
      gameData,
    })
    if (!nextQuestionId) {
      console.warn('** no question id to go to - soz **')
    } else {
      updateCurrentRound({
        activeQuestionId: nextQuestionId,
        questionRevealed: false,
        answerRevealed: false,
        questionRevealedAt: null,
      })
    }
  }

  function onRevealAnswer() {
    // if current question type involves auto assessment, then update player assessments
    // as well as setting the answer revealed state
    // OTHERWISE just set the answer revealed state
    // ALSO take in to account responseTiming as we may only be giving the points to the fastest finger!

    const { responseTiming } = getCurrentRound({
      gameData,
    })
    const { questionType } =
      getCurrentQuestion({
        gameData,
      }) || {}
    if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
      const currentQuestionId = getCurrentQuestionId({ gameData })
      const correctAnswerOptionIdForCurrentQuestion = getCorrectAnswerOptionIdForCurrentQuestion({
        gameData,
      })
      const currentPlayerResponses = getCurrentPlayerResponses({
        gameData,
      })
      const currentPlayerAssessments = getCurrentPlayerAssessments({
        gameData,
      })
      const updatedResponseAssessments = clone(currentPlayerAssessments)
      updatedResponseAssessments[currentQuestionId] = {}

      const orderedPlayerResponses = _orderBy(currentPlayerResponses, ['timeStamp'], ['asc'])
      let correctTimeStamp

      orderedPlayerResponses.forEach(({ playerId, answerOptionId, timeStamp }) => {
        let assessmentOutcome = RESPONSE_ASSESSMENT_WRONG
        const answerIsCorrect = answerOptionId === correctAnswerOptionIdForCurrentQuestion
        if (answerIsCorrect) {
          if (responseTiming === RESPONSE_TIMING_FF_FIRST) {
            // fastest finger first, so we only give the points to the earliest time stamp
            // (multiple if they are matching)
            if (!correctTimeStamp || timeStamp === correctTimeStamp) {
              correctTimeStamp = timeStamp
              assessmentOutcome = RESPONSE_ASSESSMENT_CORRECT
            } else {
              assessmentOutcome = RESPONSE_ASSESSMENT_CORRECT_NO_POINTS
            }
          } else {
            assessmentOutcome = RESPONSE_ASSESSMENT_CORRECT
          }
        }
        updatedResponseAssessments[currentQuestionId][playerId] = assessmentOutcome
      })
      updateCurrentRound({
        answerRevealed: true,
        responseAssessments: updatedResponseAssessments,
      })
    } else {
      updateCurrentRound({
        answerRevealed: true,
      })
    }
  }

  function onEndRound() {
    updateCurrentRound({
      activeQuestionId: null,
      questionRevealed: false,
      answerRevealed: false,
      questionRevealedAt: null,
      status: ROUND_STATUS_COMPLETED,
    })
  }

  function onAssessCorrect({ playerId }) {
    setCurrentQuestionAssessmentForPlayer({
      playerId,
      assessment: RESPONSE_ASSESSMENT_CORRECT,
    })
  }

  function onAssessWrong({ playerId }) {
    setCurrentQuestionAssessmentForPlayer({
      playerId,
      assessment: RESPONSE_ASSESSMENT_WRONG,
    })
  }

  // ********************
  // ** player actions **
  // ********************

  function onSubmitResponse({ playerId, answerText, answerOptionId }) {
    setPlayerResponse({
      playerId,
      response: {
        timeStamp: getServerTimeStamp(),
        pass: false,
        answerText,
        answerOptionId,
      },
    })
  }

  function onPass({ playerId }) {
    setPlayerResponse({
      playerId,
      response: {
        timeStamp: getServerTimeStamp(),
        pass: true,
      },
    })
  }

  return {
    onRevealQuestion,
    onGoToNextQuestion,
    onRevealAnswer,
    onEndRound,
    onAssessCorrect,
    onAssessWrong,
    onSubmitResponse,
    onPass,
  }
}
