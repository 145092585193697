import React, { useCallback } from 'react'
import { Button } from 'semantic-ui-react'

import './start-game.css'

import useGameActivation from 'feature/Game/useGameActivation'
import ContentPanel from 'component/ContentPanel'
import RenderOnInterval from 'component/RenderOnInterval'

import PlayerList from 'feature/Game/PlayerList'
import GameConferenceDetails from 'feature/Game/GameConferenceDetails'

import { dateTimeDisplay } from 'module/dates'
import { getIsWithinGamePlayTimePeriod } from 'module/game'

import useNav from 'hooks/useNav'
import {
  LIVE_GAME_STATUS_IN_PROGRESS,
  LIVE_GAME_STATUS_PENDING,
  LIVE_GAME_STATUS_PAUSED,
} from 'api/apiConstants'

import { GAME_PERIOD_POST } from 'module/constants'

export default function StartGame({ gameLinkId, publicGameData = {} }) {
  const { navToLiveGame } = useNav()
  const {
    fetchingGameData,
    startGame,
    startingGame,
    startingGameErrorDisplay,
    gameStatus,
  } = useGameActivation({
    gameLinkId,
  })
  const { details = {} } = publicGameData
  const { dateTime, maxDurationMinutes } = details
  const { gamePeriodStatus } = getIsWithinGamePlayTimePeriod({ dateTime, maxDurationMinutes })
  const gameHasBeenAndGone = gamePeriodStatus === GAME_PERIOD_POST

  const onNavToLiveGame = useCallback(() => {
    navToLiveGame({ gameLinkId })
  }, [navToLiveGame, gameLinkId])

  const canStartGame = useCallback(() => {
    return getIsWithinGamePlayTimePeriod({
      dateTime,
      maxDurationMinutes,
    }).isWithinGamePlayTimePeriod
  }, [dateTime, maxDurationMinutes])

  if (fetchingGameData) {
    return <ContentPanel>Connecting....</ContentPanel>
  }
  return (
    <ContentPanel>
      <h2>{details.name}</h2>
      <h4>
        {gameHasBeenAndGone ? '' : 'Starts on: '}
        {dateTimeDisplay({ dateTime, mode: 'friendly' })}
      </h4>
      {!!gameHasBeenAndGone && <p>Game has ended</p>}
      {!!startingGameErrorDisplay && <div>{startingGameErrorDisplay}</div>}
      {!gameHasBeenAndGone && (
        <>
          <PlayerList gameLinkId={gameLinkId} />
          <div style={{ marginTop: '0.5em' }}>
            <GameConferenceDetails gameLinkId={gameLinkId} />
          </div>
          <div style={{ marginTop: '2em' }}>
            <RenderOnInterval interval={1000} test={canStartGame}>
              {!!gameStatus && gameStatus === LIVE_GAME_STATUS_PENDING && (
                <Button loading={startingGame} onClick={startGame}>
                  Start Quiz
                </Button>
              )}
              {!!gameStatus && gameStatus === LIVE_GAME_STATUS_PAUSED && (
                <Button loading={startingGame} onClick={startGame}>
                  Restart Quiz
                </Button>
              )}
              {!!gameStatus && gameStatus === LIVE_GAME_STATUS_IN_PROGRESS && (
                <Button loading={startingGame} onClick={onNavToLiveGame}>
                  Enter Quiz
                </Button>
              )}
            </RenderOnInterval>
          </div>
        </>
      )}
    </ContentPanel>
  )
}
