import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import moment from 'moment'

import { LIVE_GAME_STATUS_PENDING } from 'api/apiConstants'

import { getHostEvents, getGames } from './index'

export function getCreateEventStatus(state) {
  const { creatingEvent = false, creatingEventErrorDisplay = '' } = state
  return {
    creatingEvent,
    creatingEventErrorDisplay,
  }
}

export function getUpdateEventStatus({ state, eventId }) {
  const { updatingEvents = {}, updatingEventsErrorDisplay = {} } = state
  return {
    updatingEvent: updatingEvents[eventId] || false,
    updatingEventErrorDisplay: updatingEventsErrorDisplay[eventId] || '',
  }
}

export function getDeleteEventStatus({ state, eventId }) {
  const { deletingEvents = {}, deletingEventsErrorDisplay = {} } = state
  return {
    deletingEvent: deletingEvents[eventId] || false,
    deletingEventErrorDisplay: deletingEventsErrorDisplay[eventId] || '',
  }
}

export function getCanEditEvent({ state, eventId }) {
  // do not allow editing of events if the associated game link is not
  // in a pending state
  const { gameLinkId } = _get(state, `userData.events.${eventId}.details`, {})
  const gameLinkMetaStatus = _get(state, `userData.meta.games.${gameLinkId}.status`, '')
  return gameLinkMetaStatus === LIVE_GAME_STATUS_PENDING
}

export function getAllEvents(state) {
  const hostEvents = getHostEvents(state)
  const games = getGames(state)
  const eventsList = {
    ...hostEvents,
    ...games,
  }
  const allEvents = _orderBy(
    Object.entries(eventsList).map(([id, event]) => {
      const isHost = !!hostEvents[id]
      const eventDetails = isHost ? getHostEventDetails({ state, eventId: id }) : event
      const gameLinkId = games[id] ? id : eventDetails.gameLinkId
      return { id, ...eventDetails, isHost, gameLinkId }
    }),
    ['dateTime', 'name'],
    ['asc', 'asc'],
  )
  const upcoming = []
  const historic = []
  const today = moment().format('YYYY-MM-DD')
  allEvents.forEach((event) => {
    if (moment(event.dateTime).isBefore(today)) {
      historic.push(event)
    } else {
      upcoming.push(event)
    }
  })
  return {
    upcoming,
    historic,
  }
}

export function getHostEventDetails({ state, eventId }) {
  const hostEvents = getHostEvents(state)
  if (!hostEvents[eventId]) {
    return undefined
  }
  const { details = {} } = hostEvents[eventId]
  return {
    ...details,
    canEditEvent: getCanEditEvent({ state, eventId }),
  }
}

export function getHostEvent({ state, eventId }) {
  const hostEvents = getHostEvents(state)
  if (!hostEvents[eventId]) {
    return undefined
  }
  return hostEvents[eventId]
}
