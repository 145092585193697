import React from 'react'
import { createContext, useContext } from 'react'

export const QuizManagerContext = createContext()

function QuizManagerProvider({ value, children }) {
  return <QuizManagerContext.Provider value={value}>{children}</QuizManagerContext.Provider>
}

function useQuizManagerContext() {
  const context = useContext(QuizManagerContext)
  if (context === undefined) {
    throw new Error('useQuizManager must be used within a QuizManagerProvider')
  }
  return context
}

export { QuizManagerProvider, useQuizManagerContext }
