import React, { useState, useCallback } from 'react'

import { getDurationDisplay } from 'module/displayFormat'
import { timeDiffFromNow } from 'module/calcs'

import Typography from 'component/Typography'

import useInterval from 'hooks/useInterval'

export default function TimeCounter({ from }) {
  const [timeDiff, setTimeDiff] = useState(timeDiffFromNow({ from }))
  const callback = useCallback(() => {
    const diff = timeDiffFromNow({ from })
    setTimeDiff(diff)
  }, [setTimeDiff, from])
  useInterval({
    callback,
    delay: 1000,
    autoStart: true,
  })
  return <Typography.text alt>{getDurationDisplay({ duration: timeDiff })}</Typography.text>
}
