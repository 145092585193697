import { startAuthListener, signOut, signIn, signUp, resetPassword } from './auth'
import { startUserDataListener, stopUserDataListener } from './userData'
import { addUpdateEvent, deleteEvent } from './events'
import {
  getPublicGameData,
  connectToPublicGameData,
  disconnectFromPublicGameData,
  getIsGameParticipant,
  startGame,
  joinGame,
  connectToGame,
  disconnectFromGame,
  playerAction,
  hostAction,
} from './game'

function getServerTimeStamp() {
  // mimic the firebase time stamps: the time since the Unix epoch, in milliseconds
  return Math.round(new Date().getTime())
}

export default {
  getServerTimeStamp,
  // auth
  startAuthListener,
  signOut,
  signIn,
  signUp,
  resetPassword,
  // user data
  startUserDataListener,
  stopUserDataListener,
  // events
  addUpdateEvent,
  deleteEvent,
  // game
  getPublicGameData,
  connectToPublicGameData,
  disconnectFromPublicGameData,
  getIsGameParticipant,
  startGame,
  joinGame,
  connectToGame,
  disconnectFromGame,
  playerAction,
  hostAction,
}
