import { useEffect } from 'react'

const useVideoPlayerSlaveTwilio = ({
  isMe,
  videoRef,
  audioRef,
  audioTrack,
  videoTrack,
  audioEnabled,
  videoEnabled,
}) => {
  useEffect(() => {
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
    }
    return () => {
      if (audioTrack) {
        audioTrack.detach()
      }
    }
  }, [audioRef, audioTrack])

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
    }
    return () => {
      if (videoTrack) {
        videoTrack.detach()
      }
    }
  }, [videoRef, videoTrack])
}

export default useVideoPlayerSlaveTwilio
