import storeApi from 'store/storeApi'
import {
  ACTION_CREATE_EVENT_START,
  ACTION_CREATE_EVENT_SUCCESS,
  ACTION_CREATE_EVENT_ERROR,
  ACTION_UPDATE_EVENT_START,
  ACTION_UPDATE_EVENT_SUCCESS,
  ACTION_UPDATE_EVENT_ERROR,
  ACTION_DELETE_EVENT_START,
  ACTION_DELETE_EVENT_SUCCESS,
  ACTION_DELETE_EVENT_ERROR,
} from 'store/App/actionTypes'
import { getNormalisedDateFromDateTimeParts } from 'module/dates'

export function createEvent({
  dispatch,
  name,
  date,
  time,
  useVideo,
  videoMode,
  connectionType,
  supportingVideoConference,
  supportingVideoConferenceInfo,
  maxDurationMinutes,
  maxPlayers,
  onSuccess,
  onError,
}) {
  try {
    dispatch({
      type: ACTION_CREATE_EVENT_START,
    })
    storeApi({
      method: 'addUpdateEvent',
      params: {
        name,
        useVideo,
        videoMode,
        connectionType,
        supportingVideoConference,
        supportingVideoConferenceInfo,
        maxDurationMinutes,
        maxPlayers,
        dateTime: getNormalisedDateFromDateTimeParts({
          datePart: date,
          timePart: time,
        }),
        onSuccess: ({ eventId, gameLinkId } = {}) => {
          dispatch({
            type: ACTION_CREATE_EVENT_SUCCESS,
          })
          if (onSuccess) {
            onSuccess({ eventId, gameLinkId })
          }
        },
        onError: ({ errorMsg = 'Error creating event' } = {}) => {
          dispatch({
            type: ACTION_CREATE_EVENT_ERROR,
            payload: { errorMsg },
          })
          if (onError) {
            onError()
          }
        },
      },
    })
  } catch (error) {
    console.warn('error creating event', { error })
    dispatch({
      type: ACTION_CREATE_EVENT_ERROR,
      payload: {
        errorMsg: 'Error creating event',
      },
    })
  }
}

export function updateEvent({
  dispatch,
  id,
  name,
  date,
  time,
  useVideo,
  videoMode,
  connectionType,
  supportingVideoConference,
  supportingVideoConferenceInfo,
  maxDurationMinutes,
  maxPlayers,
  gameLinkId,
  onSuccess,
  onError,
}) {
  try {
    dispatch({
      type: ACTION_UPDATE_EVENT_START,
      payload: {
        eventId: id,
      },
    })
    storeApi({
      method: 'addUpdateEvent',
      params: {
        id,
        name,
        useVideo,
        videoMode,
        connectionType,
        supportingVideoConference,
        supportingVideoConferenceInfo,
        maxDurationMinutes,
        maxPlayers,
        dateTime: getNormalisedDateFromDateTimeParts({
          datePart: date,
          timePart: time,
        }),
        gameLinkId,
        onSuccess: ({ eventId } = {}) => {
          dispatch({
            type: ACTION_UPDATE_EVENT_SUCCESS,
            payload: {
              eventId: id,
            },
          })
          if (onSuccess) {
            onSuccess({ eventId })
          }
          if (onError) {
            onError()
          }
        },
        onError: ({ errorMsg = 'Error updating event' } = {}) => {
          dispatch({
            type: ACTION_UPDATE_EVENT_ERROR,
            payload: { errorMsg, eventId: id },
          })
        },
      },
    })
  } catch (error) {
    console.warn('error updating event', { error })
    dispatch({
      type: ACTION_UPDATE_EVENT_ERROR,
      payload: {
        errorMsg: 'Error updating event',
        eventId: id,
      },
    })
  }
}

export function setEventRounds({ dispatch, id, rounds, onSuccess, onError }) {
  try {
    dispatch({
      type: ACTION_UPDATE_EVENT_START,
      payload: {
        eventId: id,
      },
    })
    storeApi({
      method: 'setEventRounds',
      params: {
        id,
        rounds,
        onSuccess: ({ eventId } = {}) => {
          dispatch({
            type: ACTION_UPDATE_EVENT_SUCCESS,
            payload: {
              eventId: id,
            },
          })
          if (onSuccess) {
            onSuccess({ eventId })
          }
          if (onError) {
            onError()
          }
        },
        onError: ({ errorMsg = 'Error updating event' } = {}) => {
          dispatch({
            type: ACTION_UPDATE_EVENT_ERROR,
            payload: { errorMsg, eventId: id },
          })
        },
      },
    })
  } catch (error) {
    console.warn('error updating event', { error })
    dispatch({
      type: ACTION_UPDATE_EVENT_ERROR,
      payload: {
        errorMsg: 'Error updating event',
        eventId: id,
      },
    })
  }
}

export function deleteEvent({ dispatch, id, onSuccess, onError }) {
  try {
    dispatch({
      type: ACTION_DELETE_EVENT_START,
      payload: {
        eventId: id,
      },
    })
    storeApi({
      method: 'deleteEvent',
      params: {
        id,
        onSuccess: () => {
          dispatch({
            type: ACTION_DELETE_EVENT_SUCCESS,
            payload: {
              eventId: id,
            },
          })
          if (onSuccess) {
            onSuccess()
          }
        },
        onError: ({ errorMsg = 'Error deleting event' } = {}) => {
          dispatch({
            type: ACTION_DELETE_EVENT_ERROR,
            payload: { errorMsg, eventId: id },
          })
          if (onError) {
            onError()
          }
        },
      },
    })
  } catch (error) {
    console.warn('error deleting event', { error })
    dispatch({
      type: ACTION_DELETE_EVENT_ERROR,
      payload: {
        errorMsg: 'Error deleting event',
        eventId: id,
      },
    })
  }
}
