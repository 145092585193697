import _get from 'lodash/get'
import { useState, useEffect, useCallback, useRef } from 'react'
import useInterval from 'hooks/useInterval'

import useAuth from 'hooks/useAuth'
import useSetupData from 'hooks/useSetupData'

import { getVideoConfig } from 'module/environ'

const videoConfig = getVideoConfig()

function getConnectionOptions({ gameLinkId, setupData }) {
  const jitsiSetup = _get(setupData, 'video.jitsi', {})
  const { activeSetupId } = jitsiSetup

  if (activeSetupId && jitsiSetup[activeSetupId]) {
    const { bosh = '', ...otherConfig } = jitsiSetup[activeSetupId] || {}
    console.log(`*** using jitsi config with id "${activeSetupId}"`, {
      config: jitsiSetup[activeSetupId],
    })
    return {
      ...otherConfig,
      bosh: `${bosh}?room=qhl-${gameLinkId}`,
    }
  } else {
    console.log('*** using default jitsi config', {
      config: videoConfig.providerConfig,
    })
    const { bosh = '', ...otherConfig } = videoConfig.providerConfig || {}
    return {
      ...otherConfig,
      bosh: `${bosh}?room=qhl-${gameLinkId}`,
    }
  }
}

const confOptions = {
  openBridgeChannel: true,
}

const useVideoParticipantsJitsi = ({
  gameLinkId,
  autoConnect,
  localVideoOff,
  localAudioOff,
  onSetConnectionId,
}) => {
  const { userId } = useAuth()
  const { setupData } = useSetupData()
  const [error, setError] = useState(null)
  const [connectionId, setConnectionId] = useState(null)
  const [audioTracks, setAudioTracks] = useState({})
  const [videoTracks, setVideoTracks] = useState({})
  const localTrackList = useRef()
  const connection = useRef()
  const room = useRef()
  const checkForScripts = useRef()

  const callback = useCallback(() => {
    if (checkForScripts.current) {
      checkForScripts.current()
    }
  }, [checkForScripts])

  const { start: startCheckForScripts, stop: stopCheckForScripts } = useInterval({
    callback,
    delay: 100,
    autoStart: false,
  })

  const addLocalTracksToRoom = useCallback(
    (tracks) => {
      console.log('*** addLocalTracksToRoom', { tracks })
      localTrackList.current = localTrackList.current || []
      tracks.forEach((track) => {
        room.current.addTrack(track)
        // we keep a local track list copy to use for disposing of
        // tracks when the component unmounts (tried using the tacks from
        // connection state BUT got stuck in an infinite loop!)
        localTrackList.current.push(track)
      })
    },
    [room, localTrackList],
  )

  const createLocalTracks = useCallback(
    ({ devices = ['audio', 'video'] } = {}) => {
      console.log('*** createLocalTracks', { devices })
      window.JitsiMeetJS.createLocalTracks({ devices })
        .then(addLocalTracksToRoom)
        .catch((error) => {
          throw error
        })
    },
    [addLocalTracksToRoom],
  )

  // *** LISTENERS ****

  const connectionListeners = {}
  const roomListeners = {}

  const removeAllListeners = useCallback(() => {
    console.log('** removeAllListeners')
    if (room.current) {
      room.current.removeEventListener(
        window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        roomListeners.conferenceJoined,
      )
      room.current.removeEventListener(
        window.JitsiMeetJS.events.conference.TRACK_ADDED,
        roomListeners.trackAdded,
      )
      room.current.removeEventListener(
        window.JitsiMeetJS.events.conference.TRACK_REMOVED,
        roomListeners.trackRemoved,
      )
      room.current.removeEventListener(
        window.JitsiMeetJS.events.conference.USER_JOINED,
        roomListeners.userJoined,
      )
      room.current.removeEventListener(
        window.JitsiMeetJS.events.conference.USER_LEFT,
        roomListeners.userLeft,
      )
    }
    if (connection.current) {
      connection.current.removeEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        connectionListeners.connectionEstablished,
      )
      connection.current.removeEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
        connectionListeners.connectionFailed,
      )
      connection.current.removeEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        connectionListeners.connectionDisconnected,
      )
    }
  }, [
    roomListeners.conferenceJoined,
    roomListeners.trackAdded,
    roomListeners.trackRemoved,
    roomListeners.userJoined,
    roomListeners.userLeft,
    connectionListeners.connectionEstablished,
    connectionListeners.connectionFailed,
    connectionListeners.connectionDisconnected,
  ])

  // *** ROOM **

  roomListeners.trackAdded = useCallback(
    (track) => {
      console.log('******* %&^ track added', { track })
      const { ownerEndpointId } = track
      const isLocal = track.isLocal()
      const id = isLocal ? room.current.myUserId() : ownerEndpointId
      if (track.getType() === 'audio') {
        setAudioTracks((existingTracks) => ({
          ...existingTracks,
          [id]: track,
        }))
      } else if (track.getType() === 'video') {
        setVideoTracks((existingTracks) => ({
          ...existingTracks,
          [id]: track,
        }))
      }
    },
    [setAudioTracks, setVideoTracks],
  )

  roomListeners.trackRemoved = useCallback(
    (track) => {
      console.log('** %&^ track removed!!', { track })
      const { ownerEndpointId } = track
      const isLocal = track.isLocal()
      const id = isLocal ? room.current.myUserId() : ownerEndpointId
      if (track.getType() === 'audio') {
        setAudioTracks((existingTracks) => {
          const { [id]: thisTrack, ...remainingTracks } = existingTracks
          if (thisTrack && thisTrack.detach) {
            thisTrack.detach()
          }

          return remainingTracks
        })
      } else if (track.getType() === 'video') {
        setVideoTracks((existingTracks) => {
          const { [id]: thisTrack, ...remainingTracks } = existingTracks
          if (thisTrack && thisTrack.detach) {
            thisTrack.detach()
          }
          return remainingTracks
        })
      }
    },
    [setAudioTracks, setVideoTracks, room],
  )

  roomListeners.conferenceJoined = useCallback(() => {
    console.log('*** conferenceJoined')
    createLocalTracks()
  }, [createLocalTracks])

  roomListeners.userJoined = useCallback((connectionId) => {
    console.log('** userJoined', { connectionId })
  }, [])

  roomListeners.userLeft = useCallback(
    (connectionId) => {
      console.log('** userLeft', { connectionId })
      // remove this users connections
      setAudioTracks((existingTracks) => {
        const { [connectionId]: thisTrack, ...remainingTracks } = existingTracks
        return remainingTracks
      })
      setVideoTracks((existingTracks) => {
        const { [connectionId]: thisTrack, ...remainingTracks } = existingTracks
        return remainingTracks
      })
    },
    [setAudioTracks, setVideoTracks],
  )

  const connectToRoom = useCallback(() => {
    room.current = connection.current.initJitsiConference('conference', confOptions)
    room.current.addEventListener(
      window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      roomListeners.conferenceJoined,
    )
    room.current.addEventListener(
      window.JitsiMeetJS.events.conference.TRACK_ADDED,
      roomListeners.trackAdded,
    )
    room.current.addEventListener(
      window.JitsiMeetJS.events.conference.TRACK_REMOVED,
      roomListeners.trackRemoved,
    )
    room.current.addEventListener(
      window.JitsiMeetJS.events.conference.USER_JOINED,
      roomListeners.userJoined,
    )
    room.current.addEventListener(
      window.JitsiMeetJS.events.conference.USER_LEFT,
      roomListeners.userLeft,
    )
    room.current.join()
    setConnectionId(room.current.myUserId())
  }, [
    roomListeners.conferenceJoined,
    roomListeners.trackAdded,
    roomListeners.trackRemoved,
    roomListeners.userJoined,
    roomListeners.userLeft,
    setConnectionId,
  ])

  // *** CONNECTION ****

  connectionListeners.connectionEstablished = useCallback(() => {
    console.log('** connectionEstablished')
    connectToRoom()
  }, [connectToRoom])
  connectionListeners.connectionFailed = useCallback(() => {
    console.log('** connectionFailed')
  }, [])
  connectionListeners.connectionDisconnected = useCallback(() => {
    console.log('** connectionDisconnected')
    removeAllListeners()
  }, [removeAllListeners])

  const initialiseConnection = useCallback(() => {
    try {
      if (window.JitsiMeetJS) {
        const options = getConnectionOptions({
          gameLinkId,
          setupData,
        })
        window.JitsiMeetJS.init()
        connection.current = new window.JitsiMeetJS.JitsiConnection(null, null, options)
        connection.current.addEventListener(
          window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
          connectionListeners.connectionEstablished,
        )
        connection.current.addEventListener(
          window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
          connectionListeners.connectionFailed,
        )
        connection.current.addEventListener(
          window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
          connectionListeners.connectionDisconnected,
        )
        connection.current.connect()
      } else {
        setError('Unable to connect')
        console.log('** JitsiMeetJS NOT defined **')
      }
    } catch (error) {
      setError('Unable to connect')
      console.log('not happening mate', { error })
    }
  }, [
    connectionListeners.connectionEstablished,
    connectionListeners.connectionFailed,
    connectionListeners.connectionDisconnected,
    gameLinkId,
    setError,
    connection,
    setupData,
  ])

  // ** start / end session

  const endSession = useCallback(() => {
    stopCheckForScripts()
    if (localTrackList.current) {
      localTrackList.current.forEach((localTrack) => {
        if (!localTrack.disposed) {
          localTrack.dispose()
        }
      })
    }
    if (room.current && room.current.leave) {
      room.current.leave()
    }
    if (connection.current && connection.current.disconnect) {
      connection.current.disconnect()
    }
  }, [stopCheckForScripts, localTrackList])

  const startSession = useCallback(() => {
    if (!window.$) {
      const jQueryScript = document.createElement('script')
      jQueryScript.src = 'https://code.jquery.com/jquery-3.5.0.min.js'
      jQueryScript.async = true
      document.body.appendChild(jQueryScript)
    }
    if (!window.JitsiMeetJS) {
      const jitsiMeetScript = document.createElement('script')
      jitsiMeetScript.src = 'https://rusta.co.uk/qhl/lib-jitsi-meet-bmm-qhl-mod001.min.js'
      jitsiMeetScript.async = true
      jitsiMeetScript.crossorigin = true
      document.body.appendChild(jitsiMeetScript)
    }
    if (!checkForScripts.current) {
      checkForScripts.current = () => {
        if (window.$ && window.JitsiMeetJS) {
          stopCheckForScripts()
          initialiseConnection()
          // TODO: confirm if we need the below?
          // window.$(window).bind('beforeunload', endSession)
          // window.$(window).bind('unload', endSession)
        }
      }
    }
    startCheckForScripts()
  }, [initialiseConnection, startCheckForScripts, stopCheckForScripts, checkForScripts])

  // ** video connection handling **

  const onEnableVideoConnection = useCallback(() => {
    console.log('** onEnableVideoConnection')
    let shouldCreateNewVideoTrack = true
    // only create tracks if we do not already have a video track that is not disposed of already
    if (localTrackList.current) {
      localTrackList.current.forEach((track) => {
        if (track.getType() === 'video' && !track.disposed) {
          shouldCreateNewVideoTrack = false
        }
      })
    }
    if (shouldCreateNewVideoTrack) {
      console.log('** create new video track')
      createLocalTracks({ devices: ['video'] })
    } else {
      console.log('** DO NOT create new video track - as already exists')
    }
  }, [createLocalTracks, localTrackList])

  const onDisableVideoConnection = useCallback(() => {
    // disable the connection for this user (in connection state)
    if (room.current) {
      const myConnectionId = room.current.myUserId()
      if (videoTracks[myConnectionId]) {
        if (!videoTracks[myConnectionId].disposed) {
          videoTracks[myConnectionId].dispose()
        }
        videoTracks[myConnectionId].detach()
      }
    }
    // also disable our local copy
    if (localTrackList.current) {
      localTrackList.current.forEach((track) => {
        if (track.getType() === 'video') {
          if (!track.disposed) {
            track.dispose()
          }
        }
      })
    }
  }, [room, localTrackList, videoTracks])

  // ** audio connection handling **

  const onSetAudioConnection = useCallback(
    ({ mute }) => {
      console.log('*** onSetAudioConnection', { mute })
      // set the connection for this user (in connection state)
      if (room.current) {
        const myConnectionId = room.current.myUserId()
        const track = audioTracks[myConnectionId]
        if (track) {
          if (track.getType() === 'audio') {
            if (mute && !track.isMuted()) {
              track.mute()
            } else if (!mute && track.isMuted()) {
              track.unmute()
            }
          }
        }
      }
      // also set our local copy
      if (localTrackList.current) {
        localTrackList.current.forEach((track) => {
          if (track.getType() === 'audio') {
            if (mute && !track.isMuted()) {
              track.mute()
            } else if (!mute && track.isMuted()) {
              track.unmute()
            }
          }
        })
      }
    },
    [room, localTrackList, audioTracks],
  )

  const onEnableAudioConnection = useCallback(() => {
    console.log('*** onEnableAudioConnection')
    onSetAudioConnection({ mute: false })
  }, [onSetAudioConnection])

  const onDisableAudioConnection = useCallback(() => {
    console.log('*** onDisableAudioConnection')
    onSetAudioConnection({ mute: true })
  }, [onSetAudioConnection])

  // ** lifecycle events

  useEffect(() => {
    if (!userId) {
      console.log('** no user id **')
    } else if (videoConfig.useDebugToken) {
      console.info(
        `** CONGRATS: You are using a debug_pass_through_token - video will NOT be activated **`,
      )
    } else {
      startSession()
    }
  }, [userId, startSession])

  useEffect(() => {
    onSetConnectionId({ connectionId })
  }, [connectionId, onSetConnectionId])

  useEffect(() => {
    // if component un-mounts then clean up this user session
    return endSession
  }, [endSession])

  useEffect(() => {
    if (localVideoOff) {
      onDisableVideoConnection()
    }
    if (localAudioOff) {
      onDisableAudioConnection()
    }
  }, [localVideoOff, localAudioOff, onDisableVideoConnection, onDisableAudioConnection])

  console.log('********** %&^ useVideo', {
    audioTracks,
    videoTracks,
  })

  return {
    error,
    audioTracks,
    videoTracks,
    onEnableVideoConnection,
    onDisableVideoConnection,
    onEnableAudioConnection,
    onDisableAudioConnection,
  }
}

export default useVideoParticipantsJitsi
