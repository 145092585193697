import { useCallback, useState, useMemo } from 'react'
import _get from 'lodash/get'

import useForm from 'hooks/useForm'

import {
  QUESTION_TYPE_OPEN_QUESTION,
  QUESTION_TYPE_QUESTION_AND_ANSWER,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUIZ_FEATURE_AUDIO_PLAY,
  QUIZ_FEATURES_ACTIVE,
  // QUESTION_TYPE_FILL_THE_BLANK,
} from 'module/constants'
import { hasValue } from 'module/utils'

const audioPlayFeatureActive = QUIZ_FEATURES_ACTIVE.indexOf(QUIZ_FEATURE_AUDIO_PLAY) > -1

function questionFieldsValid({ questionType = '', fields = {}, fieldValidity = {} }) {
  let isValid = false
  const {
    questionText,
    answerText,
    hasQuestionImage,
    questionImage,
    hasQuestionAudio,
    questionAudio,
    answerOptions = {},
  } = fields
  if (questionType === QUESTION_TYPE_OPEN_QUESTION) {
    // has question text
    if (hasValue(questionText)) {
      isValid = true
    }
  } else if (questionType === QUESTION_TYPE_QUESTION_AND_ANSWER) {
    // has question amd answer text
    if (hasValue(questionText) && hasValue(answerText)) {
      isValid = true
    }
  } else if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
    // has question text and at least one correct answer and at least 2 answers
    let hasAtLeastOneCorrectAnswer = !!Object.values(answerOptions).filter(
      ({ isCorrect }) => !!isCorrect,
    ).length
    if (
      hasValue(questionText) &&
      hasAtLeastOneCorrectAnswer &&
      Object.keys(answerOptions).length > 1
    ) {
      isValid = true
    }
  }
  if (hasQuestionImage && !hasValue(questionImage)) {
    isValid = false
  }
  if (audioPlayFeatureActive) {
    if (hasQuestionAudio && !hasValue(questionAudio)) {
      isValid = false
    }
  }
  // check overriding validity state for questionImage and questionAudio
  if (isValid) {
    if (questionImage) {
      isValid = _get(fieldValidity, 'questionImage.isValid', false)
    }
  }
  if (isValid) {
    if (questionAudio) {
      isValid = _get(fieldValidity, 'questionAudio.isValid', false)
    }
  }

  return isValid
}

export default function useAddEditQuestion({
  mode,
  questionType,
  question = {},
  onSubmit,
  onCancel,
  onDelete,
}) {
  const [fieldValidity, setFieldValidity] = useState({})
  const onHandleSubmit = useCallback(
    (fields) => {
      const {
        order,
        questionText,
        hasQuestionImage,
        hasQuestionAudio,
        questionImage,
        questionAudio,
        answerText,
        answerOptions,
      } = fields
      const canSubmit = questionFieldsValid({
        questionType,
        fields,
        fieldValidity,
      })
      if (canSubmit) {
        // if question is not marked as having audio or image we wipe them out
        // on submit
        onSubmit({
          order,
          questionType,
          questionText,
          hasQuestionImage,
          hasQuestionAudio,
          questionImage: hasQuestionImage ? questionImage : '',
          questionAudio: hasQuestionAudio ? questionAudio : '',
          answerText,
          answerOptions,
        })
      }
    },
    [onSubmit, questionType, fieldValidity],
  )

  const { inputs: fields, handleInputChange, setInputValue, handleSubmit, hasChanges } = useForm({
    initialValues: {
      ...question,
      questionType,
    },
    onSubmit: onHandleSubmit,
  })
  let fieldsToDisplay = []

  if (
    [
      QUESTION_TYPE_OPEN_QUESTION,
      QUESTION_TYPE_QUESTION_AND_ANSWER,
      QUESTION_TYPE_MULTIPLE_CHOICE,
    ].indexOf(questionType) > -1
  ) {
    fieldsToDisplay.push('questionText')
    fieldsToDisplay.push('hasQuestionImage')
    if (fields.hasQuestionImage) {
      fieldsToDisplay.push('questionImage')
    }
    if (audioPlayFeatureActive) {
      fieldsToDisplay.push('hasQuestionAudio')
      if (fields.hasQuestionAudio) {
        fieldsToDisplay.push('questionAudio')
      }
    }
  }

  if (questionType === QUESTION_TYPE_QUESTION_AND_ANSWER) {
    fieldsToDisplay.push('answerText')
  }

  if (questionType === QUESTION_TYPE_MULTIPLE_CHOICE) {
    fieldsToDisplay.push('answerOptions')
  }

  if (onCancel) {
    fieldsToDisplay.push('cancelButton')
  }

  if (mode === 'edit') {
    fieldsToDisplay.push('deleteButton')
  }

  const canSubmit = useMemo(() => {
    return questionFieldsValid({
      questionType,
      fields,
      fieldValidity,
    })
  }, [questionType, fields, fieldValidity])

  if (canSubmit) {
    fieldsToDisplay.push('saveButton')
  }

  if (mode === 'edit' && !hasChanges) {
    // remove action button if no changes and we are in edit mode
    fieldsToDisplay = fieldsToDisplay.filter((field) => field !== 'saveButton')
  }

  const onSave = useCallback(() => {
    onHandleSubmit(fields)
  }, [onHandleSubmit, fields])

  const onSetAnswerOptions = useCallback(
    ({ answerOptions }) => {
      setInputValue({
        name: 'answerOptions',
        value: answerOptions,
      })
    },
    [setInputValue],
  )

  return {
    fields,
    fieldValidity,
    setFieldValidity,
    handleInputChange,
    handleSubmit,
    fieldsToDisplay,
    onSetAnswerOptions,
    onSave,
    onCancel,
    onDelete,
  }
}
