import React from 'react'
import { Modal, Loader } from 'semantic-ui-react'

export default function FullScreenLoader() {
  return (
    <Modal open closeOnDimmerClick={false} closeOnEscape={false}>
      <Loader />
    </Modal>
  )
}
