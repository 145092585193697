import React, { useState, useCallback, useMemo } from 'react'
import { Form, Button, Select, Radio } from 'semantic-ui-react'

import './add-edit-event.css'

import Input from 'component/Input'
import DatePicker from 'component/DatePicker'
import TimePicker from 'component/TimePicker'
import ConfirmButton from 'component/ConfirmButton'
import Typography from 'component/Typography'

import {
  isValidDateString,
  isValidTimeString,
  getDatePart,
  getTimePart,
  getNormalisedDateFromDateTimeParts,
} from 'module/dates'

import {
  EVENT_LIMITS_MAX_PLAYERS,
  EVENT_LIMITS_MAX_DURATION_MINUTES,
  EVENT_DEFAULTS_MAX_PLAYERS,
  EVENT_DEFAULTS_MAX_DURATION_MINUTES,
  EVENT_CONNECTION_TYPE_OPTIONS,
  EVENT_USE_VIDEO_OPTIONS,
  EVENT_VIDEO_MODE_OPTIONS,
  QUIZ_FEATURE_IN_APP_VIDEO,
  QUIZ_FEATURE_THIRD_PARTY_VIDEO,
  QUIZ_FEATURES_ACTIVE,
} from 'module/constants'

import useForm from 'hooks/useForm'
import useHostEvents from 'hooks/useHostEvents'
import useNav from 'hooks/useNav'

const inAppVideoFeatureActive = QUIZ_FEATURES_ACTIVE.indexOf(QUIZ_FEATURE_IN_APP_VIDEO) > -1

const thirdPartyVideoFeatureActive =
  QUIZ_FEATURES_ACTIVE.indexOf(QUIZ_FEATURE_THIRD_PARTY_VIDEO) > -1

const connectionTypeOptions = EVENT_CONNECTION_TYPE_OPTIONS
const useVideoOptions = EVENT_USE_VIDEO_OPTIONS
const videoModeOptions = EVENT_VIDEO_MODE_OPTIONS

const LABEL_WIDTH = 140

function getIsValid({
  name,
  eventDate,
  eventTime,
  useVideo,
  videoMode,
  connectionType,
  maxDurationMinutes,
  maxPlayers,
  supportingVideoConference,
  supportingVideoConferenceInfo,
}) {
  let isValid = true
  if (!name) {
    isValid = false
  }
  if (!isValidDateString({ dateString: eventDate })) {
    isValid = false
  }
  if (!isValidTimeString({ timeString: eventTime })) {
    isValid = false
  }
  if (!useVideo) {
    isValid = false
  }
  if (
    !maxDurationMinutes ||
    Number(maxDurationMinutes) < 0 ||
    Number(maxDurationMinutes) > EVENT_LIMITS_MAX_DURATION_MINUTES
  ) {
    isValid = false
  }
  if (!maxPlayers || Number(maxPlayers) < 0 || Number(maxPlayers) > EVENT_LIMITS_MAX_PLAYERS) {
    isValid = false
  }
  if (useVideo === 'use-video') {
    if (!videoMode) {
      isValid = false
    }
    if (videoMode === 'TWILIO' && !connectionType) {
      isValid = false
    }
  }

  return isValid
}

function getInitialData({
  initialDataProp = {},
  canCreateVideoEvents,
  canAddSupportingVideoConferenceDetails,
} = {}) {
  const initialDataWithDefaults = {
    ...initialDataProp,
  }
  initialDataWithDefaults.useVideo = 'no-video'
  initialDataWithDefaults.supportingVideoConference = 'no'
  if (canCreateVideoEvents) {
    initialDataWithDefaults.useVideo = 'use-video'
    initialDataWithDefaults.videoMode = 'TWILIO'
    initialDataWithDefaults.connectionType = 'peer-to-peer'
  }
  if (canAddSupportingVideoConferenceDetails) {
    initialDataWithDefaults.supportingVideoConference = 'yes'
  }
  initialDataWithDefaults.maxPlayers = initialDataProp.maxPlayers || EVENT_DEFAULTS_MAX_PLAYERS
  initialDataWithDefaults.maxDurationMinutes =
    initialDataWithDefaults.maxDurationMinutes || EVENT_DEFAULTS_MAX_DURATION_MINUTES
  return initialDataWithDefaults
}

export default function EventDetails({
  mode = 'add',
  eventId,
  gameLinkId,
  initialData: initialDataProp = {},
  onAddEventSuccess,
  readOnly,
  canCreateVideoEvents: canCreateVideoEventsProp = false,
  canChooseVideoEventType = false,
}) {
  const canCreateVideoEvents = canCreateVideoEventsProp && inAppVideoFeatureActive
  const canAddSupportingVideoConferenceDetails = thirdPartyVideoFeatureActive

  const {
    createEvent,
    createEventStatus = {},
    updateEvent,
    updateEventStatus = {},
    deleteEvent,
    deleteEventStatus = {},
  } = useHostEvents({ eventId })

  const [initialData, setInitialData] = useState(
    getInitialData({
      initialDataProp,
      canCreateVideoEvents,
      canAddSupportingVideoConferenceDetails,
    }),
  )
  const { creatingEvent, creatingEventErrorDisplay } = createEventStatus
  const { updatingEvent, updatingEventErrorDisplay } = updateEventStatus
  const { deletingEvent, deletingEventErrorDisplay } = deleteEventStatus

  let loading
  let errorDisplay
  let actionButtonLabel
  let submitAction
  if (mode === 'add') {
    actionButtonLabel = 'Create Quiz'
    loading = creatingEvent
    errorDisplay = creatingEventErrorDisplay
    submitAction = createEvent
  } else {
    actionButtonLabel = 'Save Changes'
    loading = updatingEvent
    errorDisplay = updatingEventErrorDisplay
    submitAction = updateEvent
  }

  const { navToDashboard } = useNav()
  const [eventDate, setEventDate] = useState()
  const [eventTime, setEventTime] = useState()

  const onSubmit = useCallback(
    (
      {
        name,
        connectionType,
        maxDurationMinutes,
        maxPlayers,
        supportingVideoConference,
        supportingVideoConferenceInfo: supportingVideoConferenceInfoProp,
        useVideo,
        videoMode,
      },
      { setFormOriginValues },
    ) => {
      const supportingVideoConferenceInfo =
        supportingVideoConference === 'yes' ? supportingVideoConferenceInfoProp : ''
      if (!deletingEvent) {
        if (
          getIsValid({
            name,
            eventDate,
            eventTime,
            useVideo,
            videoMode,
            connectionType,
            supportingVideoConference,
            supportingVideoConferenceInfo,
            maxDurationMinutes,
            maxPlayers,
          })
        ) {
          submitAction({
            ...initialData,
            id: eventId,
            gameLinkId,
            name,
            date: eventDate,
            time: eventTime,
            useVideo,
            videoMode,
            connectionType,
            supportingVideoConference,
            supportingVideoConferenceInfo,
            maxDurationMinutes,
            maxPlayers,
            onSuccess: ({ eventId, gameLinkId }) => {
              setInitialData({
                ...initialData,
                name,
                useVideo,
                videoMode,
                connectionType,
                supportingVideoConference,
                supportingVideoConferenceInfo,
                maxDurationMinutes,
                maxPlayers,
                dateTime: getNormalisedDateFromDateTimeParts({
                  datePart: eventDate,
                  timePart: eventTime,
                }),
              })
              setFormOriginValues({
                values: {
                  name,
                  useVideo,
                  videoMode,
                  connectionType,
                  supportingVideoConference,
                  supportingVideoConferenceInfo,
                  maxDurationMinutes,
                  maxPlayers,
                },
              })
              if (onAddEventSuccess) {
                onAddEventSuccess({ eventId, gameLinkId })
              }
            },
          })
        }
      }
    },
    [
      eventDate,
      eventTime,
      submitAction,
      onAddEventSuccess,
      initialData,
      deletingEvent,
      eventId,
      gameLinkId,
    ],
  )

  const onDelete = useCallback(() => {
    if (!loading) {
      deleteEvent({
        id: initialData.id,
        onSuccess: () => {
          navToDashboard()
        },
      })
    }
  }, [deleteEvent, navToDashboard, initialData, loading])

  const { inputs, handleInputChange, handleSubmit, hasChanges: hasFormChanges } = useForm({
    onSubmit,
    initialValues: initialData,
  })

  const onDateChanged = useCallback(
    (date) => {
      setEventDate(date)
    },
    [setEventDate],
  )

  const onTimeChanged = useCallback(
    (time) => {
      setEventTime(time)
    },
    [setEventTime],
  )

  const hasChanges = useMemo(() => {
    return (
      hasFormChanges ||
      initialData.dateTime !==
        getNormalisedDateFromDateTimeParts({
          datePart: eventDate,
          timePart: eventTime,
        })
    )
  }, [hasFormChanges, eventDate, eventTime, initialData])

  const isValid = getIsValid({
    ...inputs,
    eventDate,
    eventTime,
  })

  const eventDateError = !isValidDateString({ dateString: eventDate })
  const eventNameError = inputs.name === ''
  const initialDateString = initialData.dateTime ? getDatePart(initialData.dateTime) : undefined
  const initialTimeString = initialData.dateTime ? getTimePart(initialData.dateTime) : undefined

  return (
    <div className="add-edit-event-container">
      <Form>
        <Form.Group inline>
          <Form.Field label="Name" style={{ width: LABEL_WIDTH }}></Form.Field>
          <Input
            placeholder="Quiz name"
            required
            onChange={handleInputChange}
            value={inputs.name || initialData.name || ''}
            name="name"
            type="text"
            error={eventNameError}
            disabled={readOnly}
            autoFocus={mode === 'add'}
            style={{
              marginTop: '0.5em',
            }}
            width={12}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field label="Date" style={{ width: LABEL_WIDTH }}></Form.Field>
          <DatePicker
            onDateChanged={onDateChanged}
            error={eventDateError}
            initialDateString={initialDateString}
            disabled={readOnly}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field label="Time" style={{ width: LABEL_WIDTH }}></Form.Field>
          <TimePicker
            onTimeChanged={onTimeChanged}
            initialTimeString={initialTimeString}
            disabled={readOnly}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field label="Max Players" style={{ width: LABEL_WIDTH }}></Form.Field>
          <Typography.text alt style={{ marginRight: '3em' }}>
            {inputs.maxPlayers}
          </Typography.text>
          <Form.Field label="Max Duration" style={{ width: LABEL_WIDTH }}></Form.Field>
          <Typography.text alt>{inputs.maxDurationMinutes} minutes</Typography.text>
        </Form.Group>

        {!!canAddSupportingVideoConferenceDetails && (
          <Form.Group inline>
            <Form.Field label="Video Conference" style={{ width: LABEL_WIDTH }}></Form.Field>
            <Form.Radio
              label="Yes"
              value="yes"
              name="supportingVideoConference"
              checked={inputs.supportingVideoConference === 'yes'}
              onChange={handleInputChange}
              disabled={readOnly}
            />
            <Form.Radio
              label="No"
              value="no"
              name="supportingVideoConference"
              checked={inputs.supportingVideoConference === 'no'}
              onChange={handleInputChange}
              disabled={readOnly}
            />
          </Form.Group>
        )}

        {inputs.supportingVideoConference === 'yes' && (
          <>
            <Form.Group inline>
              <Form.Field label="" style={{ width: LABEL_WIDTH }}></Form.Field>
              <Input
                placeholder="Video Conference Details (eg Meeting ID and Password)"
                onChange={handleInputChange}
                value={inputs.supportingVideoConferenceInfo || ''}
                name="supportingVideoConferenceInfo"
                type="text"
                disabled={readOnly}
                width={12}
                textArea
              />
            </Form.Group>
            <Form.Group inline>
              <Form.Field label="" style={{ width: LABEL_WIDTH }}></Form.Field>
              <Typography.smallText alt>
                The above video conference details will be displayed to all players who join the
                quiz
              </Typography.smallText>
            </Form.Group>
          </>
        )}

        {!!canCreateVideoEvents && (
          <>
            <Form.Group inline>
              <Form.Field label="Video Quiz" style={{ width: LABEL_WIDTH }}></Form.Field>
              {useVideoOptions.map(({ key, value, text }) => (
                <Form.Field
                  key={key}
                  control={Radio}
                  label={text}
                  value={value}
                  checked={inputs.useVideo === value}
                  onChange={handleInputChange}
                  name="useVideo"
                  disabled={readOnly || !canCreateVideoEvents}
                />
              ))}
              <Form.Field>
                <Typography.smallText alt>(experimental feature)</Typography.smallText>
              </Form.Field>
            </Form.Group>

            {!!canChooseVideoEventType && (
              <>
                {inputs.useVideo === 'use-video' && (
                  <Form.Group inline>
                    <Form.Field label="Video Mode" style={{ width: LABEL_WIDTH }}></Form.Field>
                    <Select
                      placeholder="Video Mode"
                      options={videoModeOptions}
                      onChange={handleInputChange}
                      value={inputs.videoMode || ''}
                      name="videoMode"
                      disabled={readOnly}
                    />
                  </Form.Group>
                )}
                {inputs.useVideo === 'use-video' && inputs.videoMode === 'TWILIO' && (
                  <Form.Group inline>
                    <Form.Field label="Connection Type" style={{ width: LABEL_WIDTH }}></Form.Field>
                    <Select
                      placeholder="Connection Type"
                      options={connectionTypeOptions}
                      onChange={handleInputChange}
                      value={inputs.connectionType || ''}
                      name="connectionType"
                      disabled={readOnly}
                    />
                  </Form.Group>
                )}
              </>
            )}
          </>
        )}
        {!!errorDisplay && <p style={{ color: 'red' }}>{errorDisplay}</p>}
      </Form>
      <div className="add-edit-event-action-buttons">
        <div>
          {(!!hasChanges || mode === 'add') && !!isValid && (
            <Button primary loading={loading} onClick={handleSubmit} style={{ marginRight: '1em' }}>
              {actionButtonLabel}
            </Button>
          )}
          <Button color="black" onClick={navToDashboard}>
            {mode === 'add' ? 'Cancel' : readOnly ? 'OK' : 'Done'}
          </Button>
        </div>
        <div>
          {!readOnly && mode === 'edit' && (
            <div>
              {!!deletingEventErrorDisplay && (
                <p style={{ color: 'red' }}>{deletingEventErrorDisplay}</p>
              )}
              <ConfirmButton
                renderButton={() => (
                  <Button color="red" loading={deletingEvent}>
                    Delete Quiz
                  </Button>
                )}
                renderConfirmButton={() => (
                  <Button size="tiny" color="red" content="Delete" onClick={onDelete} />
                )}
                renderCancelButton={({ onCancel }) => (
                  <Button size="tiny" color="black" content="Cancel" onClick={onCancel} />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
