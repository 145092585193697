import React, { useEffect, useCallback, useState } from 'react'

import useInterval from 'hooks/useInterval'

export default function DebugGeneral() {
  const [counter, setCounter] = useState(0)
  const incrementCounter = useCallback(() => {
    setCounter(counter + 1)
  }, [counter, setCounter])
  const resetCounter = useCallback(() => {
    setCounter(0)
  }, [setCounter])
  const { start, stop } = useInterval({ callback: incrementCounter, delay: 1000, autoStart: false })
  useEffect(() => {
    start()
  }, [start])
  return (
    <div>
      <div>DEBUG GENERAL {counter}</div>
      <button onClick={incrementCounter}>increment counter</button>
      <button onClick={resetCounter}>reset counter</button>
      <button onClick={start}>start timer</button>
      <button onClick={stop}>stop timer</button>
    </div>
  )
}
