import { useEffect, useState, useCallback } from 'react'

import storeApi from 'store/storeApi'
import useIsMounted from 'hooks/useIsMounted'

const usePublicGameData = ({ gameLinkId }) => {
  const isMounted = useIsMounted()
  const [fetchingPublicGameData, setFetchingPublicGameData] = useState(true)
  const [fetchingPublicGameDataErrorDisplay, setFetchingPublicGameDataErrorDisplay] = useState('')
  const [publicGameData, setPublicGameData] = useState()

  const startPublicGameListener = useCallback(() => {
    storeApi({
      method: 'connectToPublicGameData',
      params: {
        gameLinkId,
        onUpdate: ({ publicData }) => {
          if (isMounted.current) {
            setPublicGameData((existingData) => {
              return {
                ...existingData,
                details: publicData,
              }
            })
          }
        },
      },
    })
  }, [isMounted, gameLinkId, setPublicGameData])

  useEffect(() => {
    try {
      storeApi({
        method: 'getPublicGameData',
        params: {
          gameLinkId,
          onSuccess: ({ data }) => {
            if (isMounted.current) {
              setFetchingPublicGameDataErrorDisplay('')
              setFetchingPublicGameData(false)
              setPublicGameData({
                gameLinkId,
                ...data,
              })
              startPublicGameListener()
            }
          },
          onError: ({ errorMsg = 'Error fetching game data' } = {}) => {
            if (isMounted.current) {
              setFetchingPublicGameDataErrorDisplay(errorMsg)
              setFetchingPublicGameData(false)
            }
          },
        },
      })
    } catch (error) {
      setFetchingPublicGameData(false)
      setFetchingPublicGameDataErrorDisplay('Error fetching game data')
    }
  }, [
    gameLinkId,
    isMounted,
    setFetchingPublicGameData,
    setFetchingPublicGameDataErrorDisplay,
    setPublicGameData,
    startPublicGameListener,
  ])

  useEffect(() => {
    return function cleanup() {
      storeApi({
        method: 'disconnectFromPublicGameData',
        params: {
          gameLinkId,
        },
      })
    }
  }, [gameLinkId])

  return {
    fetchingPublicGameData,
    fetchingPublicGameDataErrorDisplay,
    publicGameData,
  }
}

export default usePublicGameData
