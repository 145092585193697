import React, { useCallback } from 'react'
import { Card, Button } from 'semantic-ui-react'

import VideoPlayerSlave from 'component/VideoPlayerSlave'
import MediaControl from 'component/MediaControl'
import { colors } from 'module/theme'

const hostImgSrc = require('images/avatars/quizbee.svg')

export default function HostCard({
  gameLinkId,
  setHostMedia,
  setAllPlayersMedia,
  setLocalAudioOff,
  setLocalVideoOff,
  audioTrack,
  videoTrack,
  localVideoOff,
  localAudioOff,
  host = {},
  isHost = false,
  allMuted,
  hasPlayers,
  isCurrentQuestionAudioPlayingLocally = false,
} = {}) {
  const { name, video, audio } = host

  const canToggleAudio = isHost && !isCurrentQuestionAudioPlayingLocally
  const canToggleVideo = isHost

  const toggleHostAudio = useCallback(() => {
    setLocalAudioOff(audio)
    setHostMedia({
      hostId: host.id,
      mediaType: 'audio',
      mediaActive: !audio,
    })
  }, [audio, setHostMedia, setLocalAudioOff, host])

  const toggleHostVideo = useCallback(() => {
    setLocalVideoOff(video)
    setHostMedia({
      hostId: host.id,
      mediaType: 'video',
      mediaActive: !video,
    })
  }, [video, setHostMedia, setLocalVideoOff, host])

  const me = isHost ? ' (me)' : ''

  const toggleAllMuted = useCallback(() => {
    setAllPlayersMedia({
      mediaType: 'audio',
      mediaActive: allMuted,
    })
  }, [allMuted, setAllPlayersMedia])

  const videoDimensionStyles = {
    width: 240,
    height: 180,
  }

  let mediaOverrideDisplay
  if (isCurrentQuestionAudioPlayingLocally && isHost) {
    mediaOverrideDisplay = 'Microphone is disabled while playing sound track'
  }

  let audioEnabled = audio
  let videoEnabled = video
  if (isHost) {
    if (localAudioOff || isCurrentQuestionAudioPlayingLocally) {
      audioEnabled = false
    }
    if (localVideoOff) {
      videoEnabled = false
    }
  }

  return (
    <Card>
      <div
        className="video-content-container"
        style={{ backgroundColor: colors.primary, ...videoDimensionStyles }}
      >
        {!!audioTrack || !!videoTrack ? (
          <div style={{ position: 'relative' }}>
            <VideoPlayerSlave
              isMe={isHost}
              audioTrack={audioTrack}
              videoTrack={videoTrack}
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
              videoDimensionStyles={videoDimensionStyles}
            />
            {!!video && (
              <div style={{ position: 'absolute', bottom: 6, right: 6 }}>
                <img alt="Host" className="video-placeholder-image-micro" src={hostImgSrc} />
              </div>
            )}
            {!video && (
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <img
                  alt="Host"
                  className="video-placeholder-image"
                  src={hostImgSrc}
                  style={videoDimensionStyles}
                />
              </div>
            )}
          </div>
        ) : (
          <img
            alt="Host"
            className="video-placeholder-image"
            src={hostImgSrc}
            style={videoDimensionStyles}
          />
        )}
        {!!mediaOverrideDisplay && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <div className="override-msg">{mediaOverrideDisplay}</div>
          </div>
        )}
      </div>
      <Card.Content>
        <Card.Header>
          {name}
          {me}
        </Card.Header>
        <Card.Meta>
          <span className="date">Your host</span>
        </Card.Meta>
        <div
          style={{
            display: 'flex',
            justifyContent: isHost && hasPlayers ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {!!isHost && !!hasPlayers && (
            <Button style={{ marginTop: '0.5em' }} size="tiny" onClick={toggleAllMuted}>
              {allMuted ? 'UN-MUTE' : 'MUTE'} ALL
            </Button>
          )}
          <MediaControl
            videoOn={videoEnabled}
            audioOn={audioEnabled}
            toggleAudio={canToggleAudio ? toggleHostAudio : undefined}
            toggleVideo={canToggleVideo ? toggleHostVideo : undefined}
          />
        </div>
      </Card.Content>
    </Card>
  )
}
