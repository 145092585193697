import { getVideoConfig } from 'module/environ'

const videoConfig = getVideoConfig()

/* eslint-disable no-unused-vars */
export const APP_MODE_NORMAL = 'APP_MODE_NORMAL'
export const APP_MODE_MOCK = 'APP_MODE_MOCK'

// export const APP_MODE = APP_MODE_MOCK
export const APP_MODE = APP_MODE_NORMAL

// export const USE_SENTRY_IN_DEV_MODE = true
export const USE_SENTRY_IN_DEV_MODE = false

// set this to return a fake debug token for video access
// i.e. so we don't get charged and video doesn't actually start
export const USE_DEBUG_TOKEN_FOR_VIDEO = videoConfig.useDebugToken
export const VIDEO_MODE = videoConfig.videoMode
export const VIDEO_INACTIVITY_TIMEOUT = videoConfig.inactivityDetectionPeriod
