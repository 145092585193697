import { useEffect, useState, useCallback } from 'react'

import storeApi from 'store/storeApi'
import useIsMounted from 'hooks/useIsMounted'

const useLiveGame = ({ gameLinkId }) => {
  const isMounted = useIsMounted()
  const [isConnecting, setIsConnecting] = useState(true)
  const [gameData, setGameData] = useState({})
  const [localSoundtracksPlaying, setLocalSoundtracksPlaying] = useState({})

  const onUpdate = useCallback(
    ({ gameData, setGameDataCallback }) => {
      if (isMounted.current) {
        setIsConnecting(false)
        if (setGameDataCallback) {
          setGameData(setGameDataCallback)
        } else {
          setGameData({
            gameLinkId,
            ...gameData,
          })
        }
      }
    },
    [isMounted, gameLinkId, setIsConnecting, setGameData],
  )

  const onLocalSoundtrackStarted = useCallback(
    ({ trackUrl }) => {
      setLocalSoundtracksPlaying((soundTracks) => ({
        ...soundTracks,
        [trackUrl]: true,
      }))
    },
    [setLocalSoundtracksPlaying],
  )

  const onLocalSoundtrackStopped = useCallback(
    ({ trackUrl }) => {
      setLocalSoundtracksPlaying((soundTracks) => ({
        ...soundTracks,
        [trackUrl]: false,
      }))
    },
    [setLocalSoundtracksPlaying],
  )

  useEffect(() => {
    storeApi({
      method: 'connectToGame',
      params: {
        gameLinkId,
        onUpdate,
      },
    })
    return function cleanup() {
      storeApi({
        method: 'disconnectFromGame',
        params: {
          gameLinkId,
        },
      })
    }
  }, [gameLinkId, onUpdate])

  return {
    isConnecting,
    gameData,
    localSoundtracksPlaying,
    onLocalSoundtrackStarted,
    onLocalSoundtrackStopped,
  }
}

export default useLiveGame
