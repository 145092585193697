import _get from 'lodash/get'

import { USER_STATUS_UNDETERMINED, USER_STATUS_AUTHENTICATED } from 'store/App/constants'

export function getIsUserStatusDetermined(state) {
  return state.userStatus !== USER_STATUS_UNDETERMINED
}

export function getUser(state) {
  return state.user
}

export function getUserId(state) {
  return _get(state, 'user.id')
}

export function getIsUserAuthenticated(state) {
  return state.userStatus === USER_STATUS_AUTHENTICATED
}

export function getIsAuthListenerInitialised(state) {
  return !!state.authListenerInitialised
}

export function getSignInUpStatus(state) {
  const {
    userSigningIn = false,
    userSignInErrorDisplay = '',
    userSigningUp = false,
    userSignUpErrorDisplay = '',
  } = state
  return {
    userSigningIn,
    userSignInErrorDisplay,
    userSigningUp,
    userSignUpErrorDisplay,
  }
}
